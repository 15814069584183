define("lt-page-editor/components/blocks/db-content/lt-page-editor-db-content-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U63Tgpc0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"data\"]]],null,{\"statements\":[[4,\"each\",[[29,\"array\",[[25,[\"data\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[23,\"content\"],true],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/db-content/lt-page-editor-db-content-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});