define("lt-page-editor/components/lt-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GxLaWS7s",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"href\"]]],null,{\"statements\":[[7,\"a\"],[12,\"href\",[23,\"href\"]],[12,\"style\",[23,\"style\"]],[12,\"class\",[23,\"class\"]],[12,\"title\",[23,\"title\"]],[12,\"target\",[23,\"hrefTarget\"]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"_onclick\"]]],null]],[12,\"rel\",[23,\"rel\"]],[9],[15,1,[[25,[\"title\"]]]],[10]],\"parameters\":[]},{\"statements\":[[7,\"span\"],[12,\"style\",[23,\"style\"]],[12,\"class\",[23,\"class\"]],[9],[15,1,[[25,[\"title\"]]]],[10]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/lt-link/template.hbs"
    }
  });

  _exports.default = _default;
});