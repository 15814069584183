define("lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser-readonly/component", ["exports", "lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    _value: Ember.computed.alias('value.data'),
    linkValue: Ember.computed('value.data.link', function () {
      return Ember.get(this, 'value.data.link');
    }),
    layoutStyle: Ember.computed('value.layout', function () {
      return Ember.get(this, 'value.layout') || 0;
    })
  });

  _exports.default = _default;
});