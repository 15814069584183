define("lt-page-editor/components/blocks/inception/lt-page-editor-inception-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PxKbLIC1",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"lt-page-editor-readonly\",null,[[\"class\",\"model\",\"value\",\"columns\",\"columnWidth\",\"resolver\",\"transition\",\"blocks\"],[[25,[\"inceptionClass\"]],[25,[\"model\"]],[25,[\"_value\"]],[29,\"if\",[[25,[\"columns\"]],[25,[\"columns\"]],[25,[\"value\",\"width\"]]],null],[25,[\"columnWidth\"]],[25,[\"resolver\"]],[25,[\"transition\"]],[29,\"lt-page-editor-object-merge\",[[29,\"hash\",null,[[\"inception\",\"teaser\"],[[28],[28]]]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/inception/lt-page-editor-inception-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});