define("lt-page-editor/helpers/short-openhours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getShortOpenhours = getShortOpenhours;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatTime(x) {
    if (!x) {
      return null;
    }

    return "".concat(x).substr(0, 5);
  }

  function formatTimeSpan(x, y) {
    if (!x) {
      return null;
    }

    if (!y) {
      return "ab ".concat(formatTime(x));
    }

    if (y == '00:00:00') {
      y = '24:00';
    }

    return "".concat(formatTime(x), "-").concat(formatTime(y));
  }

  function getShortOpenhours(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 5),
        dayId = _ref2[0],
        from1 = _ref2[1],
        to1 = _ref2[2],
        from2 = _ref2[3],
        to2 = _ref2[4];

    var day = [null, 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.', 'So.'][dayId];
    return [day, [formatTimeSpan(from1, to1), formatTimeSpan(from2, to2)].filter(function (x) {
      return x;
    }).join(' und ')].filter(function (x) {
      return x;
    }).join(' ');
  }

  var _default = Ember.Helper.helper(getShortOpenhours);

  _exports.default = _default;
});