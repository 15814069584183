define("lt-page-editor/components/blocks/text/lt-page-editor-text-readonly/component", ["exports", "ui-page-editor-text/components/ui-page-editor-text-readonly/component", "lt-page-editor/components/blocks/text/lt-page-editor-text-readonly/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function convertToStruct(value) {
    return value.map(function (x, i, a) {
      if (i % 2 !== 0) {
        return;
      }

      return {
        component: x && x.startsWith('!') && x.split(' ')[0].replace(/^!/, ''),
        cls: x && x.split(' ').filter(function (x) {
          return !x.startsWith('!');
        }).join(' '),
        value: a[i + 1]
      };
    }).filter(function (x) {
      return x;
    });
  }

  var _default = _component.default.extend({
    layout: _template.default,
    _value: Ember.computed('value.data', 'preview', function () {
      var links = this.get('value.links') || [];
      var value = this.get('value.data');

      if (!value) {
        return null;
      }

      var res = convertToStruct(value); // extra step merge link.. :/

      var _loop = function _loop(i) {
        var x = res[i];
        var link = x && x.cls && x.cls.split(' ').includes('link') && x.cls.split(' ').find(function (x) {
          return x.startsWith('link-');
        });

        if (!link) {
          return "continue";
        }

        var idx = parseInt(link.substring(5));

        if (!idx) {
          return "continue";
        }

        var ll = links.find(function (x) {
          return x.id == idx;
        });

        if (!ll) {
          return "continue";
        }

        x.link = ll;
        x.value = convertToStruct([null, x.value]); // find all children

        var j = i + 1;

        for (; j < res.length; ++j) {
          var y = res[j];

          var _link = y && y.cls && y.cls.split(' ').includes('link') && y.cls.split(' ').includes("link-".concat(idx));

          if (!_link) {
            break;
          }

          x.value.push(y);
        } // remove extracted stuff


        res.splice(i + 1, j - (i + 1));
      };

      for (var i = 0; i < res.length; ++i) {
        var _ret = _loop(i);

        if (_ret === "continue") continue;
      }

      return res;
    })
  });

  _exports.default = _default;
});