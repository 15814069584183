define('ui-input/components/ui-input-number', ['exports', 'ui-input/components/ui-textarea', 'ui-input/utils/immediate', 'ui-contenteditable/components/ui-contenteditable'], function (exports, _uiTextarea, _immediate, _uiContenteditable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _uiTextarea.default.extend({
    tagName: 'ui-input',
    multiline: false,
    steps: 0.01,
    init: function init() {
      this._super();
      this._dontUpdateCursor = false;
      if (this.get('steps') === null || this.get('steps') === undefined) {
        this.set('steps', 0.01);
      }
    },
    _format: function _format(input) {
      var _this = this;

      input = input || '';
      if (input.length > 0) {
        var cursor = this._cursorOffset();
        var steps = parseFloat(this.get('steps')) || 0;
        var fractions = Math.ceil(-Math.log10(steps));

        var r = [(input || '').split('').map(function (x, i) {
          return [x, i];
        }).filter(function (x) {
          return (/[0-9,]/.test(x[0])
          );
        }).map(function (x) {
          return x[1];
        })];
        var rAfter = void 0;

        if (r[0].findIndex(function (x) {
          return input[x] === ',';
        }) > 0) {
          rAfter = r[0].slice(r[0].findIndex(function (x) {
            return input[x] === ',';
          }) + 1);
          r[0] = r[0].slice(0, r[0].findIndex(function (x) {
            return input[x] === ',';
          }));
        }

        for (var i = 0; i < r.length; ++i) {
          r[i] = r[i].filter(function (x) {
            return input[x] !== ',';
          });
        }
        if (rAfter) {
          rAfter = rAfter.filter(function (x) {
            return input[x] !== ',';
          });
        }

        var hasDecimalPoint = input.includes(',');
        var hasDecimal = rAfter && rAfter.length;
        if (fractions === 0) {
          hasDecimalPoint = false;
          hasDecimal = false;
        }

        //thousender:
        for (var _i = r[0].length; _i >= 0; --_i) {
          if ((r[0].length - _i) % 3 === 0 && _i !== r[0].length) {
            r.splice(1, 0, r[0].splice(_i));
          }
        }

        r = r.filter(function (x) {
          return x.length;
        });

        var res = [];
        for (var _i2 = 0; _i2 < r.length; ++_i2) {
          if (_i2 !== 0) {
            res.push({
              value: '.',
              class: ['static']
            });
          }
          res.push({
            value: r[_i2].map(function (x) {
              return input[x];
            }).join('')
          });
        }

        var shift = 0;
        if (r.length == 0 && (hasDecimalPoint || hasDecimal)) {
          shift = 1;
          res.push({
            value: '0', //'\u2012',
            class: ['placeholder']
          });
        }

        if (hasDecimalPoint || hasDecimal) {
          res.push({
            value: ',',
            class: ['static']
          });
        }

        if (hasDecimal) {
          var tmp = {
            value: rAfter.map(function (x) {
              return input[x];
            }).join('').substr(0, fractions)
          };
          while (tmp.value.length < fractions) {
            tmp.value += '\u2012';
          }
          res.push(tmp);
        } else if (hasDecimalPoint) {
          var _tmp = {
            value: '',
            class: ['placeholder']
          };
          while (_tmp.value.length < fractions) {
            _tmp.value += '\u2012';
          }
          res.push(_tmp);
        }

        if (cursor) {
          var bcur = [].concat(_toConsumableArray(cursor));
          var h = [];
          for (var _i3 = 0; _i3 < r.length; ++_i3) {
            if (h.length) {
              h.push(null);
            }
            h.push.apply(h, _toConsumableArray(r[_i3].map(function (x) {
              return x;
            })));
          }
          for (var _i4 = 0; _i4 < h.length; ++_i4) {
            if (h[_i4] === null) {
              h[_i4] = Math.floor((h[_i4 - 1] + h[_i4 + 1]) / 2);
              if (isNaN(h[_i4])) {
                h[_i4] = h[_i4 - 1];
              }
              if (isNaN(h[_i4])) {
                h[_i4] = h[_i4 + 1];
              }
            }
          }
          var revLookup = [];
          for (var _i5 = 0; _i5 < h.length; ++_i5) {
            if (h[_i5] !== null) {
              if (revLookup[h[_i5] - 3] === undefined) revLookup[h[_i5] - 3] = _i5 - 3;
              if (revLookup[h[_i5] - 2] === undefined) revLookup[h[_i5] - 2] = _i5 - 2;
              if (revLookup[h[_i5] - 1] === undefined) revLookup[h[_i5] - 1] = _i5 - 1;
              revLookup[h[_i5]] = _i5;
              if (revLookup[h[_i5] + 1] === undefined) revLookup[h[_i5] + 1] = _i5 + 1;
              if (revLookup[h[_i5] + 2] === undefined) revLookup[h[_i5] + 2] = _i5 + 2;
              if (revLookup[h[_i5] + 3] === undefined) revLookup[h[_i5] + 3] = _i5 + 3;
            }
          }

          if (h.length > 0) {
            cursor[0] = revLookup[bcur[0]] + shift;
            cursor[1] = revLookup[bcur[1]] + shift;
          } else {
            cursor[0] = bcur[0] + shift;
            cursor[1] = bcur[1] + shift;
          }

          if (cursor[0] !== null && cursor[0] !== undefined && cursor[1] !== null && cursor[1] !== undefined) {
            // needs to be done after ... this "_format" !
            (0, _immediate.default)(function () {
              try {
                if (_this.element && document.activeElement === _this.element) {
                  _this._setCursorOffset(cursor);
                }
              } catch (e) {
                // ignore
              }
            });
          }
        }

        return res.map(function (x) {
          var tmp = _uiContenteditable.ContentObject.create();
          tmp.setText(x.class, x.value);
          return tmp;
        });
      }
      return [];
    },

    content: Ember.computed('value', {
      set: function set(k, v) {
        try {
          this._ignoreValueChange = true;
          var formated = this._format(v.reduce(function (x, _ref) {
            var value = _ref.value;
            return x + value;
          }, '') + '        ');
          this.set('value', this._value(formated));
          return formated;
        } finally {
          this._ignoreValueChange = false;
        }
      },
      get: function get(k) {
        k;
        var value = this.get('value');
        if (!isNaN(value) && value !== null) {
          var steps = parseFloat(this.get('steps')) || 0;
          var fractions = Math.ceil(-Math.log10(steps));
          var res = (Math.round(value / steps) * steps).toFixed(fractions);
          return this._format('' + res.replace('.', ','));
        }
        return this._format('' + value);
      }
    }),
    _value: function _value(content) {
      content = content || this.get('content');
      var numberStr = '';
      for (var i = 0; i < content.length; ++i) {
        numberStr += content[i].value;
      }
      return parseFloat(numberStr.replace(/[\.]/g, '').replace(',', '.'));
    },
    _change: function _change() {
      try {
        var value = this._value();
        if (isNaN(value)) {
          // don't format number
          return;
        }
        this._dontUpdateCursor = true;
        var steps = parseFloat(this.get('steps')) || 0;
        var nv = Math.round(value / steps) * steps;
        if (this.get('value') != nv) {
          this.set('value', nv);
          this.notifyPropertyChange('value');
        }
      } finally {
        this._dontUpdateCursor = false;
        this._super.apply(this, arguments);
      }
    }
  });
});