define("lt-page-editor/components/blocks/sp-calendar/lt-page-editor-sp-calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OEOrPcMK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[25,[\"helper\"]]]],[0,\"\\n\"],[1,[29,\"lt-page-editor-sp-calendar-readonly\",null,[[\"preview\",\"model\",\"value\",\"resolver\",\"transition\"],[true,[25,[\"model\"]],[25,[\"value\"]],[25,[\"resolver\"]],[25,[\"transition\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-calendar/lt-page-editor-sp-calendar/template.hbs"
    }
  });

  _exports.default = _default;
});