define("lt-page-editor/components/blocks/hr/lt-page-editor-hr/component", ["exports", "lt-page-editor/components/blocks/hr/lt-page-editor-hr/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    helper: Ember.computed('value.data.layout', function () {
      var helper = {
        layout: Ember.get(this, 'value.layout') | 0,
        setLayout: Ember.set.bind(null, this, 'value.layout')
      };
      return helper;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});