define("ui-page-editor/components/ui-page-editor-wysiwyg-col/component", ["exports", "ui-page-editor/components/ui-page-editor-wysiwyg-col/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: 'ui-wysiwyg-col',
    layout: _template.default,
    attributeBindings: ['model.data.width:data-w', 'index:data-index', 'editor.elementId:data-parent', 'model.rowIndex:data-row-index', 'model.sectionIndex:data-section-index', 'model.afterSelectedInRow:data-after-selected', 'model.selectedInRow:data-selected-in-row', 'model.selectedInRowBefore:data-selected-in-row-before', 'tabIndex:tabindex'],
    classNameBindings: ['selected', 'placeholder', 'extraClassName'],
    handler: Ember.computed.not('model.isPlaceholder'),
    index: Ember.computed.alias('model.index'),
    selected: Ember.computed.alias('model.selected'),
    placeholder: Ember.computed.alias('model.isPlaceholder'),
    tabIndex: "0",
    extraClassName: Ember.computed('model.data.component', function () {
      var comp = Ember.get(this, 'model.data.component');
      return "ui-wysiwyg-col-" + comp;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this._resize = this._resize.bind(this);
      this._resizeStop = this._resizeStop.bind(this);

      this._resizeDiv = document.createElement('div');
      this._resizeDiv.style.position = 'fixed';
      this._resizeDiv.style.left = 0;
      this._resizeDiv.style.top = 0;
      this._resizeDiv.style.bottom = 0;
      this._resizeDiv.style.right = 0;
      this._resizeDiv.style.zIndex = 100000;
    },
    _resize: function _resize(e) {
      var _this = this;

      var pageX = e.pageX;

      var size = pageX - this._resizeX + this._resizeW;

      //EXTREMLY PERFORMANCE KILLER !

      var maxN = this._maxN;

      while (this.element.offsetWidth < size) {
        var n = parseInt(this.element.dataset.w) + 1;
        if (n > maxN) {
          break;
        }
        this.element.dataset.w = n;
      }
      var sizeBigger = this.element.offsetWidth;
      while (this.element.offsetWidth > size) {
        var _n = parseInt(this.element.dataset.w) - 1;
        if (_n <= 0) {
          break;
        }
        this.element.dataset.w = _n;
      }
      var sizeSmaller = this.element.offsetWidth;

      // select nearest
      if (Math.abs(sizeSmaller - size) > Math.abs(sizeBigger - size)) {
        var _n2 = parseInt(this.element.dataset.w) + 1;
        if (_n2 <= maxN) {
          this.element.dataset.w = _n2;
        }
      }

      // fix neighbours
      var index = parseInt(this.element.dataset.index.split('-')[1]);
      var elInRow = this._resizeElementInRow;
      var withBefore = elInRow.filter(function (x) {
        return x.index < index;
      }).reduce(function (p, c) {
        return p + c.width;
      }, parseInt(this.element.dataset.w));
      var elInRowAfter = elInRow.filter(function (x) {
        return x.index > index;
      });

      // restore widths
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = elInRowAfter[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _n3 = _step.value;

          _n3.element.dataset.w = _n3.width;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (parseInt(this.element.dataset.w) <= elInRow.find(function (x) {
        return x.element === _this.element;
      }).width) {
        // increase size of next neighbour
        var diff = elInRow.find(function (x) {
          return x.element === _this.element;
        }).width - parseInt(this.element.dataset.w);
        var elAfter = elInRowAfter[0];
        if (elAfter) {
          var newWidth = elAfter.width + diff;
          elAfter.element.dataset.w = newWidth;
        }
      } else {
        // take the first neighbour and reduce width..
        var _n4 = elInRowAfter.length;
        while (_n4 >= 0) {
          var neighbours = elInRowAfter.slice(0, _n4).map(function (x) {
            return Object.assign({}, x);
          }).sort(function (a, b) {
            return b.width - a.width || a.index - b.index;
          });

          var currentWidth = 0;
          var canResize = void 0;
          do {
            canResize = false;
            currentWidth = withBefore + neighbours.reduce(function (p, c) {
              return p + c.width;
            }, 0);
            var i = void 0;
            for (i = 0; i < neighbours.length && currentWidth > maxN; ++i) {
              var neighbour = neighbours[i];
              if (neighbour.width > 1) {
                neighbour.width -= 1;
                canResize = true;
                currentWidth -= 1;
              }
            }
          } while (canResize && currentWidth > maxN);

          if (currentWidth <= maxN) {
            // make it final
            //debugger;

            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = neighbours[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var _n5 = _step2.value;

                _n5.element.dataset.w = _n5.width;
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            break;
          }

          // next try
          _n4 -= 1;
        }
      }
    },
    _resizeStop: function _resizeStop() {
      document.removeEventListener('mousemove', this._resize);
      document.removeEventListener('mouseup', this._resizeStop);
      document.body.removeChild(this._resizeDiv);

      //XXX: not so sexy, might be do able via editor, without direct access ?!
      var value = this.get('editor.value');
      this._resizeElementInRow.map(function (n) {
        return Ember.set.bind(null, value[n.index], 'width', parseInt(n.element.dataset.w));
      }).forEach(function (f) {
        return f();
      });

      var el = this.get('editor').element;
      Ember.run.schedule('afterRender', function () {
        el.classList.remove('hide-placeholders');
      });
    },


    actions: {
      startResize: function startResize(e) {
        if (!this.element) {
          return;
        }
        if (this.get('width') <= 0) {
          return;
        }

        this.get('editor').element.classList.add('hide-placeholders');

        var rowIndex = parseInt(this.element.dataset.rowIndex);
        this._resizeElementInRow = [].concat(_toConsumableArray(this.element.parentElement.parentElement.querySelectorAll("[data-parent=\"" + this.element.dataset.parent + "\"][data-row-index=\"" + rowIndex + "\"]:not(.placeholder)"))).map(function (x) {
          return {
            index: parseInt(x.dataset.index.split('-')[1]),
            width: parseInt(x.dataset.w),
            element: x
          };
        });

        this._maxN = this.get('editor.columns');
        this._resizeX = e.pageX;
        this._resizeY = e.pageY;
        this._resizeW = this.element.offsetWidth;
        e.preventDefault();
        document.addEventListener('mousemove', this._resize);
        document.addEventListener('mouseup', this._resizeStop);
        this._resizeDiv.style.cursor = 'col-resize';
        document.body.appendChild(this._resizeDiv);
      }
    }
  });
});