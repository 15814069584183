define("lt-page-editor/components/blocks/pk-veranstaltungsraeume/lt-page-editor-pk-veranstaltungsraeume-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-veranstaltungsraeume/lt-page-editor-pk-veranstaltungsraeume-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    lokal: Ember.computed.readOnly('model.lokal'),
    linkValue: Ember.computed('model.lokal.id', function () {
      return {
        internal: true,
        data: 's' + Ember.get(this, 'model.lokal.id')
      };
    }),
    hasMore: Ember.computed('lokal.veranstaltungsraeume', function () {
      var raeume = Ember.get(this, 'lokal.veranstaltungsraeume.list.length');
      var total = Ember.get(this, 'lokal.veranstaltungsraeume.count');
      return raeume < total;
    })
  });

  _exports.default = _default;
});