define("lt-page-editor/components/blocks/sp-gewinnspiel/lt-page-editor-sp-gewinnspiel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f218RtXT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"lt-page-editor-sp-gewinnspiel-readonly\",null,[[\"data\",\"value\",\"resolver\",\"edit\"],[[25,[\"data\"]],[25,[\"value\"]],[25,[\"resolver\"]],true]]],false],[0,\"\\n\"],[15,1,[[25,[\"helper\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-gewinnspiel/lt-page-editor-sp-gewinnspiel/template.hbs"
    }
  });

  _exports.default = _default;
});