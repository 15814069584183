define("ui-input/templates/components/ui-input-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xh1OVMhL",
    "block": "{\"symbols\":[\"item\",\"i\",\"&default\"],\"statements\":[[4,\"each\",[[25,[\"_items\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"itemComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[25,[\"itemComponent\"]]],[[\"value\",\"model\"],[[24,2,[]],[24,1,[\"original\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"    \"],[15,3,[[24,2,[]],[24,1,[\"original\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"label\"],[9],[7,\"input\"],[12,\"value\",[24,2,[]]],[12,\"checked\",[24,1,[\"checked\"]]],[11,\"type\",\"checkbox\"],[9],[10],[1,[24,1,[\"original\",\"value\"]],false],[10],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-input/templates/components/ui-input-checkbox.hbs"
    }
  });

  _exports.default = _default;
});