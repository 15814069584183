define("lt-page-editor/utils/send-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function sendAction(self, name) {
    var fn = Ember.get(self, name);

    if (!fn) {
      return;
    }

    if (fn instanceof Function) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return fn.apply(void 0, args);
    }

    console.warn("sendAction: ", "The following is not a function", name, "=>", fn);
    return;
  }

  var _default = sendAction;
  _exports.default = _default;
});