define("lt-page-editor/components/blocks/sp-author/lt-page-editor-sp-author-readonly/component", ["exports", "lt-page-editor/components/blocks/sp-author/lt-page-editor-sp-author-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    publishDate: Ember.computed('model.publishDate', function () {
      var pd = Ember.get(this, 'model.publishDate');

      if (!pd) {
        return null;
      }

      var parts = ((pd || '').split('T')[0] || '').split('-');
      return "".concat(parts[2], ".").concat(parts[1], ".").concat(parts[0]);
    })
  });

  _exports.default = _default;
});