define("ui-page-editor-text/components/ui-page-editor-text-properties/component", ["exports", "ui-page-editor-text/components/ui-page-editor-text-properties/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.computedContainsClass = computedContainsClass;
  exports.computedContainsData = computedContainsData;
  exports.computedContainsAnyClass = computedContainsAnyClass;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function computedContainsClass(cls) {
    return Ember.computed('cursorNode', function () {
      var node = this.get('cursorNode');

      return node && node.classList && node.classList.contains(cls) || false;
    });
  }

  function computedContainsData(d, v) {
    return Ember.computed('cursorNode', function () {
      var node = this.get('cursorNode');

      return node && node.dataset && d in node.dataset && node.dataset[d] == v;
    });
  }

  function computedContainsAnyClass() {
    var cls = [].concat(Array.prototype.slice.call(arguments));
    return Ember.computed('cursorNode', function () {
      var node = this.get('cursorNode');
      if (!node) {
        return false;
      }
      var end = this.get('helper.cursor.endContainer');
      do {
        if (node.classList) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = cls[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var c = _step.value;

              if (node.classList.contains(c)) {
                return true;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }
        node = node.nextElementSibling;
      } while (node && node !== end);
    });
  }

  exports.default = Ember.Component.extend({
    layout: _template.default,
    cursorNode: Ember.computed('helper.cursor.startContainer', 'helper.cursor.startOffset', 'helper.cursor.endContainer', 'helper.cursor.endOffset', function () {
      var startNode = this.get('helper.cursor.startContainer');
      var endNode = this.get('helper.cursor.endContainer');

      if (this._prevActiveComponent) {
        delete this._prevActiveComponent.dataset.active;
        this._prevActiveComponent = null;
      }
      if (!startNode) {
        return;
      }

      var startOffset = this.get('helper.cursor.startOffset');
      var endOffset = this.get('helper.cursor.endOffset');
      if (startOffset !== endOffset) {
        if (startOffset === startNode.innerText.length) {
          if (startNode.nextElementSibling) {
            startNode = startNode.nextElementSibling;
          }
        }
        if (endOffset === 0) {
          if (endNode.previousSibling) {
            endNode = endNode.previousSibling;
          }
        }
      }

      while (startNode.parentElement && startNode.parentElement.tagName !== 'UI-CONTENTEDITABLE') {
        startNode = startNode.parentElement;
      }

      var node2 = this.get('helper.cursor.endContainer');
      while (node2 && node2.parentElement && node2.parentElement.tagName !== 'UI-CONTENTEDITABLE') {
        node2 = node2.parentElement;
      }

      if (startNode && startNode.dataset && 'component' in startNode.dataset && startNode === node2) {
        if (startNode == endNode) {
          this._prevActiveComponent = startNode;
          startNode.dataset.active = true;
        }
      }
      return startNode;
    }),

    willDestroyElement: function willDestroyElement() {
      if (this._prevActiveComponent) {
        delete this._prevActiveComponent.dataset.active;
        this._prevActiveComponent = null;
      }
    },


    styleable: Ember.computed('cursorNode', function () {
      var node = this.get('cursorNode');
      if (!node) {
        return false;
      }
      return true;
      //return !this.get('helper.cursor.collapsed');
    }),
    notStyleable: Ember.computed.not('styleable'),

    hasBold: computedContainsClass("bold"),
    hasItalic: computedContainsClass("italic"),
    hasUnderline: computedContainsClass("underline"),
    hasStrikethrough: computedContainsClass("strikethrough"),
    hasLink: computedContainsData("component", "link"),
    hasBig: computedContainsClass("big"),
    hasSmall: computedContainsClass("small"),
    hasBigOrSmall: Ember.computed.or('hasBig', 'hasSmall'),
    hasNormal: Ember.computed.not('hasBigOrSmall'),

    isAlignLeft: Ember.computed.not("helper.halign"),
    isAlignCenter: Ember.computed.equal("helper.halign", 1),
    isAlignRight: Ember.computed.equal("helper.halign", 2),
    isAlignJustify: Ember.computed.equal("helper.halign", 3),

    isAlignTop: Ember.computed.not("helper.valign"),
    isAlignMiddle: Ember.computed.equal("helper.valign", 1),
    isAlignBottom: Ember.computed.equal("helper.valign", 2),

    hasAnyFormating: computedContainsAnyClass('bold', 'italic', 'underline', 'strikethrough', 'big', 'small'),

    widget: Ember.computed.alias('helper.widget'),

    actions: {
      toggleClass: function toggleClass(c) {
        var cursor = this.get('helper.cursor');
        var node = this.get('cursorNode');
        var cls = node && node.classList && [].concat(_toConsumableArray(node.classList)) || [];

        var _loop = function _loop(cc) {
          if (cc) {
            if (cls.includes(cc)) {
              cls = cls.filter(function (x) {
                return x !== cc;
              });
            } else {
              cls.push(cc);
            }
          }
        };

        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = (c || '').split(' ')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var cc = _step2.value;

            _loop(cc);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        cls.sort();
        if (!cls.length) {
          cls = null;
        }
        cursor.setClass(cls);
      },
      setClass: function setClass(c) {
        var cursor = this.get('helper.cursor');
        var node = this.get('cursorNode');

        var cls = node && node.classList && [].concat(_toConsumableArray(node.classList)) || [];

        for (var _len = arguments.length, removeCls = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          removeCls[_key - 1] = arguments[_key];
        }

        var _loop2 = function _loop2(_c) {
          if (cls.includes(_c)) {
            cls = cls.filter(function (x) {
              return x !== _c;
            });
          }
        };

        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = removeCls[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var _c = _step3.value;

            _loop2(_c);
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = (c || '').split(' ')[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var cc = _step4.value;

            if (cc && !cls.includes(cc)) {
              cls.push(cc);
            }
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }

        cls.sort();
        if (!cls.length) {
          cls = null;
        }
        cursor.setClass(cls);
      },
      removeClass: function removeClass() {
        var cursor = this.get('helper.cursor');

        for (var _len2 = arguments.length, removeCls = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          removeCls[_key2] = arguments[_key2];
        }

        if (!removeCls.length) {
          cursor.setClass(null);
          return;
        }
        // remove classes
        var node = this.get('cursorNode');
        var cls = node && node.classList && [].concat(_toConsumableArray(node.classList)) || [];

        var _loop3 = function _loop3(c) {
          if (cls.includes(c)) {
            cls = cls.filter(function (x) {
              return x !== c;
            });
          }
        };

        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = removeCls[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var c = _step5.value;

            _loop3(c);
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        cls.sort();
        if (!cls.length) {
          cls = null;
        }
        cursor.setClass(cls);
      },
      setComponent: function setComponent(componentName) {
        var cursor = this.get('helper.cursor');
        cursor.setComponent(componentName);
      },
      setHAlign: function setHAlign() {
        this.get('helper.setHAlign').apply(undefined, arguments);
      },
      setVAlign: function setVAlign() {
        this.get('helper.setVAlign').apply(undefined, arguments);
      }
    }

  });
});