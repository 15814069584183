define("ui-page-editor/components/ui-page-editor-add-block/component", ["exports", "ui-page-editor/components/ui-page-editor-add-block/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['isEnd'],
    attributeBindings: ['ignoreSelection:data-ignore-selection'],
    ignoreSelection: true,
    showPopover: false,

    addBlock: function addBlock() {},

    init: function init() {
      this._super.apply(this, arguments);

      this._mouseDownOutside = this._mouseDownOutside.bind(this);
    },
    _mouseDownOutside: function _mouseDownOutside(e) {
      if (this.element && !this.element.contains(e.target)) {
        this.send('closePopover', e);
        document.removeEventListener('mousedown', this._mouseDownOutside);
      }
    },


    actions: {
      onmousedown: function onmousedown(e) {
        e.preventDefault();
      },
      openPopover: function openPopover(e) {
        e;
        this.set('showPopover', true);
      },
      closePopover: function closePopover(e) {
        e;
        this.set('showPopover', false);
      },
      openPopover2: function openPopover2(e) {
        if (!this.get('showPopover')) {
          document.addEventListener('mousedown', this._mouseDownOutside);
        }
        this.send('openPopover', e);
      },
      addBlock: function addBlock() {
        this.sendAction.apply(this, ['addBlock'].concat(Array.prototype.slice.call(arguments)));
        this.send('closePopover');
      }
    }
  });
});