define("lt-page-editor/components/blocks/pk-veranstaltungsraeume/lt-page-editor-pk-veranstaltungsraeume-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LMXgXPOs",
    "block": "{\"symbols\":[\"raum\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pad\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"raeume\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\",\"lokal\",\"veranstaltungsraeume\",\"list\"]]],null,{\"statements\":[[4,\"lt-link\",null,[[\"preview\",\"resolver\",\"transition\",\"link\",\"class\"],[[25,[\"preview\"]],[25,[\"resolver\"]],[25,[\"transition\"]],[29,\"hash\",null,[[\"internal\",\"data\"],[true,[29,\"concat\",[\"r\",[24,1,[\"id\"]]],null]]]],\"raum\"]],{\"statements\":[[0,\"        \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"hasMore\"]]],null,{\"statements\":[[4,\"lt-link\",null,[[\"preview\",\"resolver\",\"transition\",\"link\",\"class\"],[[25,[\"preview\"]],[25,[\"resolver\"]],[25,[\"transition\"]],[25,[\"linkValue\"]],\"more\"]],{\"statements\":[[0,\"      \"],[7,\"button\"],[9],[0,\"Alle \"],[1,[25,[\"lokal\",\"veranstaltungsraeume\",\"count\"]],false],[0,\" Räume anzeigen\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-veranstaltungsraeume/lt-page-editor-pk-veranstaltungsraeume-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});