define("lt-page-editor/components/blocks/title/lt-page-editor-title-properties/component", ["exports", "lt-page-editor/components/blocks/title/lt-page-editor-title-properties/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    layoutItems: Ember.computed(function () {
      return [{
        id: 0,
        value: 'Überschrift'
      }, {
        id: 1,
        value: 'Überschrift + Untertitel'
      }];
    }),
    layoutValue: Ember.computed('helper.layout', {
      get: function get() {
        return Ember.get(this, 'helper.layout');
      },
      set: function set(k, v) {
        k;
        Ember.get(this, 'helper.setLayout')(v);
        return v;
      }
    })
  });

  _exports.default = _default;
});