define("lt-page-editor/components/blocks/inception/lt-page-editor-inception-properties-popup/component", ["exports", "lt-page-editor/components/blocks/inception/lt-page-editor-inception-properties-popup/template", "lt-page-editor/utils/send-action"], function (_exports, _template, _sendAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['selected'],
    bodyElement: Ember.computed(function () {
      return document.body;
    }),
    _value: Ember.computed.oneWay('value'),
    didInsertElement: function didInsertElement() {
      var editor = this.get('editor');
      editor.set('lockSelection', true);
    },
    willDestroyElement: function willDestroyElement() {
      var editor = this.get('editor');
      Ember.run.next(function () {
        Ember.set(editor, 'lockSelection', false);
      });
    },
    actions: {
      close: function close() {
        (0, _sendAction.default)(this, 'close');
      },
      save: function save(v) {
        (0, _sendAction.default)(this, 'save', v);
        (0, _sendAction.default)(this, 'close');
      }
    }
  });

  _exports.default = _default;
});