define("lt-page-editor/helpers/short-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getShortDate = getShortDate;
  _exports.default = void 0;

  function _getShortDate(v, hash) {
    var ndate = new Date(v);
    var day = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'][ndate.getDay()];
    var date = ndate.getDate();
    var month = ndate.getMonth() + 1;

    if (hash.withoutWeekday) {
      return "".concat(date, ".").concat(month, ".");
    }

    return "".concat(day, " ").concat(date, ".").concat(month, ".");
  }

  function getShortDate(args, hash) {
    var v = args[0];
    var v2 = args[1];

    if (v) {
      v = _getShortDate(v, hash);
    }

    if (v2) {
      v2 = _getShortDate(v2, hash);
    }

    if (v && v2 && v != v2) {
      return "".concat(v, " - ").concat(v2);
    }

    return v;
  }

  var _default = Ember.Helper.helper(getShortDate);

  _exports.default = _default;
});