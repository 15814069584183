define("ui-page-editor-text/components/ui-page-editor-text-readonly/component", ["exports", "ui-page-editor-text/components/ui-page-editor-text-readonly/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['classHelper'],
    classHelper: Ember.computed('value.valign', 'value.halign', function () {
      var tmp = '';
      switch (this.get('value.halign')) {
        case 1:
          tmp += "halign-center ";
          break;
        case 2:
          tmp += "halign-right ";
          break;
        case 3:
          tmp += "halign-justify ";
          break;
        default:
          tmp += "halign-left ";
      }
      switch (this.get('value.valign')) {
        case 1:
          tmp += "valign-middle ";
          break;
        case 2:
          tmp += "valign-bottom ";
          break;
        default:
          tmp += "valign-top ";
      }
      return tmp;
    }),
    _value: Ember.computed('value.data', function () {
      var value = this.get('value.data');
      if (!value) {
        return null;
      }
      return value.map(function (x, i, a) {
        if (i % 2 !== 0) {
          return;
        }
        return {
          component: x && x.startsWith('!') && x.split(' ')[0].replace(/^!/, ''),
          cls: x && x.split(' ').filter(function (x) {
            return !x.startsWith('!');
          }).join(' '),
          value: a[i + 1]
        };
      }).filter(function (x) {
        return x;
      });
    })
  });
});