define("ui-input/mixins/masked-tabbing", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _focus: function _focus(e) {
      this._super.apply(this, arguments);

      var selection = window.getSelection() || document.getSelection();

      var new_range = document.createRange();
      new_range.setStart(this.element.firstChild, 0);
      new_range.setEnd(this.element.lastChild, 1);
      selection.removeAllRanges();
      selection.addRange(new_range);
    },
    _blur: function _blur(e) {
      this._super.apply(this, arguments);

      var selection = window.getSelection() || document.getSelection();
      if (this.element.contains(selection.anchorNode)) {
        selection.removeAllRanges();
      }
    },
    _keydown: function _keydown(e) {
      this._super.apply(this, arguments);

      var code = e.keyCode || e.which;
      var shift = e.shiftKey;
      if (code !== 9) {
        if (![8, 16, 37, 39, 40, 38].includes(code)) {
          // select whole placeholder
          var selectionH = window.getSelection() || document.getSelection();
          if (selectionH.isCollapsed) {
            var _node = selectionH.anchorNode;
            while (_node && (!_node.dataset || !_node.dataset.index)) {
              _node = _node.parentNode;
            }
            if (_node && _node.classList.contains('placeholder')) {
              var new_range = document.createRange();
              new_range.setStart(_node, 0);
              new_range.setEnd(_node, 1);
              selectionH.removeAllRanges();
              selectionH.addRange(new_range);
              return;
            }
          }
          // alternative move back until no white space
          if (selectionH.anchorNode.nodeType == Node.TEXT_NODE) {
            var _new_range = document.createRange();
            var npos = selectionH.anchorNode.nodeValue.substr(0, selectionH.anchorOffset).trimRight().length;
            console.log("chagne from", selectionH.anchorOffset, "to", npos);
            _new_range.setStart(selectionH.anchorNode, npos);
            _new_range.setEnd(selectionH.extentNode, selectionH.extentOffset);
            selectionH.removeAllRanges();
            selectionH.addRange(_new_range);
          }
        }
        return;
      }

      var selection = window.getSelection() || document.getSelection();

      // get current index
      var searchForNext = true;
      var node = shift ? selection.focusNode : selection.anchorNode;
      if (this.element === node) {
        node = shift ? node.lastChild : node.firstChild;
        searchForNext = false;
      }
      while (node.nodeType === 3) {
        node = node.parentNode;
      }
      // search next
      if (searchForNext || node.classList.contains('static')) {
        while (node = shift ? node.previousElementSibling : node.nextElementSibling) {
          if (!node.classList.contains('static')) {
            break;
          }
        }
      }

      if (node) {
        e.preventDefault();
        // select this area
        var _new_range2 = document.createRange();
        _new_range2.setStart(node, 0);
        _new_range2.setEnd(node, 1);
        selection.removeAllRanges();
        selection.addRange(_new_range2);
      }
    }
  });
});