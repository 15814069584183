define("lt-page-editor/components/blocks/vr-teaser/lt-page-editor-vr-teaser-readonly/component", ["exports", "lt-page-editor/components/blocks/vr-teaser/lt-page-editor-vr-teaser-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function filter(name, v) {
    return Ember.computed(name, function () {
      var items = (Ember.get(this, name) || []).filter(function (x) {
        return x && (Ember.get(x, 'id') / 1000 | 0) == v;
      }).map(function (x) {
        return x.value;
      });
      return items.join(', ');
    });
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    lokal: Ember.computed.readOnly('model.lokal'),
    vr: Ember.computed.readOnly('model.veranstaltungsraum'),
    essen: filter('model.veranstaltungsraum.attributes', 8),
    ausstattung: filter('model.veranstaltungsraum.attributes', 9),
    extras: filter('model.veranstaltungsraum.attributes', 10),
    imageData: Ember.computed('vr.teaserImage', function () {
      var img = Ember.get(this, 'vr.teaserImage');

      if (img) {
        return {
          data: [img],
          showDesc: 1,
          size: 4
        };
      }
    }),
    website: Ember.computed('lokal.website', function () {
      var website = Ember.get(this, 'lokal.website');

      if (!website) {
        return null;
      }

      return website;
    })
  });

  _exports.default = _default;
});