define("lt-page-editor/components/blocks/pk-open-small/lt-page-editor-pk-open-small-properties/component", ["exports", "lt-page-editor/components/blocks/pk-open-small/lt-page-editor-pk-open-small-properties/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default
  });

  _exports.default = _default;
});