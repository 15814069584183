define('ui-contenteditable/utils/content-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var ContentObject = Ember.Object.extend({
    /**
      0 => Text
      1 => Component
     */
    type: null,
    class: null,
    component: null,
    value: null, // or component data
    static: null,
    contenteditable: null,

    obsValue: Ember.observer('value', function () {
      if (this.type === 1 && this._span) {
        this._setData(this._span, this.get('value'));
      }
    }),

    serialize: function serialize() {
      return JSON.stringify(this.getProperties('type', 'class', 'component', 'value', 'static', 'contenteditable'));
    },
    deserialize: function deserialize(json) {
      this.setProperties(JSON.parse(json));
    },
    _sameArray: function _sameArray(a, b) {
      if (!Array.isArray(a) && !Array.isArray(b)) {
        return a === b;
      }
      if (!Array.isArray(a) || !Array.isArray(b)) {
        return false;
      }
      if (a.length != b.length) {
        return false;
      }
      return a.every(function (x) {
        return b.includes(x);
      });
    },
    equal: function equal(other) {
      return this._sameArray(this.class || [], other.class || []) && (this.type === 0 && other.type === 0 || this.type === 1 && other.type === 1 && this.component === other.component && this.value === other.value);
    },
    append: function append(other) {
      if (this.type !== 0 || this.type !== other.type) {
        throw "Must be type=0 (Text)";
      }
      this.set('value', (this.get('value') || '') + (other.value || ''));
    },
    setText: function setText(cls, value) {
      this.setProperties({
        'type': 0,
        'class': cls && cls.map(function (x) {
          return x.trim();
        }).filter(function (x) {
          return x.length;
        }),
        'value': value
      });
    },
    setComponent: function setComponent(componentName, cls, value) {
      this.setProperties({
        'type': 1,
        'component': componentName,
        'class': cls && cls.map(function (x) {
          return x.trim();
        }).filter(function (x) {
          return x.length;
        }),
        'value': value
      });
    },
    _setAttribute: function _setAttribute(self, key, value) {
      if (key === 'contenteditable') {
        if (value === true) {
          if (self.contentEditable !== 'true') {
            self.contentEditable = 'true';
          }
        } else if (value === false) {
          if (self.contentEditable !== 'false') {
            self.contentEditable = 'false';
          }
        } else if (self.contentEditable !== 'inherit') {
          self.contentEditable = 'inherit';
        }
        return;
      }
      if (value) {
        if (self.getAttribute(key) != value) {
          self.setAttribute(key, value);
        }
      } else {
        if (self.hasAttribute(key)) {
          self.removeAttribute(key);
        }
      }
    },
    _setData: function _setData(self, key, value) {
      if (value) {
        if (self.dataset[key] != value) {
          self.dataset[key] = value;
        }
      } else {
        if (key in self.dataset) {
          delete self.dataset[key];
        }
      }
    },
    createElement: function createElement() {
      var span = this._span || document.createElement('span');
      if (this.type === 1) {
        if (!span.dataset || !span.dataset.component) {
          // force fresh element when not component
          span = document.createElement('span');
        }
      } else {
        if (span.dataset && span.dataset.component) {
          // force fresh element when component
          span = document.createElement('span');
        }
      }
      this._setData(span, 'type', this.type);
      this._setAttribute(span, 'class', (this.class || []).join(' '));
      if (this.static) {
        this._setData(span, 'static', this.value);
      } else {
        this._setData(span, 'static', null);
      }
      if (this.type === 0) {
        this._setAttribute(span, 'contenteditable', this.contenteditable);
        if (span.childNodes.length != 1 || span.childNodes[0].nodeType !== Node.TEXT_NODE) {
          // only when size wrong
          [].concat(_toConsumableArray(span.childNodes)).forEach(function (x) {
            return x.remove();
          });
          span.appendChild(document.createTextNode(this.value));
        } else {
          var text = span.childNodes[0];
          if (text.nodeValue !== this.value) {
            text.nodeValue = this.value;
          }
        }
      } else if (this.type === 1) {
        this._setAttribute(span, 'contenteditable', false);
        this._setData(span, 'component', this.get('component'));
        this._setData(span, 'value', this.value);
      }
      this.set('_span', span);
      return span;
    },
    split: function split(from, to) {
      if (this.type !== 0) {
        throw "Must be type=0 (Text)";
      }
      to = to || this.value.length;

      var prev = ContentObject.create();
      var after = ContentObject.create();
      prev.setText(this.class, this.value.substring(0, from));
      after.setText(this.class, this.value.substring(to, this.value.length));

      this.set('value', this.value.substring(from, to));

      return [prev, after];
    }
  });

  exports.default = ContentObject;
});