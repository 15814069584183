define("ui-page-editor/components/ui-page-editor-readonly/component", ["exports", "ui-page-editor/components/ui-page-editor-readonly/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    columns: 8,
    columnWidth: '128px',
    columnMaxWidth: null,
    columnMinWidth: null,
    _style: Ember.computed('elementId', 'columns', 'columnWidth', 'columnMaxWidth', 'columnMinWidth', function () {
      var columns = this.get('columns');
      var columnWidth = this.get('columnWidth');
      var hasMinMax = this.get('columnMinWidth') || this.get('columnMaxWidth');
      var columnMinWidth = hasMinMax ? this.get('columnMinWidth') : columnWidth;
      var columnMaxWidth = hasMinMax ? this.get('columnMaxWidth') : columnWidth;
      var elementId = this.get('elementId');
      return "." + elementId + "-ui-wysiwyg-col {\n  width: calc(" + columns + " * " + columnWidth + ");\n  min-width: calc(" + columns + " * " + columnMinWidth + ");\n  max-width: calc(" + columns + " * " + columnMaxWidth + ");\n}\n" + Array.from(new Array(columns + 3), function (_, i) {
        var j = i - 2;
        if (j < 0) {
          j = columns;
        }
        return "." + elementId + "-ui-wysiwyg-col[data-w=\"" + (i - 2) + "\"] {\n  width: calc(" + j + " * " + columnWidth + ");\n  min-width: calc(" + j + " * " + columnMinWidth + ");\n  max-width: calc(" + j + " * " + columnMaxWidth + ");\n}";
      }).join('\n') + ("\n#" + elementId + " {\n  width: calc(" + columns + " * " + columnWidth + ");\n  min-width: calc(" + columns + " * " + columnMinWidth + ");\n  max-width: calc(" + columns + " * " + columnMaxWidth + ");\n}");
    })
  });
});