define("lt-page-editor/components/lt-page-editor-delay-render/component", ["exports", "lt-page-editor/components/lt-page-editor-delay-render/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    idx: 0,
    done: false,
    helper1: null,
    helper2: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.helper1 = document.createComment("DELAY-START");
      this.helper2 = document.createComment("DELAY-END");
    },
    _checkDisplay: function _checkDisplay() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.idx--;

      if (this.idx <= 0) {
        this.animate = true;
        Ember.set(this, 'done', true);
        return;
      }

      requestAnimationFrame(this._checkDisplay.bind(this));
    },
    didInsertElement: function didInsertElement() {
      requestAnimationFrame(this._checkDisplay.bind(this));
    },
    didRender: function didRender() {
      if (!this.animate) {
        return;
      }

      this.animate = false;
      var node = this.helper1.nextElementSibling;

      while (node && node != this.helper2) {
        if ("animate" in node) {
          node.animate([{
            transform: 'translateY(-20px)',
            opacity: 0.1
            /* LCP */

          }, {
            transform: 'translateY(0)',
            opacity: 1
          }], {
            duration: 150
          });
        }

        node = node.nextElementSibling;
      }
    }
  });

  _exports.default = _default;
});