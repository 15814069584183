define("lt-page-editor/components/blocks/sp-gewinnspiel/lt-page-editor-sp-gewinnspiel-readonly/component", ["exports", "lt-page-editor/components/blocks/sp-gewinnspiel/lt-page-editor-sp-gewinnspiel-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function shuffle(a) {
    var j, x, i;

    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }

    return a;
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    data: Ember.computed('value.data.link.data', function () {
      var data = Ember.get(this, 'value.data.link.data');

      if (!data) {
        return;
      }

      var resolver = Ember.get(this, 'resolver');

      if (!resolver) {
        console.warn("RESOLVER NOT SET");
        return;
      }

      return resolver('gewinnspiel', data, {
        supportAsync: false
      });
    }),
    questions: Ember.computed('data', function () {
      var data = Ember.get(this, 'data');

      if (!data) {
        return null;
      }

      return shuffle(_toConsumableArray(data.fragen || [])).map(function (x) {
        return Object.assign({}, x, {
          antworten: shuffle(_toConsumableArray(x.antworten || []))
        });
      });
    }),
    finish: Ember.computed('questions', function () {
      return (Ember.get(this, 'questions') || []).every(function (x) {
        return (Ember.get(x, 'antworten') || []).some(function (y) {
          return Ember.get(y, 'selected');
        });
      });
    }),
    actions: {
      update_finish: function update_finish() {
        this.notifyPropertyChange('finish');
      },
      select: function select(answer, question, e) {
        e.preventDefault();
        (answer.antworten || []).forEach(function (x) {
          return Ember.set(x, 'selected', false);
        });
        Ember.set(question, 'selected', true);
      },
      submit: function submit(questions, e) {
        e.preventDefault();
        var res = [];
        (questions || []).forEach(function (y) {
          (Ember.get(y, 'antworten') || []).forEach(function (x) {
            if (Ember.get(x, 'selected')) {
              res.push(Ember.get(x, 'publicId'));
            }
          });
        });
        var event = new CustomEvent("lt-page-editor-sp-gewinnspiel", {
          detail: {
            gruppe_id: Ember.get(this, 'value.data.link.data').match(/([0-9].*$)/)[0] | 0,
            answers: res
          },
          bubbles: true,
          cancelable: false
        });
        e.currentTarget.dispatchEvent(event);
      }
    }
  });

  _exports.default = _default;
});