define("lt-page-editor/components/lt-page-editor-add-block/component", ["exports", "lt-page-editor/components/lt-page-editor-add-block/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    bodyElement: Ember.computed(function () {
      return document.body;
    }),
    blocks: Ember.computed('width', 'withoutPk', 'withoutInception', 'search', function () {
      var width =
      /*get(this, 'width')*/
      1;
      var withoutPk = Ember.get(this, 'withoutPk');
      var withoutInception = Ember.get(this, 'withoutInception');
      var search = (Ember.get(this, 'search') || '').split(' ').filter(function (x) {
        return x;
      });
      var blocks = [{
        filterPk: true,
        title: 'Block - mit Titel',
        icon: '/lt-page-editor/block_inception2_title.svg',
        default: {
          width: 1,
          component: 'pkBlock',
          data: {
            withTitle: true,
            version: 2,
            withText: true,
            withImage: false
          }
        }
      }, {
        keywords: ['image'],
        filterPk: true,
        title: 'Block - Bild und Titel',
        icon: '/lt-page-editor/block_img_title.svg',
        default: {
          width: 1,
          component: 'pkBlock',
          data: {
            withTitle: true,
            version: 2,
            withText: false,
            withImage: true
          }
        }
      }, {
        keywords: ['inception'],
        filterPk: true,
        title: 'Block',
        icon: '/lt-page-editor/block_inception2.svg',
        default: {
          width: 1,
          component: 'pkBlock',
          data: {
            withTitle: false,
            version: 2,
            withText: true,
            withImage: false
          }
        }
      }, {
        keywords: ['image'],
        filterPk: true,
        title: 'Block - Bild',
        icon: '/lt-page-editor/block_img.svg',
        default: {
          width: 1,
          component: 'pkBlock',
          data: {
            withTitle: false,
            version: 2,
            withText: false,
            withImage: true
          }
        }
      }, {
        seperator: true
      }, {
        keywords: ['teaser', 'small'],
        filterPk: true,
        title: 'Spezial - Kleiner Teaser',
        icon: '/lt-page-editor/block_small_teaser.svg',
        default: {
          width: 1,
          component: 'smallTeaser'
        }
      }, {
        filterInception: true,
        title: 'Premium - Teaser Text',
        icon: '/lt-page-editor/block_pk_teaser.svg',
        default: {
          width: 1,
          component: 'pkTeaser'
        }
      }, {
        filterPk: true,
        filterInception: true,
        title: 'Premium - Teaser Bild',
        icon: '/lt-page-editor/block_pk_teaser.svg',
        default: {
          width: 1,
          component: 'pkTeaserImage'
        }
      }, {
        filterPk: true,
        filterInception: true,
        title: 'Nicht Premium - Teaser',
        icon: '/lt-page-editor/block_pk_teaser.svg',
        default: {
          width: -1,
          component: 'npkTeaser'
        }
      }, {
        seperator: true
      }, {
        title: 'Premium - Kontakt',
        icon: '/lt-page-editor/block_pk_contact.svg',
        default: {
          width: 1,
          component: 'pkContact'
        }
      }, {
        title: 'Premium - aktuelle Öffnungszeiten',
        icon: '/lt-page-editor/block_pk_open.svg',
        default: {
          width: 1,
          component: 'pkOpenSmall'
        }
      }, {
        title: 'Premium - Öffnungszeiten',
        icon: '/lt-page-editor/block_pk_open2.svg',
        default: {
          width: 1,
          component: 'pkOpenFull'
        }
      }, {
        title: 'Premium - Ausstattung',
        icon: '/lt-page-editor/block_article_list.svg',
        default: {
          width: 1,
          component: 'pkAusstattung'
        }
      }, {
        title: 'Premium - Veranstaltungsräume',
        icon: '/lt-page-editor/block_pk_contact.svg',
        default: {
          width: 1,
          component: 'pkVeranstaltungsraeume'
        }
      }, {
        seperator: true
      }, {
        title: 'Artikel - Titel',
        icon: '/lt-page-editor/block_article_title.svg',
        default: {
          width: -1,
          component: 'title'
        }
      }, {
        title: 'Artikel - Trennlinie',
        icon: '/lt-page-editor/block_article_hr.svg',
        default: {
          width: -1,
          component: 'hr'
        }
      }, {
        filterInception: true,
        filterPk: true,
        title: 'Artikel - Teaser',
        icon: '/lt-page-editor/block_teaser.svg',
        default: {
          width: -1,
          component: 'teaser'
        }
      }, {
        title: 'Artikel - Text',
        icon: '/lt-page-editor/block_article_text.svg',
        default: {
          width: width,
          component: 'text'
        }
      }, {
        keywords: ['image'],
        title: 'Artikel - Bild',
        icon: '/lt-page-editor/block_article_img.svg',
        default: {
          width: 1,
          component: 'image'
        }
      }, {
        keywords: ['inception'],
        filterInception: true,
        title: 'Spezial - Blöcke in Block',
        icon: '/lt-page-editor/block_inception.svg',
        default: {
          width: width,
          component: 'inception'
        }
      }, {
        filterInception: true,
        filterPk: true,
        title: 'Spezial - Author',
        icon: '/lt-page-editor/block_article_author.svg',
        default: {
          width: -1,
          component: 'author'
        }
      }, {
        filterPk: true,
        filterInception: true,
        title: 'Special - Adventkalender',
        icon: '/lt-page-editor/block_advent.svg',
        default: {
          width: -1,
          component: 'spAdventCalendar'
        }
      }, {
        filterPk: true,
        title: 'Special - Gewinnspiel',
        icon: '/lt-page-editor/block_gewinnspiel.svg',
        default: {
          width: -1,
          component: 'spGewinnspiel'
        }
      }, {
        title: 'Special - Top Event Kalender',
        icon: '/lt-page-editor/block_calendar.svg',
        default: {
          width: 1,
          component: 'spCalendarTop'
        }
      }, {
        title: 'Special - Event Kalender',
        icon: '/lt-page-editor/block_calendar.svg',
        default: {
          width: 1,
          component: 'spCalendar'
        }
      }, {
        title: 'Veranstaltungsraum - Infos',
        icon: '/lt-page-editor/block_calendar.svg',
        default: {
          width: -1,
          component: 'vrTeaser'
        }
      }, {
        title: 'Premium - Tisch reservieren',
        icon: '/lt-page-editor/block_article_title.svg',
        default: {
          width: 1,
          component: 'pkBook'
        }
      }, {
        title: 'Premium - Lieferando.at',
        icon: '/lt-page-editor/block_article_title.svg',
        default: {
          width: 1,
          component: 'pkLieferando'
        }
      }, {
        title: 'Special - Newsletter Anmelden Formular',
        icon: '/lt-page-editor/block_article_title.svg',
        default: {
          width: 1,
          component: 'spNewsletter'
        }
      }, {
        title: 'Special - DB Content',
        icon: '/lt-page-editor/block_text.svg',
        default: {
          width: 1,
          component: 'dbContent'
        }
      }];
      blocks = blocks.filter(function (x) {
        return !(withoutInception && x.filterInception === withoutInception || withoutPk && x.filterPk === withoutPk);
      });

      if (search.length) {
        blocks = blocks.filter(function (x) {
          if (x.seperator) {
            return true;
          }

          return search.some(function (y) {
            return x.title.toLowerCase().includes(y.toLowerCase());
          }) || search.some(function (y) {
            return x.keywords && x.keywords.some(function (z) {
              return z.toLowerCase().includes(y.toLowerCase());
            });
          });
        });
      }

      return blocks;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'search', window._searchAddBlock || '');
    },
    willDestroyElement: function willDestroyElement() {
      window._searchAddBlock = Ember.get(this, 'search') || '';
    },
    didInsertElement: function didInsertElement() {
      var popup = document.querySelector('#' + this.elementId + '-popup');
      var input = popup.querySelector('.quick-search input');
      input.focus();
      input.select();
    }
  });

  _exports.default = _default;
});