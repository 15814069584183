define("ui-page-editor-text/components/ui-page-editor-text-widget-link-readonly/component", ["exports", "ui-page-editor-text/components/ui-page-editor-text-widget-link-readonly/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,

    init: function init() {
      this._super.apply(this, arguments);

      this.model = {};
      try {
        this.model = JSON.parse(this.get('value'));
      } catch (e) {
        e;
        /* IGNORE */
      }
    }
  });
});