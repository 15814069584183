define("ui-page-editor-text/utils/computed-json", ["exports", "ui-page-editor-text/utils/track-changes-object"], function (exports, _trackChangesObject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (key) {
    function updateValue(value) {
      var asJson = JSON.stringify(value);
      if (asJson !== Ember.get(this, key)) {
        Ember.set(this, key, asJson);
      }
    }

    return (0, _trackChangesObject.computedTrackChanges)(key, function (v) {
      Ember.run.once(this, updateValue, v);
    }, function () {
      try {
        return JSON.parse(Ember.get(this, key));
      } catch (e) {
        return {};
      }
    });
  };
});