define("lt-page-editor/components/blocks/pk-teaser-image/lt-page-editor-pk-teaser-image-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-teaser-image/lt-page-editor-pk-teaser-image-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    _value: Ember.computed.alias('value.data'),
    lokal: Ember.computed.readOnly('model.lokal'),
    imageData: Ember.computed('lokal.teaserImage', function () {
      var img = Ember.get(this, 'lokal.teaserImage');

      if (img) {
        return {
          data: [img],
          showDesc: 1,
          size: 4
        };
      }
    })
  });

  _exports.default = _default;
});