define("ui-page-editor/components/ui-page-editor-wysiwyg-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mz66XMxD",
    "block": "{\"symbols\":[\"c\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"selected\"]]],null,{\"statements\":[[15,2,[[29,\"hash\",null,[[\"noblock\",\"editor\",\"isRow\",\"isSelected\",\"index\"],[true,[25,[\"editor\"]],true,true,[25,[\"index\"]]]]]]]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[25,[\"withoutSections\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"handle\"],[12,\"ondragstart\",[29,\"action\",[[24,0,[]],\"startDrag\"],null]],[12,\"ondragend\",[29,\"action\",[[24,0,[]],\"endDrag\"],null]],[11,\"draggable\",\"true\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"drag_handle\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\",\"data\"]]],[[\"key\"],[\"index\"]],{\"statements\":[[0,\"    \"],[15,2,[[24,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"dropmask\"],[12,\"ondragenter\",[29,\"action\",[[24,0,[]],\"dragEnter\"],null]],[12,\"ondragover\",[29,\"action\",[[24,0,[]],\"dragOver\"],null]],[12,\"ondragleave\",[29,\"action\",[[24,0,[]],\"dragLeave\"],null]],[12,\"ondrop\",[29,\"action\",[[24,0,[]],\"drop\"],null]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"dropbefore\"],[12,\"ondragenter\",[29,\"action\",[[24,0,[]],\"dragEnter\"],null]],[12,\"ondragover\",[29,\"action\",[[24,0,[]],\"dragOver\"],null]],[12,\"ondragleave\",[29,\"action\",[[24,0,[]],\"dragLeave\"],null]],[12,\"ondrop\",[29,\"action\",[[24,0,[]],\"dropBefore\"],null]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"dropafter\"],[12,\"ondragenter\",[29,\"action\",[[24,0,[]],\"dragEnter\"],null]],[12,\"ondragover\",[29,\"action\",[[24,0,[]],\"dragOver\"],null]],[12,\"ondragleave\",[29,\"action\",[[24,0,[]],\"dragLeave\"],null]],[12,\"ondrop\",[29,\"action\",[[24,0,[]],\"dropAfter\"],null]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor/components/ui-page-editor-wysiwyg-row/template.hbs"
    }
  });

  _exports.default = _default;
});