define("lt-page-editor/components/blocks/vr-teaser/lt-page-editor-vr-teaser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q2c/M6XE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[25,[\"helper\"]]]],[0,\"\\n\"],[1,[29,\"lt-page-editor-pk-contact-readonly\",null,[[\"model\",\"value\",\"resolver\"],[[25,[\"model\"]],[25,[\"value\"]],[25,[\"resolver\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/vr-teaser/lt-page-editor-vr-teaser/template.hbs"
    }
  });

  _exports.default = _default;
});