define("lt-page-editor/components/blocks/pk-lieferando/lt-page-editor-pk-lieferando-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cyFRJUJQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"lieferando\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[23,\"lieferando\"]],[11,\"class\",\"button\"],[11,\"rel\",\"noopener\"],[11,\"target\",\"_blank\"],[9],[7,\"img\"],[12,\"src\",[29,\"root-url\",[\"/lt-page-editor/social/lieferando.svg\"],null]],[9],[10],[7,\"span\"],[9],[4,\"if\",[[25,[\"text\"]]],null,{\"statements\":[[1,[23,\"text\"],false]],\"parameters\":[]},{\"statements\":[[0,\" Bei Lieferando.at bestellen\"]],\"parameters\":[]}],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-lieferando/lt-page-editor-pk-lieferando-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});