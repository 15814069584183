define("lt-page-editor/components/blocks/title/lt-page-editor-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0VQy6gNt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[1,[29,\"ui-input-text\",null,[[\"class\",\"value\"],[\"text1\",[25,[\"_text1\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"value\",\"layout\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"ui-input-text\",null,[[\"class\",\"value\"],[\"text2\",[25,[\"_text2\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[15,1,[[25,[\"helper\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/title/lt-page-editor-title/template.hbs"
    }
  });

  _exports.default = _default;
});