define("ui-input/templates/components/ui-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u7ZeyyMC",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"component\",[[29,\"concat\",[\"ui-input-\",[25,[\"type\"]]],null]],[[\"class\",\"value\",\"accept\",\"alt\",\"autocomplete\",\"autofocus\",\"checked\",\"disabled\",\"max\",\"maxlength\",\"min\",\"name\",\"placeholder\",\"readonly\",\"required\",\"size\",\"step\",\"type\",\"items\",\"onchange\",\"onfocus\",\"onblur\"],[[25,[\"class\"]],[25,[\"value\"]],[25,[\"accept\"]],[25,[\"alt\"]],[25,[\"autocomplete\"]],[25,[\"autofocus\"]],[25,[\"checked\"]],[25,[\"disabled\"]],[25,[\"max\"]],[25,[\"maxlength\"]],[25,[\"min\"]],[25,[\"name\"]],[25,[\"placeholder\"]],[25,[\"readonly\"]],[25,[\"required\"]],[25,[\"size\"]],[25,[\"step\"]],[25,[\"type\"]],[25,[\"items\"]],[25,[\"onchange\"]],[25,[\"onfocus\"]],[25,[\"onblur\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-input/templates/components/ui-input.hbs"
    }
  });

  _exports.default = _default;
});