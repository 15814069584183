define("lt-page-editor/components/blocks/sp-newsletter/lt-page-editor-sp-newsletter-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/1IbK3Vb",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"success\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"success\"],[9],[0,\"\\n    Erfolgreich angemeldet\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"form\"],[12,\"onsubmit\",[29,\"action\",[[24,0,[]],\"submit\"],null]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"label\"],[9],[0,\"E-Mail Adresse\"],[10],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"type\",\"value\"],[\"email\",[25,[\"email\"]]]]],false],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"\\n        \"],[1,[29,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[25,[\"acceptDatenschutz\"]]]]],false],[0,\" Ich stimme den\\n\"],[4,\"lt-link\",null,[[\"resolver\",\"transition\",\"link\"],[[25,[\"resolver\"]],[25,[\"transition\"]],[29,\"hash\",null,[[\"internal\",\"data\"],[true,\"p4\"]]]]],{\"statements\":[[0,\"          Datenschutzbedingungen\\n        \"]],\"parameters\":[]},null],[0,\" zu\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right\"],[9],[0,\"\\n      \"],[7,\"input\"],[11,\"value\",\"Anmelden\"],[12,\"disabled\",[23,\"cannotSubmit\"]],[11,\"type\",\"submit\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-newsletter/lt-page-editor-sp-newsletter-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});