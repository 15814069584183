define("lt-page-editor/components/blocks/pk-block/lt-page-editor-pk-block-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jZm2t+Az",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"helper\",\"properties\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"helper\",\"properties\"]]],[[\"editor\",\"helper\",\"value\"],[[25,[\"editor\"]],[25,[\"helper\",\"helper\"]],[25,[\"helper\",\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"helper\",\"withTitle\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"lt-page-editor-text-link-button\",null,[[\"hasLink\",\"linkData\",\"editor\",\"resolver\",\"saveLink\",\"removeLink\",\"uploader\",\"onInternalPage\"],[[29,\"if\",[[25,[\"linkData\"]],true,false],null],[25,[\"linkData\"]],[25,[\"editor\"]],[25,[\"resolver\"]],[29,\"action\",[[24,0,[]],\"saveLink\"],null],[29,\"action\",[[24,0,[]],\"removeLink\"],null],[25,[\"uploader\"]],[25,[\"onInternalPage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-block/lt-page-editor-pk-block-properties/template.hbs"
    }
  });

  _exports.default = _default;
});