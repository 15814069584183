define("lt-page-editor/components/blocks/pk-contact/lt-page-editor-pk-contact-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-contact/lt-page-editor-pk-contact-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getUrl(x) {
    try {
      var url = new URL(x);

      if (url.host.includes('awin1.com')) {
        return url.searchParams.get('ued');
      }

      return url.href;
    } catch (ex) {
      return x;
    }
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    lokal: Ember.computed.readOnly('model.lokal'),
    socialmedia: Ember.computed('lokal.websites', function () {
      var social = [{
        name: 'facebook',
        title: 'Facebook',
        icon: '/lt-page-editor/social/facebook.svg'
      }, {
        name: 'instagram',
        title: 'Instagram',
        icon: '/lt-page-editor/social/instagram.svg'
      }, {
        name: 'tripadvisor',
        title: 'Tripadvisor',
        icon: '/lt-page-editor/social/tripadvisor.svg'
      }, {
        name: 'twitter',
        title: 'Twitter',
        icon: '/lt-page-editor/social/twitter.svg'
      }, {
        name: 'youtube',
        title: 'YouTube',
        icon: '/lt-page-editor/social/youtube.svg'
      }, {
        name: 'lieferando',
        title: 'Lieferando',
        icon: '/lt-page-editor/social/lieferando.svg'
      }];
      var websites = Ember.get(this, 'lokal.websites');

      if (!websites) {
        return;
      }

      websites = websites.filter(function (x) {
        return social.some(function (y) {
          return getUrl(x).toLowerCase().includes(y.name.toLowerCase());
        });
      });
      return websites.map(function (x) {
        return {
          icon: social.find(function (y) {
            return getUrl(x).toLowerCase().includes(y.name.toLowerCase());
          }).icon,
          href: x,
          title: social.find(function (y) {
            return getUrl(x).toLowerCase().includes(y.name.toLowerCase());
          }).title
        };
      });
    }),
    website: Ember.computed('lokal.website', function () {
      var website = Ember.get(this, 'lokal.website');

      if (!website) {
        return null;
      }

      return website;
    })
  });

  _exports.default = _default;
});