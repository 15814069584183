define("ui-input/components/ui-textarea-richtext", ["exports", "ui-input/templates/components/ui-textarea-richtext", "ui-contenteditable/components/ui-contenteditable"], function (exports, _uiTextareaRichtext, _uiContenteditable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ui-textarea-richtext',
    multiline: true,
    layout: _uiTextareaRichtext.default,
    content: Ember.computed('value', {
      get: function get(k) {
        k;
        var text = this.get('value') || [];
        if (!(text instanceof Array)) {
          throw "Value must be an Array";
        }
        return text.map(function (value, index, array) {
          if (index % 2 === 0) {
            return;
          }
          var cls = (array[index - 1] || "").split(' ');
          if (!cls.length) {
            cls = null;
          }
          return {
            value: value,
            class: cls
          };
        }).filter(function (x) {
          return x;
        }).map(function (x) {
          var tmp = _uiContenteditable.ContentObject.create();
          if (x.class && x.class.length && x.class[0].startsWith('!')) {
            var ncls = x.class.filter(function (y) {
              return !y.startsWith('!');
            });
            if (!ncls.length) {
              ncls = null;
            }
            tmp.setComponent(x.class[0].replace('!', ''), ncls, x.value);
          } else {
            tmp.setText(x.class, x.value);
          }
          return tmp;
        });
      },
      set: function set(k, v) {
        var text = [];
        v.forEach(function (x) {
          var cls = x.class && x.class.length && x.class.sort().join(' ') || null;
          if (x.type === 1) {
            // component !
            text.push(["!" + x.get('component'), cls || []].join(' '));
          } else {
            text.push(cls);
          }
          text.push(x.value);
        });
        var value = this.get('value') || [];
        if (JSON.stringify(text) !== JSON.stringify(value)) {
          this.set('value', text);
        }
        return v;
      }
    })
  });
});