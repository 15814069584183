define("lt-page-editor/components/blocks/inception/lt-page-editor-inception-properties-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wRRTs/Hd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"in-element\",[[25,[\"bodyElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[30,[[23,\"elementId\"],\"-popup\"]]],[12,\"class\",[30,[[23,\"componentCssClassName\"],\"-popup-area\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"popup\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"\\n        \"],[7,\"span\"],[9],[0,\"Inhalt Bearbeiten\"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"close\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"close\"],null]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"sep\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"scroller\"],[9],[0,\"\\n              \"],[1,[29,\"component\",[[25,[\"pageEditor\"]]],[[\"value\",\"toolbar\"],[[25,[\"_value\"]],[25,[\"toolbar\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"buttons\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n              \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"close\"],null]],[9],[0,\"Abbrechen\"],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"style\",\"flex-grow: 1\"],[9],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n              \"],[7,\"button\"],[12,\"disabled\",[23,\"uploading\"]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"save\",[25,[\"_value\"]]],null]],[9],[0,\"Übernehmen\"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/inception/lt-page-editor-inception-properties-popup/template.hbs"
    }
  });

  _exports.default = _default;
});