define("lt-page-editor/components/blocks/pk-open-full/lt-page-editor-pk-open-full-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-open-full/lt-page-editor-pk-open-full-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function pad(str) {
    return ('00' + str).substr(-2);
  }

  function timeToNumber(x, h24) {
    if (x) {
      var tmp = "".concat(x).split(':');
      var hours = parseInt(tmp[0], 10);
      var minutes = parseInt(tmp[1], 10);

      if (h24 && hours === 0 && minutes === 0) {
        hours += 24;
      }

      return hours * 100 + minutes;
    }

    return NaN;
  }

  function numberToTime(x) {
    if (x || x === 0) {
      return "".concat(pad(Math.floor(x / 100)), ":").concat(pad(x % 100));
    }

    return null;
  }

  function evaluateOpenHours(h) {
    if (!h || !h.length) {
      return null;
    }

    var now = new Date();
    var nowDateDay = now.getDay();
    var nowDayId = (nowDateDay + 6) % 7 + 1;
    var transformed = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag', 'Feiertage'].map(function (name, i) {
      var dayId = i + 1;
      var day = h.find(function (x) {
        return x.dayId === dayId;
      });
      var res = {
        dayId: dayId,
        dayIds: [dayId],
        name: name,
        names: [name]
      };

      if (day) {
        res.from1 = numberToTime(timeToNumber(day.values[0]));
        res.to1 = numberToTime(timeToNumber(day.values[1], true));
        res.from2 = numberToTime(timeToNumber(day.values[2]));
        res.to2 = numberToTime(timeToNumber(day.values[3], true));
      }

      return res;
    }); // compress

    var compressed = transformed.reduce(function (arr, day, i) {
      // don't compress feiertage and skip the first one
      if (i === 0 || i === 7) {
        arr.push(day);
        return arr;
      } // get the last item we have


      var prev = arr[arr.length - 1];

      if (prev && prev.from1 === day.from1 && prev.to1 === day.to1 && prev.from2 === day.from2 && prev.to2 === day.to2) {
        prev.dayIds.push(day.dayIds[0]);
        prev.names.push(day.names[0]);
      } else {
        arr.push(day);
      }

      return arr;
    }, []); // update the names

    compressed.forEach(function (day) {
      // set if it contains todays date and if we have double times
      day.today = day.dayIds.includes(nowDayId);
      day.dual = !!day.from2; // keep feiertage text complete

      if (day.dayId === 8) {
        return;
      } // if there's only one, take the full name


      if (day.names.length === 1) {
        day.name = day.names[0].substr(0, 2) + '.';
        return;
      } // otherwise stitch together the first and last


      var start = day.names[0].substr(0, 2);
      var end = day.names[day.names.length - 1].substr(0, 2);
      day.name = "".concat(start, ". - ").concat(end, ".");
    });
    return compressed;
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    lokal: Ember.computed('model.lokal.openHours.@each.values', function () {
      var h = Ember.get(this, 'model.lokal.openHours');
      return evaluateOpenHours.call(this, h);
    }),
    kitchen: Ember.computed('model.lokal.kitchenHours.@each.values', function () {
      var h = Ember.get(this, 'model.lokal.kitchenHours');
      return evaluateOpenHours.call(this, h);
    })
  });

  _exports.default = _default;
});