define("lt-page-editor/components/blocks/pk-ausstattung/lt-page-editor-pk-ausstattung-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9mnvNLs1",
    "block": "{\"symbols\":[\"value\",\"key\",\"txt\",\"idx\"],\"statements\":[[4,\"each\",[[29,\"-each-in\",[[29,\"hash\",null,[[\"1\",\"2\",\"3\",\"4\",\"5\",\"6\"],[[29,\"hash\",null,[[\"name\",\"items\",\"link\"],[\"Kategorie\",[25,[\"kategorien\"]],\"xkategorie:\"]]],[29,\"hash\",null,[[\"name\",\"items\",\"link\"],[\"Küche\",[25,[\"kuechen\"]],\"xküche:\"]]],[29,\"hash\",null,[[\"name\",\"items\"],[\"Bezahlmöglichkeit\",[25,[\"bezahlmoeglichkeiten\"]]]]],[29,\"hash\",null,[[\"name\",\"items\"],[\"Ausstattung\",[25,[\"ausstattung\"]]]]],[29,\"hash\",null,[[\"name\",\"items\"],[\"Angebot\",[25,[\"angebot\"]]]]],[29,\"hash\",null,[[\"name\",\"items\"],[\"Extra\",[25,[\"extra\"]]]]]]]]],null]],null,{\"statements\":[[4,\"if\",[[24,1,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"category\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n      \"],[4,\"each\",[[24,1,[\"items\"]]],null,{\"statements\":[[4,\"if\",[[24,4,[]]],null,{\"statements\":[[0,\", \"]],\"parameters\":[]},null],[4,\"if\",[[24,1,[\"link\"]]],null,{\"statements\":[[4,\"lt-link\",null,[[\"resolver\",\"transition\",\"link\",\"class\"],[[25,[\"resolver\"]],[25,[\"transition\"]],[29,\"hash\",null,[[\"iternal\",\"data\"],[true,[29,\"concat\",[[24,1,[\"link\"]],[29,\"first-word\",[[24,3,[]]],[[\"lower\"],[true]]]],null]]]],\"title\"]],{\"statements\":[[1,[24,3,[]],false]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[1,[24,3,[]],false]],\"parameters\":[]}]],\"parameters\":[3,4]},null],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-ausstattung/lt-page-editor-pk-ausstattung-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});