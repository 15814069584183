define("lt-page-editor/components/blocks/title/lt-page-editor-title-readonly/component", ["exports", "lt-page-editor/components/blocks/title/lt-page-editor-title-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default
  });

  _exports.default = _default;
});