define("lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ivQIHab+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"fromDB\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"lokal\",\"nameBefore\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"name-before\"],[9],[1,[25,[\"lokal\",\"nameBefore\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"name\"],[9],[1,[25,[\"lokal\",\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"lokal\",\"nameAfter\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"name-after\"],[9],[1,[25,[\"lokal\",\"nameAfter\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"text\"],[9],[1,[25,[\"lokal\",\"teaserText\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"_value\",\"nameBefore\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"name-before\"],[9],[1,[25,[\"_value\",\"nameBefore\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"name\"],[9],[1,[25,[\"_value\",\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"_value\",\"nameAfter\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"name-after\"],[9],[1,[25,[\"_value\",\"nameAfter\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"text without-padding\"],[9],[0,\"\\n      \"],[1,[29,\"component\",[[25,[\"inception\"]]],[[\"class\",\"columns\",\"columnWidth\",\"editor\",\"value\"],[\"inception2\",[25,[\"value\",\"width\"]],[25,[\"textWidth\"]],[25,[\"editor\"]],[25,[\"_value\",\"inception\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});