define("lt-page-editor/components/blocks/hr/lt-page-editor-hr-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "czTsDfSB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n  \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"layoutItems\"]],[25,[\"layoutValue\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/hr/lt-page-editor-hr-properties/template.hbs"
    }
  });

  _exports.default = _default;
});