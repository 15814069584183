define("lt-page-editor/components/blocks/inception/lt-page-editor-inception-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kRr2biWE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"button-row \",[23,\"componentCssClassName\"]]]],[9],[0,\"\\n  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"helper\",\"openEdit\"]]],null]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"edit\"],[10],[0,\" Inhalt Bearbeiten\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"Anordnung\"],[10],[0,\"\\n  \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"alignSelf\"]],[25,[\"_align\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"Animation\"],[10],[0,\"\\n  \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"animateItems\"]],[25,[\"_animate\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"helper\",\"showEdit\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"lt-page-editor-inception-properties-popup\",null,[[\"editor\",\"value\",\"pageEditor\",\"close\",\"save\"],[[25,[\"editor\"]],[25,[\"helper\",\"helper\",\"value\"]],[25,[\"helper\",\"pageEditor\"]],[29,\"action\",[[24,0,[]],[25,[\"helper\",\"closeEdit\"]]],null],[29,\"action\",[[24,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/inception/lt-page-editor-inception-properties/template.hbs"
    }
  });

  _exports.default = _default;
});