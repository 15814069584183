define("lt-page-editor/components/blocks/image/lt-page-editor-image-properties/component", ["exports", "lt-page-editor/components/blocks/image/lt-page-editor-image-properties/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //tagName: '',
    layout: _template.default,
    _size: Ember.computed('helper.size', {
      get: function get(k) {
        k;
        return Ember.get(this, 'helper.size') | 0;
      },
      set: function set(k, v) {
        k;
        Ember.get(this, 'helper.setSize')(v);
        return v;
      }
    }),
    _description: Ember.computed('helper.showDesc', {
      get: function get(k) {
        k;
        return Ember.get(this, 'helper.showDesc') | 0;
      },
      set: function set(k, v) {
        k;
        Ember.get(this, 'helper.setShowDesc')(v);
        return v;
      }
    }),
    descriptionItems: Ember.computed(function () {
      return [{
        id: 0,
        value: "verstecken"
      }, {
        id: 1,
        value: "anzeigen"
      }];
    }),
    sizeItems: Ember.computed('forceImageSize', function () {
      if (Ember.get(this, 'forceImageSize')) {
        return [{
          id: 1,
          value: "Teaser"
        }, {
          id: 2,
          value: "Teaser × 2"
        }];
      }

      return [{
        id: 0,
        value: "Original"
      }, {
        id: 1,
        value: "Teaser"
      }, {
        id: 2,
        value: "Teaser × 2"
      }, {
        id: 3,
        value: "1 : 1"
      }, {
        id: 4,
        value: "2 : 1"
      }, {
        id: 5,
        value: "1 : 2"
      }];
    })
  });

  _exports.default = _default;
});