define("lt-page-editor/components/blocks/pk-block/lt-page-editor-pk-block-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-block/lt-page-editor-pk-block-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    isVersion2: Ember.computed.equal('_value.version', 2),
    textSelected: Ember.computed.equal('subselected', 'text'),
    imageSelected: Ember.computed.equal('subselected', 'image'),
    _value: Ember.computed.alias('value.data'),
    linkValue: Ember.computed('value.data.link', function () {
      return Ember.get(this, 'value.data.link');
    }),
    hasImageAndText: Ember.computed('_value.withImage', '_value.withText', function () {
      return Ember.get(this, '_value.withImage') && Ember.get(this, '_value.withText');
    }),
    imageStyle: Ember.computed('hasImageAndText', '_value.layout', '_value.width', 'possibleSizes.[]', function () {
      if (!Ember.get(this, 'hasImageAndText')) {
        return;
      }

      var width = Ember.get(this, '_value.width') | 0;
      var layout = Ember.get(this, '_value.layout') || 1;

      if (layout == 1) {
        // bild + text
        var possibleSizes = Ember.get(this, 'possibleSizes');
        var w = " ".concat(possibleSizes[Math.min(width, possibleSizes.length - 1)], "px");
        return Ember.String.htmlSafe("max-width: ".concat(w, "; min-width: ").concat(w));
      }

      if (layout == 2) {
        // text + bild
        return;
      }
    }),
    fixedColumnWidth: Ember.computed('textStyle', 'value.width', function () {
      var width = Ember.get(this, 'value.width');
      var stext = this.element.querySelector('.text');

      if (!stext) {
        return "128px";
      }

      stext.style.overflow = 'hidden';
      var cccs = window.getComputedStyle(stext);
      var w = cccs.width;
      stext.style.overflow = '';
      return "calc(".concat(w, " / ").concat(width, ")");
    }),
    textStyle: Ember.computed('hasImageAndText', '_value.layout', '_value.width', 'possibleSizes.[]', function () {
      if (!Ember.get(this, 'hasImageAndText')) {
        return;
      }

      var width = Ember.get(this, '_value.width') | 0;
      var layout = Ember.get(this, '_value.layout') || 1;

      if (layout == 1) {
        // bild + text
        return;
      }

      if (layout == 2) {
        // text + bild
        var possibleSizes = Ember.get(this, 'possibleSizes');
        var w = " ".concat(possibleSizes[Math.min(width, possibleSizes.length - 1)], "px");
        return Ember.String.htmlSafe("max-width: ".concat(w, "; min-width: ").concat(w));
      }
    }),
    possibleSizes: Ember.computed('value.width', function () {
      var possibleSizes = [];
      var w = Ember.get(this, 'value.width');
      var r = 0.381966011; // golden ratio

      var cccs = window.getComputedStyle(this.element);
      var pl = parseFloat(cccs.paddingLeft);
      var pr = parseFloat(cccs.paddingRight);
      var pw = pl + parseFloat(cccs.width) + pr; // size of full element

      var pw1 = pw / w;

      for (var i = 0; i < w; ++i) {
        if (i != 0) {
          possibleSizes.push(pw1 * i - pr);
        }

        possibleSizes.push(pw1 * i + pw1 * r);
        possibleSizes.push(pw1 * i + pw1 - pw1 * r);
      }

      return possibleSizes;
    }),
    actions: {
      onclick: function onclick(e) {
        var el = e.target;

        while (el != this.element && (!el.dataset || !('component' in el.dataset))) {
          el = el.parentElement;
        }

        Ember.set(this, 'subselected', el == this.element ? null : el.dataset.component);
      }
    }
  });

  _exports.default = _default;
});