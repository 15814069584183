define("ui-page-editor-list/components/ui-page-editor-list-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q83syK3U",
    "block": "{\"symbols\":[\"i\",\"&default\"],\"statements\":[[4,\"each\",[[25,[\"model\"]]],[[\"key\"],[\"index\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"data-index\",[24,1,[\"index\"]]],[12,\"class\",[30,[\"line depth-\",[24,1,[\"original\",\"depth\"]],\" list-ol-nr-\",[24,1,[\"olNr\"]]]]],[9],[0,\"\\n    \"],[1,[24,1,[\"original\",\"value\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[15,2,[[25,[\"helper\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor-list/components/ui-page-editor-list-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});