define("ui-input/utils/sendAction", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function sendAction(self, name) {
    var fn = Ember.get(self, name);
    if (!fn) {
      return;
    }
    if (fn instanceof Function) {
      for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return fn.apply(undefined, args);
    }
    console.warn("sendAction: ", "The following is not a function", name, "=>", fn);
    return;
  }

  exports.default = sendAction;
});