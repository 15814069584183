define("lt-page-editor/components/blocks/teaser/lt-page-editor-teaser-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s65y/B0h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"helper\",\"properties\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"helper\",\"properties\"]]],[[\"hideSizes\",\"editor\",\"helper\",\"value\"],[true,[25,[\"editor\"]],[25,[\"helper\",\"helper\"]],[25,[\"helper\",\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[\"button-row \",[23,\"componentCssClassName\"]]]],[9],[0,\"\\n    \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"positionItems\"]],[25,[\"position\"]]]]],false],[0,\"\\n    \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"colorItems\"]],[25,[\"color\"]]]]],false],[0,\"\\n    \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"typeItems\"]],[25,[\"type\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[1,[29,\"lt-page-editor-text-link-button\",null,[[\"hasLink\",\"linkData\",\"editor\",\"resolver\",\"saveLink\",\"removeLink\",\"uploader\",\"onInternalPage\"],[[29,\"if\",[[25,[\"linkData\"]],true,false],null],[25,[\"linkData\"]],[25,[\"editor\"]],[25,[\"resolver\"]],[29,\"action\",[[24,0,[]],\"saveLink\"],null],[29,\"action\",[[24,0,[]],\"removeLink\"],null],[25,[\"uploader\"]],[25,[\"onInternalPage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/teaser/lt-page-editor-teaser-properties/template.hbs"
    }
  });

  _exports.default = _default;
});