define("lt-page-editor/components/blocks/pk-teaser-image/lt-page-editor-pk-teaser-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QH3DDc+2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"helper\"],[[25,[\"helper\"]]]]]]],[0,\"\\n\"],[1,[29,\"lt-page-editor-pk-teaser-image-readonly\",null,[[\"model\",\"image\"],[[25,[\"model\"]],[25,[\"imageReadonly\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-teaser-image/lt-page-editor-pk-teaser-image/template.hbs"
    }
  });

  _exports.default = _default;
});