define("lt-page-editor/components/blocks/teaser/lt-page-editor-teaser/component", ["exports", "lt-page-editor/components/blocks/teaser/lt-page-editor-teaser/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['_position', '_color', '_type'],
    _position: Ember.computed('value.position', function () {
      return "position-".concat(Ember.get(this, 'value.position') || 1);
    }),
    _color: Ember.computed('value.color', function () {
      return "color-".concat(Ember.get(this, 'value.color') || 1);
    }),
    _type: Ember.computed('value.type', function () {
      return "type-".concat(Ember.get(this, 'value.type') || 1);
    }),
    hasText3: Ember.computed.equal('value.type', 3),
    helper: Ember.computed('value.data.link', function () {
      var helper = {
        position: Ember.get(this, 'value.position'),
        color: Ember.get(this, 'value.color'),
        type: Ember.get(this, 'value.type'),
        update: this._update.bind(this),
        link: Ember.get(this, 'value.data.link'),
        setLink: this.setLink.bind(this)
      };
      return helper;
    }),
    setLink: function setLink(l) {
      Ember.set(this, 'value.data.link', l);
    },
    _update: function _update(position, color, type) {
      Ember.set(this, 'value.position', position);
      Ember.set(this, 'value.color', color);
      Ember.set(this, 'value.type', type);
    },
    _updateSizesObserver: Ember.observer('value.data.text1', 'value.data.text2', 'value.data.text3', 'value.type', 'value.position', function () {
      this._updateSizes();

      Ember.run.scheduleOnce('afterRender', this._updateSizes);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._updateSizes();

      Ember.run.scheduleOnce('afterRender', this._updateSizes);
    },
    didRender: function didRender() {
      this._updateSizes();
    },
    _updateSizes: function _updateSizes() {
      if (this.isDestroyed || !this.element) {
        return;
      }

      var text = this.element.querySelector('.text');
      var scaler = this.element.querySelector('.scaler');
      var text1 = this.element.querySelector('.text-1');
      var text2 = this.element.querySelector('.text-2');
      var text3 = this.element.querySelector('.text-3');

      if (!text || !scaler) {
        return;
      }

      if (text1) {
        text1.style.whiteSpace = '';
      }

      if (text2) {
        text2.style.whiteSpace = '';
      }

      if (text3) {
        text3.style.fontSize = '';
        text3.style.display = 'none';
      }

      scaler.style.transform = ''; //scaler.style.width = '';
      //scaler.style.height = '';

      text.style.overflow = 'hidden';
      scaler.style.fontSize = '1em';
      var textBB = text.getBoundingClientRect();
      var style = getComputedStyle(text);
      var textBBwidth = textBB.width - parseInt(style.paddingLeft) - parseInt(style.paddingRight);
      var textBBheight = textBB.height - parseInt(style.paddingTop) - parseInt(style.paddingBottom);
      scaler.style.position = 'absolute'; //stupid

      var scalerBB = scaler.getBoundingClientRect();
      scaler.style.position = '';
      text.style.overflow = '';
      var sX = textBBwidth / scalerBB.width;
      var sY = textBBheight / scalerBB.height;
      var s = Math.min(sX, sY);

      if (s < 0.375) {
        scaler.style.fontSize = '0.375em';

        if (text1) {
          text1.style.whiteSpace = 'pre-wrap';
        }

        if (text2) {
          text2.style.whiteSpace = 'pre-wrap';
        }

        if (text3) {
          text3.style.fontSize = "calc(1em / 0.375)";
        }
      } else if (s < 1) {
        scaler.style.fontSize = "".concat(s, "em");

        if (text3) {
          text3.style.fontSize = "calc(1em / ".concat(s, ")");
        }

        scaler.style.position = 'absolute'; //stupid

        var scalerBB2 = scaler.getBoundingClientRect();
        scaler.style.position = '';
        var sX2 = textBBwidth / scalerBB2.width;
        var sY2 = textBBheight / scalerBB2.height;
        var s2 = Math.min(sX2, sY2);

        if (s2 < 1) {
          scaler.style.transform = "scale(".concat(s2, ")");
        }
      }

      if (text3) {
        text3.style.display = '';
      }
    },
    _value: Ember.computed('value.data', function () {
      var value = Ember.get(this, 'value');

      if (!value.data || !value.data.image) {
        Ember.set(value, 'data', Object.assign(value.data || {}, {
          text1: 'Text 1',
          text2: 'Text 2',
          text3: {
            data: [null, 'Text 3']
          },
          image: {
            data: []
          },
          link: null
        }));
      }

      return value.data;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._updateSizes = this._updateSizes.bind(this);
    }
  });

  _exports.default = _default;
});