define("lt-page-editor/components/blocks/teaser/lt-page-editor-teaser-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Beq5F5zo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"lt-link\",null,[[\"resolver\",\"transition\",\"link\",\"class\"],[[25,[\"resolver\"]],[25,[\"transition\"]],[25,[\"linkValue\"]],\"block\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"image\"],[11,\"data-component\",\"image\"],[9],[0,\"\\n    \"],[1,[29,\"component\",[[25,[\"image\"]]],[[\"value\"],[[25,[\"_value\",\"image\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-wrapper\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"scaler\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"text-ani\"],[9],[0,\"\\n          \"],[7,\"h1\"],[11,\"class\",\"text-1\"],[9],[1,[29,\"unbound\",[[25,[\"_value\",\"text1\"]]],null],false],[10],[0,\"\\n          \"],[7,\"p\"],[11,\"class\",\"text-2\"],[9],[1,[29,\"unbound\",[[25,[\"_value\",\"text2\"]]],null],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"hasText3\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"component\",[[25,[\"text\"]]],[[\"class\",\"editor\",\"value\"],[\"text-3\",[25,[\"editor\"]],[29,\"unbound\",[[25,[\"_value\",\"text3\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"copyright-wrap-teaser\"],[9],[0,\"\\n    Loading...\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/teaser/lt-page-editor-teaser-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});