define("ui-input/components/ui-input-select-autocomplete", ["exports", "ui-input/templates/components/ui-input-select-autocomplete", "ui-input/utils/sendAction"], function (exports, _uiInputSelectAutocomplete, _sendAction) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function findParentItem(el) {
    while (el && (!el.dataset || !('isItem' in el.dataset))) {
      el = el.parentElement;
    }
    return el;
  }

  function compare(a, b) {
    function prepare(x) {
      if (x !== null && (typeof x === "undefined" ? "undefined" : _typeof(x)) === 'object') {
        // order properties
        var tmp = {};
        var keys = [].concat(_toConsumableArray(Object.keys(x))).sort(function (a, b) {
          if (a == b) {
            return 0;
          }
          return a < b ? -1 : 1;
        });
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = keys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var o = _step.value;

            tmp[o] = x[o];
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return JSON.stringify(tmp);
      }
      return JSON.stringify({ value: x });
    }

    return prepare(a) === prepare(b);
  }

  exports.default = Ember.Component.extend({
    tagName: 'ui-input-select-autocomplete',
    layout: _uiInputSelectAutocomplete.default,
    autocomplete: true,
    _selectedId: null,

    _value: Ember.computed('value', 'text', {
      get: function get(k) {
        k;
        var id = this.get('value');
        var item = (this.get('items') || []).find(function (x) {
          return compare(x.id, id);
        });
        if (!item) {
          if (id === null) {
            return null;
          }
          return this.get('text');
        }
        return item.value;
      },
      set: function set(k, v) {
        k;
        (0, _sendAction.default)(this, 'textChanged', v);
        return v;
      }
    }),

    obsVal: Ember.observer('value', function () {
      // remove selectedId !
      this.set('_selectedId', null);
      if (this.get('value') === null) {
        this.set('text', null);
      }
    }),

    _items: Ember.computed('items', '_selectedId', function () {
      var _selectedId = this.get('_selectedId');
      return (this.get('items') || []).map(function (x, i) {
        return {
          selected: i === _selectedId,
          item: x
        };
      });
    }),

    obsItems: Ember.observer('items', function () {
      if (this.get('_selectedId') === null) {
        return null;
      }
      this.set('_selectedId', Math.min((this.get('items.length') || 1) - 1, this.get('_selectedId')));
    }),

    _setProperties2: function _setProperties2() {
      var v = this._lastSetProperties;
      this._lastSetProperties = null;
      var tv = v.value;
      if (tv !== undefined) {
        delete v.value;
      }
      this.setProperties(v);
      if (tv !== undefined) {
        this.setProperties({ value: tv });
      }
    },
    setProperties2: function setProperties2(hash) {
      this._lastSetProperties = Object.assign(this._lastSetProperties || {}, hash);
      Ember.run.once(this._setProperties2);
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._focusRC = 0;
      this._updateFocus = this._updateFocus.bind(this);
      this._focus = this._focus.bind(this);
      this._blur = this._blur.bind(this);

      this._keydown = this._keydown.bind(this);
      this._click = this._click.bind(this);

      this._setProperties2 = this._setProperties2.bind(this);
      this.setProperties2 = this.setProperties2.bind(this);
    },
    willInsertElement: function willInsertElement() {
      this.element.addEventListener('focus', this._focus, true);
      this.element.addEventListener('blur', this._blur, true);

      this.element.addEventListener('keydown', this._keydown, true);
      this.element.addEventListener('click', this._click, true);
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('focus', this._focus, true);
      this.element.removeEventListener('blur', this._blur, true);

      this.element.removeEventListener('keydown', this._keydown, true);
      this.element.removeEventListener('click', this._click, true);
    },
    _mousedown: function _mousedown(e) {
      e.preventDefault();
    },
    _keydown: function _keydown(e) {
      if (!this.element) {
        return;
      }

      var code = e.which || e.keyCode;

      var isUp = code === 38;
      var isDown = code === 40;
      var isEnter = code === 13;

      var _selectedId = this.get('_selectedId');
      if (isUp) {
        if (_selectedId === null || _selectedId === undefined) {
          _selectedId = this.get('items.length') || 1;
        }
        _selectedId = Math.max(0, _selectedId - 1);
        e.preventDefault();
      } else if (isDown) {
        if (_selectedId === null || _selectedId === undefined) {
          _selectedId = -1;
        }
        _selectedId = Math.min((this.get('items.length') || 1) - 1, _selectedId + 1);
        e.preventDefault();
      }
      if (_selectedId !== this.get('_selectedId')) {
        this.set('_selectedId', _selectedId);
      }

      if (isEnter) {
        // select the option !
        this._selectItem(this.get('_selectedId'), true);
      }
    },
    _click: function _click(e) {
      var el = findParentItem(e.target);

      if (el && 'isItem' in el.dataset) {
        e.preventDefault();
        // select the option !
        this._selectItem(parseInt(el.dataset.value), false);

        return;
      }

      Ember.run.once(this._updateFocus);
    },
    _selectItem: function _selectItem(index, focus_next) {
      this.set('_selectedId', index);
      var item = (this.get('items') || [])[index] || {};
      this.setProperties2({
        value: item.id,
        _value: item.value,
        text: item.value
      });
      this._backupValue = item.id;
      this._backupText = item.value;
      if (!focus_next) {
        if (this.element) {
          this.element.classList.remove('focus');
        }
        return;
      }
      // get next focusable node
      try {
        var treeWalker = document.createTreeWalker(document.body, NodeFilter.SHOW_ELEMENT, { acceptNode: function acceptNode(node) {
            return node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
          } }, false);
        treeWalker.currentNode = this.element;
        treeWalker.nextNode();
        var nextFocusableNode = treeWalker.nextNode();
        nextFocusableNode.focus();
      } catch (e) {
        // ignore errors
        console.warn("Error", e);
      }
    },
    _focus: function _focus() {
      this._focusRC += 1;
      Ember.run.once(this._updateFocus);
    },
    _blur: function _blur() {
      this._focusRC -= 1;
      Ember.run.once(this._updateFocus);
    },
    _updateFocus: function _updateFocus() {
      var _this = this;

      if (!this.element) {
        return;
      }

      if (this.get('disabled')) {
        return;
      }

      if (this._focusRC > 0) {
        if (!this.element.classList.contains('focus')) {
          this.element.classList.add('focus');
          this._backupValue = this.get('value');
          this._backupText = this.get('text');
          this.set('_selectedId', (this.get('items') || []).findIndex(function (x) {
            return compare(x.id, _this._backupValue);
          }));
        }
      } else {
        var hasFocus = this.element.classList.contains('focus');
        if (!hasFocus) {
          return;
        }
        this.element.classList.remove('focus');
        // set value
        var _selectedId = this.get('_selectedId');
        var item = (this.get('items') || [])[_selectedId];
        if (item) {
          this.setProperties2({
            value: item.id,
            _value: item.value,
            text: item.value
          });
        } else {
          if (this.get('text')) {
            // restore text
            this.setProperties2({
              value: this._backupValue,
              _value: this._backupText,
              text: this._backupText
            });
          } else {
            this.setProperties2({
              value: null,
              _value: "",
              text: ""
            });
          }
        }
        this.notifyPropertyChange('_value');
      }
    }
  });
});