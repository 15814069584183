define("lt-page-editor/components/blocks/teaser/lt-page-editor-teaser-properties/component", ["exports", "lt-page-editor/components/blocks/teaser/lt-page-editor-teaser-properties/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function computedDefault(key, def) {
    return Ember.computed(key, {
      get: function get(k) {
        k;
        return Ember.get(this, key) || def;
      },
      set: function set(k, v) {
        Ember.set(this, key, v);
        return v;
      }
    });
  }

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    positionItems: [{
      id: 4,
      value: "Oben/Mitte"
    }, {
      id: 5,
      value: "Oben/Links"
    }, {
      id: 6,
      value: "Oben/Rechts"
    }, {
      id: 1,
      value: "Mitte/Mitte"
    }, {
      id: 2,
      value: "Mitte/Links"
    }, {
      id: 3,
      value: "Mitte/Rechts"
    }, {
      id: 7,
      value: "Unten/Mitte"
    }, {
      id: 8,
      value: "Unten/Links"
    }, {
      id: 9,
      value: "Unten/Rechts"
    }, {
      id: 10,
      value: "Links"
    }, {
      id: 11,
      value: "Rechts"
    }],
    colorItems: [{
      id: 1,
      value: "Weiß"
    }, {
      id: 2,
      value: "Schwarz"
    }, {
      id: 3,
      value: "Weiß auf Schwarz"
    }, {
      id: 4,
      value: "Schwarz auf Weiß"
    }],
    typeItems: [{
      id: 2,
      value: "Titel"
    }, {
      id: 1,
      value: "Titel/Untertitel"
    }, {
      id: 3,
      value: "Titel/Untertitel/Text"
    }],
    position: computedDefault('helper.position', 1),
    color: computedDefault('helper.color', 1),
    type: computedDefault('helper.type', 1),
    linkData: Ember.computed('helper.link', {
      get: function get() {
        return Ember.get(this, 'helper.link');
      },
      set: function set(k, v) {
        Ember.get(this, 'helper.setLink')(v);
      }
    }),
    updateHelper: Ember.observer('position', 'color', 'type', function () {
      var position = Ember.get(this, 'position');
      var color = Ember.get(this, 'color');
      var type = Ember.get(this, 'type');
      Ember.get(this, 'helper.update')(position, color, type);
    }),
    actions: {
      saveLink: function saveLink(value) {
        Ember.set(this, 'linkData', value);
      },
      removeLink: function removeLink() {
        Ember.set(this, 'linkData', null);
      }
    }
  });

  _exports.default = _default;
});