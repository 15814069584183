define("lt-page-editor/components/blocks/pk-open-small/lt-page-editor-pk-open-small-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2nHmagi7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[4,\"if\",[[25,[\"lokal\",\"from1\"]]],null,{\"statements\":[[0,\"  \"],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"Öffnungszeiten\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"lokal\",\"from1\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[1,[25,[\"lokal\",\"tag\"]],false],[0,\". \"],[1,[25,[\"lokal\",\"from1\"]],false],[0,\" - \"],[1,[25,[\"lokal\",\"to1\"]],false],[4,\"if\",[[25,[\"lokal\",\"dual\"]]],null,{\"statements\":[[0,\" und \"],[1,[25,[\"lokal\",\"from2\"]],false],[0,\" - \"],[1,[25,[\"lokal\",\"to2\"]],false]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\"],[9],[4,\"if\",[[25,[\"lokal\",\"open\"]]],null,{\"statements\":[[0,\"derzeit geöffnet\"]],\"parameters\":[]},{\"statements\":[[0,\"derzeit geschlossen\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"kitchen\"]]],null,{\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[4,\"if\",[[25,[\"kitchen\",\"from1\"]]],null,{\"statements\":[[0,\"  \"],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"Küche\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"kitchen\",\"from1\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[1,[25,[\"kitchen\",\"tag\"]],false],[0,\". \"],[1,[25,[\"kitchen\",\"from1\"]],false],[0,\" - \"],[1,[25,[\"kitchen\",\"to1\"]],false],[4,\"if\",[[25,[\"kitchen\",\"dual\"]]],null,{\"statements\":[[0,\" und \"],[1,[25,[\"kitchen\",\"from2\"]],false],[0,\" - \"],[1,[25,[\"kitchen\",\"to2\"]],false]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\"],[9],[4,\"if\",[[25,[\"kitchen\",\"open\"]]],null,{\"statements\":[[0,\"derzeit geöffnet\"]],\"parameters\":[]},{\"statements\":[[0,\"derzeit geschlossen\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-open-small/lt-page-editor-pk-open-small-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});