define("ui-page-editor/components/ui-page-editor-wysiwyg-row/component", ["exports", "ui-page-editor/components/ui-page-editor-wysiwyg-row/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var editors = new WeakMap();

  exports.default = Ember.Component.extend({
    tagName: 'ui-wysiwyg-row',
    layout: _template.default,
    attributeBindings: ['index:data-index', 'model.selectedInRow:data-selected-in-row', 'model.selectedInRowBefore:date-selected-in-row-before', 'model.hasSelected:data-has-selected', 'model.beforeSelected:data-before-selected'],
    classNameBindings: ['selected'],
    index: Ember.computed.alias('model.index'),
    selected: Ember.computed.alias('model.selected'),

    init: function init() {
      this._super.apply(this, arguments);
      this._updateDndMimetype();
    },

    obsEditor: Ember.observer('editor', function () {
      this._updateDndMimetype();
    }),
    _updateDndMimetype: function _updateDndMimetype() {
      var editor = this.get('editor');
      if (!editor) {
        this._dndMimetype = null;
        return;
      }
      if (editors.has(editor)) {
        this._dndMimetype = editors.get(editor);
      } else {
        var rnd = window.btoa("" + Math.random());
        this._dndMimetype = ("custom/ui-forms-wysiwyg-row-" + rnd).replace(/=/g, '').toLowerCase();
        editors.set(editor, this._dndMimetype);
      }
    },


    actions: {
      startDrag: function startDrag(e) {
        e.dataTransfer.setData(this._dndMimetype, this.get('index'));
        e.dataTransfer.effectAllowed = 'move';
        var element = e.currentElement;
        element.classList.add('will-drag');
        try {
          e.dataTransfer.setDragImage(element, 0, 0);
        } catch (_) {
          /* IGNORE STUID EDGE */
        }
        requestAnimationFrame(function () {
          element.classList.add('dragging');
          element.classList.remove('will-drag');
          document.body.classList.add('activate-dropzones-level-1');
        });
      },
      endDrag: function endDrag(e) {
        e;
        document.body.classList.remove('activate-dropzones-level-1');
        if (!this.element) {
          return;
        }
        this.element.classList.remove('dragging');
      },
      dragEnter: function dragEnter(e) {
        var _this = this;

        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this._dndMimetype;
        })) {
          return;
        }
        e.preventDefault();
        e.target.classList.add('highlight');
      },
      dragOver: function dragOver(e) {
        var _this2 = this;

        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this2._dndMimetype;
        })) {
          return;
        }
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      },
      dragLeave: function dragLeave(e) {
        var _this3 = this;

        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this3._dndMimetype;
        })) {
          return;
        }
        e.preventDefault();
        e.target.classList.remove('highlight');
      },
      dropBefore: function dropBefore(e) {
        var _this4 = this;

        e.preventDefault();
        e.target.classList.remove('highlight');
        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this4._dndMimetype;
        })) {
          return;
        }
        var index = e.dataTransfer.getData(this._dndMimetype);
        this.get('editor').send('moveRow', 0, index, this.get('index'));
      },
      drop: function drop(e) {
        var _this5 = this;

        e.preventDefault();
        e.target.classList.remove('highlight');
        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this5._dndMimetype;
        })) {
          return;
        }
        var index = e.dataTransfer.getData(this._dndMimetype);
        this.get('editor').send('moveRow', 1, index, this.get('index'));
      },
      dropAfter: function dropAfter(e) {
        var _this6 = this;

        e.preventDefault();
        e.target.classList.remove('highlight');
        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this6._dndMimetype;
        })) {
          return;
        }
        var index = e.dataTransfer.getData(this._dndMimetype);
        this.get('editor').send('moveRow', 2, index, this.get('index'));
      }
    }
  });
});