define('ui-input/components/ui-input-date', ['exports', 'ui-input/components/ui-textarea', 'ui-input/utils/maskedFormat', 'ui-input/utils/maskedFormatContent', 'ui-input/utils/immediate', 'ui-input/mixins/masked-tabbing'], function (exports, _uiTextarea, _maskedFormat, _maskedFormatContent3, _immediate, _maskedTabbing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _uiTextarea.default.extend(_maskedTabbing.default, {
    tagName: 'ui-input',
    multiline: false,
    init: function init() {
      this._super();
      this._dontUpdateCursor = false;
    },
    _format: function _format(input) {
      var _this = this;

      input = input || '';
      var shift = input.replace(/\s/g, ' ').replace(/[\u2012]/g, '-').replace(/[^0-9.\- ]/g, '').length - input.length;

      var _maskedFormatContent = (0, _maskedFormatContent3.default)({
        input: input,
        mask: '##.##.####',
        removePlaceholder: /[-]/g,
        cursorOffset: this._cursorOffset(),
        preprocess: function preprocess(value) {
          value = value.replace(/\s/g, ' '); // whitespace to normal space
          value = value.replace(/[\u2012]/g, '-'); // figure dash to normal dash
          return value;
        },
        postprocess: function postprocess(value, size) {
          value = (0, _maskedFormat.rightPad)(value, size);
          value = value.replace(/[^0-9]/g, ' '); // remove non numbers
          value = value.replace(/\s/g, '\u2007'); // replace whitespace with figure whitespace
          value = (0, _maskedFormat.placeholder)(value);
          value = value.replace(/-/g, '\u2012'); // replace dash with figure dash
          return value;
        },

        isPlaceholder: function isPlaceholder(value, size) {
          value = (0, _maskedFormat.rightPad)(value.trimLeft(), size);
          value = value.replace(/[^0-9]/g, ' '); // remove non numbers
          return (0, _maskedFormat.isPlaceholder)(value);
        }
      }),
          _maskedFormatContent2 = _slicedToArray(_maskedFormatContent, 2),
          cursor = _maskedFormatContent2[0],
          content = _maskedFormatContent2[1];

      if (cursor && !this._dontUpdateCursor) {
        // needs to be done after ... this "_format" !
        (0, _immediate.default)(function () {
          try {
            if (_this.element && document.activeElement === _this.element) {
              cursor[0] += shift;
              cursor[1] += shift;
              _this._setCursorOffset(cursor);
            }
          } catch (e) {
            // ignore
          }
        });
      }

      (0, _immediate.default)(function () {
        if (_this.element) {
          _this.element.classList.remove('empty');
          if (content[0].class && content[0].class.includes('placeholder') && content[2].class && content[2].class.includes('placeholder') && content[4].class && content[4].class.includes('placeholder')) {
            _this.element.classList.add('empty');
          }
        }
      });

      return content;
    },

    _value: Ember.computed('value', {
      get: function get(k) {
        k;
        return (Ember.get(this, 'value') || "").split('-').reverse().join('.');
      },
      set: function set(k, v) {
        Ember.set(this, 'value', (v || "").split('.').reverse().join('-'));
        return v;
      }
    }),
    content: Ember.computed('_value', {
      set: function set(k, v) {
        try {
          this._ignoreValueChange = true;
          var input = v.length > 1 ? (v.find(function (x) {
            return x.class.includes('text-0') || x.class.includes('placeholder-0');
          }) || { value: '' }).value + '.' + (v.find(function (x) {
            return x.class.includes('static-0');
          }) || { value: '' }).value.replace(/\./g, '') + (v.find(function (x) {
            return x.class.includes('text-1') || x.class.includes('placeholder-1');
          }) || { value: '' }).value + '.' + (v.find(function (x) {
            return x.class.includes('static-1');
          }) || { value: '' }).value.replace(/\./g, '') + (v.find(function (x) {
            return x.class.includes('text-2') || x.class.includes('placeholder-2');
          }) || { value: '' }).value : v.reduce(function (p, x) {
            return p + x.value;
          }, '');
          var formated = this._format(input);
          var value = formated.reduce(function (x, _ref) {
            var value = _ref.value;
            return x + value;
          }, '').replace(/ /g, '');
          var values = value.split('.');

          if (isNaN(parseInt(values[0])) || isNaN(parseInt(values[1])) || isNaN(parseInt(values[2]))) {
            if (this.get('_value') !== null) {
              this.set('_value', null);
            }
          } else if (this.get('_value') !== value) {
            this.set('_value', value);
          }
          return formated;
        } finally {
          this._ignoreValueChange = false;
        }
      },
      get: function get(k) {
        k;
        var value = this.get('_value');
        return this._format(value);
      }
    }),
    _change: function _change() {
      var content = [].concat(_toConsumableArray(this.get('content')));

      if (content.some(function (x, i) {
        return i % 2 === 0 && x.value.replace(/[\s|-|\u2012]/g, '').length;
      })) {
        // replace spaces with zeros
        Ember.set(content[0], 'value', ((0, _maskedFormat.leftPad)(content[0].value.trimRight(), 2).replace(/[\s|-|\u2012]/g, '0') + '00').substr(0, 2));
        Ember.set(content[2], 'value', ((0, _maskedFormat.leftPad)(content[2].value.trimRight(), 2).replace(/[\s|-|\u2012]/g, '0') + '00').substr(0, 2));

        var l = content[4].value.trimRight().length;
        if (l === 1) {
          Ember.set(content[4], 'value', ((0, _maskedFormat.leftPad)('200' + content[4].value.trimRight(), 4).replace(/[\s|-|\u2012]/g, '0') + '0000').substr(0, 4));
        } else if (l === 2) {
          Ember.set(content[4], 'value', ((0, _maskedFormat.leftPad)('20' + content[4].value.trimRight(), 4).replace(/[\s|-|\u2012]/g, '0') + '0000').substr(0, 4));
        } else {
          Ember.set(content[4], 'value', ((0, _maskedFormat.leftPad)(content[4].value.trimRight(), 4).replace(/[\s|-|\u2012]/g, '0') + '0000').substr(0, 4));
        }

        if (content[0].value === '00') {
          Ember.set(content[0], 'value', (0, _maskedFormat.leftPad)('' + new Date().getDate(), 2).replace(/[\s]/g, '0'));
        }
        if (content[2].value === '00') {
          Ember.set(content[2], 'value', (0, _maskedFormat.leftPad)('' + (new Date().getMonth() + 1), 2).replace(/[\s]/g, '0'));
        }
        if (content[4].value === '0000') {
          Ember.set(content[4], 'value', (0, _maskedFormat.leftPad)('' + new Date().getFullYear(), 4).replace(/[\s]/g, '0'));
        }

        this._dontUpdateCursor = true;
        this.set('content', content);
        this._dontUpdateCursor = false;
      }
      this._super.apply(this, arguments);
    }
  });
});