define("lt-page-editor/components/blocks/db-content/lt-page-editor-db-content-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WmsJll3l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"dbItems\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"dbItems\"]],[25,[\"dbValue\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/db-content/lt-page-editor-db-content-properties/template.hbs"
    }
  });

  _exports.default = _default;
});