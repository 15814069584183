define("lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+OxdpMks",
    "block": "{\"symbols\":[\"helper2\",\"&default\"],\"statements\":[[15,2,[[29,\"hash\",null,[[\"helper\"],[[25,[\"helper\"]]]]]]],[0,\"\\n\"],[4,\"if\",[[25,[\"fromDB\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"lt-page-editor-pk-teaser-readonly\",null,[[\"model\",\"resolver\"],[[25,[\"model\"]],[25,[\"resolver\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n    \"],[1,[29,\"ui-textarea\",null,[[\"class\",\"value\"],[\"name-before\",[25,[\"_value\",\"nameBefore\"]]]]],false],[0,\"\\n    \"],[1,[29,\"ui-textarea\",null,[[\"class\",\"value\"],[\"name\",[25,[\"_value\",\"name\"]]]]],false],[0,\"\\n    \"],[1,[29,\"ui-textarea\",null,[[\"class\",\"value\"],[\"name-after\",[25,[\"_value\",\"nameAfter\"]]]]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n\"],[4,\"component\",[[25,[\"inception\",\"component\"]]],[[\"selected\",\"columns\",\"columnWidth\",\"withoutPk\",\"editor\",\"value\"],[[25,[\"selected\"]],1,[25,[\"textWidth\"]],true,[25,[\"editor\"]],[25,[\"_value\",\"inception\"]]]],{\"statements\":[[0,\"          \"],[15,2,[[29,\"hash\",null,[[\"properties\",\"value\",\"helper\"],[[25,[\"inception\",\"properties\"]],[25,[\"_value\",\"inception\"]],[24,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser/template.hbs"
    }
  });

  _exports.default = _default;
});