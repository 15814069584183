define('ui-input/utils/maskedFormat', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.leftPad = leftPad;
  exports.rightPad = rightPad;
  exports.isPlaceholder = isPlaceholder;
  exports.placeholder = placeholder;
  var whitespacePad = '                ';
  var dashPad = '----------------';

  function leftPad(n, pad) {
    if (!pad) {
      pad = 2;
    }
    if (n === null) {
      return whitespacePad.substr(-pad);
    }
    return ('' + whitespacePad + n.substr(0, pad)).substr(-pad);
  }

  function rightPad(n, pad) {
    if (!pad) {
      pad = 2;
    }
    if (n === null) {
      return whitespacePad.substr(-pad);
    }
    return ('' + n.substr(0, pad) + whitespacePad).substr(0, pad);
  }

  function isPlaceholder(n) {
    return !n.trim().length;
  }

  function placeholder(n) {
    if (isPlaceholder(n)) {
      return dashPad.substr(-n.length);
    }
    return n;
  }

  function split(input, seperator, limit, rpad) {
    var index = input.indexOf(seperator);
    if (index < 0) {
      if (rpad) {
        return [rPad(input, rpad, true)];
      }
      return [input];
      // not so simple
      return ['', input];
    }
    if (rpad) {
      return [rPad(input.substr(0, index), rpad, true), input.substr(index + seperator.length)];
    } else {
      return [input.substr(0, index), input.substr(index + seperator.length)];
    }
  }

  function getCursor(l) {
    var c0 = l.indexOf('\uF8D0');
    var c1 = l.indexOf('\uF8D1');

    var c2 = l.indexOf('\uF8D2');
    var c3 = l.indexOf('\uF8D3');
    var c4 = l.indexOf('\uF8D4');
    var c5 = l.indexOf('\uF8D5');

    var c = [null, null];
    if (c2 >= 0) {
      c[0] = c2;
    }
    if (c3 >= 0) {
      c[0] = c3 + 1;
    }
    if (c4 >= 0) {
      c[1] = c4;
    }
    if (c5 >= 0) {
      c[1] = c5 + 1;
    }
    if (c0 >= 0) {
      c[0] = c0;
      c[1] = c0;
    }
    if (c1 >= 0) {
      c[0] = c1 + 1;
      c[1] = c1 + 1;
    }
    if (c[0] === null || c[1] === null) {
      return null;
    }
    return c;
  }

  function splitCursor(input, fakeInput, seperator, limit, rpad) {
    var index = fakeInput.indexOf(seperator);
    if (index < 0) {
      if (rpad) {
        return [rPadCursor(input, rpad, true)];
      }
      return [input];
      // not so simple..
      return ['', input];
    }
    var o1 = '';
    for (var i = 0; i < index; ++i) {
      o1 += input[i];
    }
    for (var _i = index; _i < rpad; ++_i) {
      if (/[\uF8D0-\uF8D5]/.test(input[_i])) {
        o1 += input[_i];
      } else {
        o1 += ' ';
      }
    }
    var o2 = '';
    if (index != rpad - 1 && /[\uF8D0-\uF8D5]/.test(input[index])) {
      var cc = input[index].charCodeAt(0);
      o2 += cc % 2 !== 0 ? String.fromCharCode(cc - 1) : String.fromCharCode(cc);
    }
    for (var _i2 = index + seperator.length + o2.length; _i2 < input.length; ++_i2) {
      if (_i2 < rpad) {
        o2 += ' ';
      } else {
        o2 += input[_i2];
      }
    }

    return [o1, o2];
  }

  function rPad(n, s, special) {
    if (special) {
      return rPad(n, Math.max(s, n.length));
    }
    return (n + '             ').substr(0, s);
  }

  function rPadCursor(n, s, special) {
    if (special) {
      return rPadCursor(n, Math.max(s, n.length));
    }
    var l = n + '             ';
    var c = getCursor(n);
    if (c) {
      if (c[0] == c[1] && c[0] >= s) {
        return l.substr(0, s - 1) + '\uF8D1';
      }
      if (c[0] >= s) {
        return l.substr(0, s - 1) + '\uF8D3';
      }
      if (c[1] >= s) {
        return l.substr(0, s - 1) + '\uF8D4';
      }
    }

    return l.substr(0, s);
  }

  /* format: ##.##.#### */
  function maskedFormat(format, placeholderRegex, input, cursorOffset) {
    var formatSize = [0];
    var formatSep = [];
    var formatIndex = 0;
    for (var i = 0; i < format.length; ++i) {
      if (format[i] !== '#') {
        formatSep[formatIndex] = (formatSep[formatIndex] || '') + format[i];
      } else {
        if (formatSep[formatIndex]) {
          formatIndex += 1;
          formatSize[formatIndex] = 0;
        }
        formatSize[formatIndex] += 1;
      }
    }

    var cursorInput = input;
    if (cursorOffset) {
      var cursorLetter = null;
      var cursorShift = null;
      if (cursorOffset[1] !== cursorOffset[0]) {
        // inject start cursor
        cursorLetter = cursorOffset[1] < input.length ? '\uF8D4' : '\uF8D5';
        cursorShift = cursorOffset[1] < input.length ? 0 : -1;
        cursorInput = input.substr(0, cursorOffset[1] + cursorShift) + cursorLetter + input.substr(cursorOffset[1] + cursorShift + 1);

        // inject end cursor
        cursorLetter = cursorOffset[0] < input.length ? '\uF8D2' : '\uF8D3';
        cursorShift = cursorOffset[0] < input.length ? 0 : -1;
        cursorInput = input.substr(0, cursorOffset[0] + cursorShift) + cursorLetter + input.substr(cursorOffset[0] + cursorShift + 1);
      } else {
        // inject cursor
        cursorLetter = cursorOffset[0] < input.length ? '\uF8D0' : '\uF8D1';
        cursorShift = cursorOffset[0] < input.length ? 0 : -1;
        cursorInput = input.substr(0, cursorOffset[0] + cursorShift) + cursorLetter + input.substr(cursorOffset[0] + cursorShift + 1);
      }
    }

    var value = [input];
    var cursor = [cursorInput];
    for (var _i3 = 0; _i3 < formatSep.length; ++_i3) {
      var c = value[_i3] ? formatSep[_i3] ? splitCursor(cursor[_i3], value[_i3], formatSep[_i3], 2, formatSize[_i3]) : [cursor[_i3], null] : [null, null];
      var s = value[_i3] ? formatSep[_i3] ? split(value[_i3], formatSep[_i3], 2, formatSize[_i3]) : [value[_i3], null] : [null, null];

      value[_i3] = s[0];
      value[_i3 + 1] = s[1] || null;
      cursor[_i3] = c[0];
      cursor[_i3 + 1] = c[1] || null;
    }
    value = value.filter(function (x) {
      return x !== null;
    }).map(function (x) {
      return x.replace(placeholderRegex, ' ');
    });
    cursor = cursor.filter(function (x) {
      return x !== null;
    }).map(function (x) {
      return x.replace(/[^\uF8D0-\uF8D2]/g, ' ');
    });

    var result = [];
    var resCursor = [];
    for (var _i4 = 0; _i4 < formatSize.length; ++_i4) {
      if (value.length <= _i4 + 1) {
        var rest = value[value.length - 1] || '';
        var restCursor = cursor[value.length - 1] || '';
        for (var j = 0; j < formatSize.length; ++j) {
          if (j < _i4) {
            result[j] = rPad(value[j], formatSize[j]);
            resCursor[j] = rPadCursor(cursor[j], formatSize[j]);
            if (value[j].trimRight().length > formatSize[j] && (cursor[j + 1] || '').indexOf('\uF8D0') === 0) {
              // cursor is on next segement start
              // => overwrite the first letter of the next segment with the next letter in this segemnt
              // += move the cursor by one..
              value[j + 1] = value[j][formatSize[j]] + value[j + 1].substr(1);
              cursor[j + 1] = '\uF8D1' + cursor[j + 1].substr(1);

              // make sure it has the lates information
              rest = value[value.length - 1];
              restCursor = cursor[value.length - 1];
            }
          } else {
            result[j] = rest.substr(0, formatSize[j]);
            resCursor[j] = restCursor.substr(0, formatSize[j]);
            rest = rest.substr(formatSize[j]);
            restCursor = restCursor.substr(formatSize[j]);
            if (restCursor[0] === '\uF8D0') {
              // if next section starts with cursor
              // move cursor into end of this section
              resCursor[j] = resCursor[j].substr(0, resCursor[j].length - 1) + '\uF8D1';
              restCursor = ' ' + restCursor.substr(1);
            }
          }
        }

        var tmp = '';
        var tmp2 = '';
        for (var _j = 0; _j < formatSize.length; ++_j) {
          tmp += resCursor[_j];
          tmp2 += result[_j];
          if (formatSep[_j]) {
            tmp += formatSep[_j];
            tmp2 += formatSep[_j];
          }
        }

        if (cursorOffset) {
          cursorOffset = getCursor(tmp);
        }
        return [cursorOffset, result, [formatSep, formatSize]];
      }
    }

    throw "Should never..";
  }
  exports.default = maskedFormat;
});