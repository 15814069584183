define('ui-contenteditable/utils/extract-char-offsets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _extractCharOffsets(node, preserveMultiline) {
    var res = null;
    if (node.nodeType == Node.TEXT_NODE) {
      var text = preserveMultiline ? node.nodeValue : node.nodeValue.replace(/[\r\n]/g, '');
      var len = text.length;
      return { node: node, start: 0, end: len, text: text, children: null };
    } else if (node.nodeType == Node.ELEMENT_NODE && node.tagName === "BR") {
      var _text = preserveMultiline ? '\n' : '';
      var _len = _text.length;
      return { node: node, start: 0, end: _len, text: _text, children: null };
    } else {
      var children = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = node.childNodes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var n = _step.value;

          children.push(_extractCharOffsets(n, preserveMultiline));
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      res = {
        node: node,
        start: 0,
        end: children.length,
        text: children.reduce(function (p, c) {
          return p + (c.text || '');
        }, ''),
        children: children
      };
      if (node.nodeType == Node.ELEMENT_NODE && node.tagName === "DIV") {
        if (preserveMultiline) {
          res.text = '\n' + res.text;
        }
      }
    }
    return res;
  }

  function extractCharOffsets(node, preserveMultiline) {
    var tmp = _extractCharOffsets(node, preserveMultiline);

    function addAbs(res) {
      var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

      res.textStart = offset;
      res.textEnd = offset + res.text.length;

      if (res.children) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = res.children[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var child = _step2.value;

            offset = addAbs(child, offset);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }

      return res.textEnd;
    }
    addAbs(tmp);

    // make 1d
    function make1D(res) {
      var tmp2 = [];
      if (res.children) {
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = res.children[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var child = _step3.value;

            tmp2.push.apply(tmp2, _toConsumableArray(make1D(child)));
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }
      tmp2.push(res);
      return tmp2;
    }
    return make1D(tmp);
  }

  exports.default = extractCharOffsets;
});