define("ui-page-editor-list/components/ui-page-editor-list-properties/component", ["exports", "ui-page-editor-list/components/ui-page-editor-list-properties/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    type: Ember.computed.alias('helper.type'),
    type0: Ember.computed.not('type'),
    type1: Ember.computed.equal('type', 1),

    isAlignTop: Ember.computed.not("helper.valign"),
    isAlignMiddle: Ember.computed.equal("helper.valign", 1),
    isAlignBottom: Ember.computed.equal("helper.valign", 2),

    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      incDepth: function incDepth(shift) {
        this.get('helper.moveSelectedLine')(shift);
      },
      setType: function setType(t) {
        this.get('helper.setType')(t);
      },
      setVAlign: function setVAlign() {
        this.get('helper.setVAlign').apply(undefined, arguments);
      }
    }
  });
});