define("lt-page-editor/instance-initializers/override-ui-page-editor", ["exports", "lt-page-editor/components/lt-page-editor-add-block-override/component", "lt-page-editor/components/blocks/text/lt-page-editor-text-properties/component", "lt-page-editor/components/blocks/text/lt-page-editor-text-readonly/component"], function (_exports, _component, _component2, _component3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.unregister('component:ui-page-editor-add-block');
    appInstance.register('component:ui-page-editor-add-block', _component.default);
    appInstance.unregister('component:ui-page-editor-text-properties');
    appInstance.register('component:ui-page-editor-text-properties', _component2.default);
    appInstance.unregister('component:ui-page-editor-text-readonly');
    appInstance.register('component:ui-page-editor-text-readonly', _component3.default);
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});