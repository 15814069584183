define("ui-input/utils/immediate", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function immediate(f) {
    /*if ('setImmediate' in window) {
      return window.setImmediate(f);
    } else {*/
    return new Promise(function (r) {
      r();
    }).then(f);
    //}
  }

  exports.default = immediate;
});