define("lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "99ymODOk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"helper\",\"properties\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"helper\",\"properties\"]]],[[\"forceImageSize\",\"editor\",\"helper\",\"value\"],[true,[25,[\"editor\"]],[25,[\"helper\",\"helper\"]],[25,[\"helper\",\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"lt-page-editor-text-link-button\",null,[[\"hasLink\",\"linkData\",\"editor\",\"resolver\",\"saveLink\",\"removeLink\",\"uploader\",\"onInternalPage\"],[[29,\"if\",[[25,[\"linkData\"]],true,false],null],[25,[\"linkData\"]],[25,[\"editor\"]],[25,[\"resolver\"]],[29,\"action\",[[24,0,[]],\"saveLink\"],null],[29,\"action\",[[24,0,[]],\"removeLink\"],null],[25,[\"uploader\"]],[25,[\"onInternalPage\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"showLink\"]]],null,{\"statements\":[[0,\"    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n        \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"loadData\"],null]],[9],[0,\"Daten vom Link laden\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"Layout\"],[10],[0,\"\\n    \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"layoutItems\"]],[25,[\"_layout\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser-properties/template.hbs"
    }
  });

  _exports.default = _default;
});