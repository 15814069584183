define("ui-input/components/ui-input-select", ["exports", "ui-input/components/ui-input-select-autocomplete"], function (exports, _uiInputSelectAutocomplete) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiInputSelectAutocomplete.default.extend({
    tagName: 'ui-input-select',
    autocomplete: false,

    obsValue: Ember.observer('value', function () {
      this._updateSelectedId();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._updateSelectedId();
    },
    _updateSelectedId: function _updateSelectedId() {
      var _this = this;

      var value = this.get('value');
      var items = this.get('items');
      if (items) {
        var index = items.findIndex(function (x) {
          return x.id == value;
        });
        if (index >= 0) {
          this.set('_selectedId', index);
        } else {
          this.set('_selectedId', items[0] ? 0 : null);
          Ember.run.once(function () {
            if (_this.isDestroyed) {
              return;
            }
            _this.set('value', items[0] ? JSON.parse(JSON.stringify(items[0].id)) : null);
          });
        }
      }
    }
  });
});