define("ui-page-editor-text/components/ui-page-editor-text-widget-link-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n6NN6PQp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"Title\"],[10],[0,\"\\n  \"],[1,[29,\"ui-input-text\",null,[[\"value\"],[[25,[\"_value\",\"value\"]]]]],false],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"Ziel\"],[10],[0,\"\\n  \"],[1,[29,\"ui-input-text\",null,[[\"placeholder\",\"value\"],[\"https://\",[25,[\"_value\",\"href\"]]]]],false],[0,\"\\n  \"],[7,\"button\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"ic_remove_circle_outline\"],[10],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor-text/components/ui-page-editor-text-widget-link-properties/template.hbs"
    }
  });

  _exports.default = _default;
});