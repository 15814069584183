define("lt-page-editor/instance-initializers/override-component-css", ["exports", "ember-component-css/pod-names"], function (_exports, _podNames2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var _podNamesAlternative = {};
  Object.keys(_podNames2.default).forEach(function (x) {
    var s = x.split('/');
    _podNamesAlternative[s[s.length - 1]] = _podNames2.default[x];
  });

  function initialize(appInstance) {
    Ember.Component.reopen({
      styleNamespace: Ember.computed({
        get: function get() {
          var name = this.get('_componentIdentifier');

          if (name.startsWith('lt-page-editor-')) {
            // additionally check if it ends with the component name..
            // ugly hack
            return _podNamesAlternative[name] || '';
          }

          return _podNames2.default[name] || '';
        }
      })
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});