define('ui-input/components/ui-input-richtext', ['exports', 'ui-input/components/ui-textarea-richtext'], function (exports, _uiTextareaRichtext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiTextareaRichtext.default.extend({
    tagName: 'ui-input-richtext',
    multiline: false
  });
});