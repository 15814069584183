define("ui-page-editor/components/ui-page-editor-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dJ5jc1tp",
    "block": "{\"symbols\":[\"block\"],\"statements\":[[7,\"style\"],[9],[1,[23,\"_style\"],true],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"elementId\"],\"-ui-wysiwyg-col\"]]],[12,\"data-w\",[24,1,[\"width\"]]],[9],[0,\"\\n\"],[4,\"if\",[[29,\"get\",[[25,[\"blocks\"]],[24,1,[\"component\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"component\",[[29,\"get\",[[25,[\"blocks\"]],[24,1,[\"component\"]]],null]],[[\"class\",\"value\"],[[29,\"concat\",[\"page-editor-block-readonly \",[24,1,[\"component\"]],\" \",[24,1,[\"class\"]]],null],[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor/components/ui-page-editor-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});