define("ui-page-editor-text/components/ui-page-editor-text-widget-link-properties/component", ["exports", "ui-page-editor-text/components/ui-page-editor-text-widget-link-properties/template", "ui-page-editor-text/utils/computed-json"], function (exports, _template, _computedJson) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    _value: (0, _computedJson.default)('value'),
    val2: ''
  });
});