define("ui-page-editor-text/components/ui-page-editor-text-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hnQUv+nf",
    "block": "{\"symbols\":[\"item\",\"v\"],\"statements\":[[7,\"div\"],[9],[4,\"each\",[[25,[\"_value\"]]],null,{\"statements\":[[7,\"span\"],[12,\"class\",[29,\"unbound\",[[24,1,[\"cls\"]]],null]],[9],[4,\"if\",[[29,\"unbound\",[[24,1,[\"component\"]]],null]],null,{\"statements\":[[4,\"with\",[[29,\"unbound\",[[29,\"get\",[[25,[\"widgets\"]],[24,1,[\"component\"]]],null]],null]],null,{\"statements\":[[1,[29,\"component\",[[24,2,[]]],[[\"value\"],[[29,\"unbound\",[[24,1,[\"value\"]]],null]]]],false]],\"parameters\":[2]},{\"statements\":[[7,\"span\"],[11,\"class\",\"error-editor-block\"],[9],[0,\"widget \"],[7,\"code\"],[9],[1,[24,1,[\"component\"]],false],[10],[0,\" not found\"],[10]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[1,[29,\"unbound\",[[24,1,[\"value\"]]],null],false]],\"parameters\":[]}],[10]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor-text/components/ui-page-editor-text-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});