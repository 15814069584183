define("lt-page-editor/components/lt-page-editor-toolbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ye69Xcb/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[25,[\"domToolbar\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[15,1]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/lt-page-editor-toolbar/template.hbs"
    }
  });

  _exports.default = _default;
});