define("lt-page-editor/components/lt-page-editor/component", ["exports", "lt-page-editor/components/lt-page-editor/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'lt-page-editor',
    columns: 3,
    columnWidth: '434px',
    layout: _template.default
  });

  _exports.default = _default;
});