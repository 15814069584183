define('ui-contenteditable/utils/extract-text-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = extractTextContent;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function extractTextContent(node, preserveMultiline) {
    if (node.nodeType == Node.TEXT_NODE) {
      var tmp = node.parentNode.cloneNode(false);
      if (preserveMultiline) {
        tmp.appendChild(document.createTextNode(node.nodeValue));
      } else {
        tmp.appendChild(document.createTextNode(node.nodeValue.replace(/[\r\n]/g, '')));
      }
      return [tmp];
    }
    if (preserveMultiline && node.nodeType === Node.ELEMENT_NODE && node.tagName === "BR") {
      var _tmp = node.cloneNode(false);
      _tmp.appendChild(document.createTextNode('\n'));
      return [_tmp];
    }
    var res = [];
    if (preserveMultiline && node.nodeType === Node.ELEMENT_NODE && node.tagName === "DIV") {
      if (node.parentNode.firstChild !== node) {
        var _tmp2 = node.cloneNode(false);
        _tmp2.appendChild(document.createTextNode('\n'));
        res.push(_tmp2);
      }
    }
    if (!node.dataset || !node.dataset.component) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = node.childNodes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var n = _step.value;

          res.push.apply(res, _toConsumableArray(extractTextContent(n, preserveMultiline)));
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    } else {
      res.push(node);
    }

    return res;
  }
});