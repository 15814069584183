define("lt-page-editor/components/blocks/pk-lieferando/lt-page-editor-pk-lieferando/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0SOw/q0g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"lt-page-editor-pk-lieferando-readonly\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n\"],[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-lieferando/lt-page-editor-pk-lieferando/template.hbs"
    }
  });

  _exports.default = _default;
});