define("lt-page-editor/components/blocks/image/lt-page-editor-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "utaT1HQY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"lt-page-editor-image-readonly\",null,[[\"resolver\",\"disableTagit\",\"value\",\"preview\",\"text\"],[[25,[\"resolver\"]],true,[25,[\"value\"]],true,[25,[\"textReadonly\"]]]]],false],[0,\"\\n\"],[4,\"unless\",[[25,[\"hideInlineEdit\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"edit\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n    \"],[7,\"button\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"edit\"],[10],[0,\" Media Bearbeiten\"],[3,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"showEdit\"]]],null],true]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[25,[\"_cursor\"]]],null,{\"statements\":[[0,\"  \"],[15,1,[[25,[\"helper\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/image/lt-page-editor-image/template.hbs"
    }
  });

  _exports.default = _default;
});