define("lt-page-editor/helpers/lt-page-editor-object-merge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (args) {
    var x = args.reduce(function (p, c) {
      return c && Object.assign(p, c) || p;
    }, {});

    for (var _i = 0, _Object$keys = Object.keys(x); _i < _Object$keys.length; _i++) {
      var key = _Object$keys[_i];

      if (x[key] === undefined) {
        delete x[key];
      }
    }

    return x;
  });

  _exports.default = _default;
});