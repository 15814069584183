define("lt-page-editor/components/blocks/sp-gewinnspiel/lt-page-editor-sp-gewinnspiel-properties/component", ["exports", "lt-page-editor/components/blocks/sp-gewinnspiel/lt-page-editor-sp-gewinnspiel-properties/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    linkData: Ember.computed('helper.link', {
      get: function get() {
        return Ember.get(this, 'helper.link');
      },
      set: function set(k, v) {
        k;
        Ember.get(this, 'helper.setLink')(v);
        return v;
      }
    }),
    _onInternalPage: function _onInternalPage(options) {
      return Ember.get(this, 'onInternalPage')(Object.assign({
        filter: 'gewinnspiel'
      }, options || {}));
    },
    actions: {
      saveLink: function saveLink(value) {
        Ember.set(this, 'linkData', value);
      },
      removeLink: function removeLink() {
        Ember.set(this, 'linkData', null);
      }
    }
  });

  _exports.default = _default;
});