define("lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ceiLa/dv",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"lt-page-editor-text-link-button\",null,[[\"hasLink\",\"linkData\",\"editor\",\"resolver\",\"saveLink\",\"removeLink\",\"uploader\",\"onInternalPage\"],[[29,\"if\",[[25,[\"linkData\"]],true,false],null],[25,[\"linkData\"]],[25,[\"editor\"]],[25,[\"resolver\"]],[29,\"action\",[[24,0,[]],\"saveLink\"],null],[29,\"action\",[[24,0,[]],\"removeLink\"],null],[25,[\"uploader\"]],[25,[\"onInternalPage\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link-properties/template.hbs"
    }
  });

  _exports.default = _default;
});