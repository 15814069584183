define("ui-page-editor/components/ui-page-editor-popover/component", ["exports", "ui-page-editor/components/ui-page-editor-popover/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ui-page-editor-popover',
    layout: _template.default,
    classNameBindings: ['dir'],
    dir: 'left'
  });
});