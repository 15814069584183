define('ui-input/components/ui-input-text', ['exports', 'ui-input/components/ui-textarea'], function (exports, _uiTextarea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiTextarea.default.extend({
    tagName: 'ui-input',
    multiline: false
  });
});