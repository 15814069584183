define("ui-page-editor-text/components/ui-page-editor-text-widget-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sTOXdIK1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[25,[\"parent\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[7,\"div\"],[11,\"contenteditable\",\"false\"],[12,\"class\",[30,[[23,\"componentCssClassName\"],\"-popup\"]]],[9],[4,\"ui-page-editor-popover\",null,[[\"dir\"],[\"top\"]],{\"statements\":[[15,1]],\"parameters\":[]},null],[10]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor-text/components/ui-page-editor-text-widget-properties/template.hbs"
    }
  });

  _exports.default = _default;
});