define("lt-page-editor/components/blocks/npk-teaser/lt-page-editor-npk-teaser-readonly/component", ["exports", "lt-page-editor/components/blocks/npk-teaser/lt-page-editor-npk-teaser-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function filter(name, v) {
    return Ember.computed(name, function () {
      return (Ember.get(this, name) || []).filter(function (x) {
        return x && (Ember.get(x, 'id') / 1000 | 0) == v;
      });
    });
  }

  function getUrl(x) {
    try {
      var url = new URL(x);

      if (url.host.includes('awin1.com')) {
        return url.searchParams.get('ued');
      }

      return url.href;
    } catch (ex) {
      ex;
      return x;
    }
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    _value: Ember.computed.alias('value.data'),
    lokal: Ember.computed.readOnly('model.lokal'),
    bezahlmoeglichkeiten: filter('model.lokal.attributes', 4),
    ausstattung: filter('model.lokal.attributes', 5),
    angebot: filter('model.lokal.attributes', 6),
    extra: filter('model.lokal.attributes', 7),
    address: Ember.computed('model.lokal.address', function () {
      var address = Ember.get(this, 'model.lokal.address');
      var ad = [[address.street, address.num], [address.plz, address.city], [address.additional]];
      return ad.map(function (x) {
        return x.filter(function (x) {
          return x;
        }).join(' ');
      }).filter(function (x) {
        return x;
      }).join(', ');
    }),
    isNotPremium: Ember.computed.equal('model.lokal.premiumStatusId', 0),
    isPremium: Ember.computed.not('isNotPremium'),
    socialmedia: Ember.computed('lokal.websites', function () {
      var social = [{
        name: 'facebook',
        title: 'Facebook',
        icon: '/lt-page-editor/social/facebook.svg'
      }, {
        name: 'instagram',
        title: 'Instagram',
        icon: '/lt-page-editor/social/instagram.svg'
      }, {
        name: 'tripadvisor',
        title: 'Tripadvisor',
        icon: '/lt-page-editor/social/tripadvisor.svg'
      }, {
        name: 'twitter',
        title: 'Twitter',
        icon: '/lt-page-editor/social/twitter.svg'
      }, {
        name: 'youtube',
        title: 'YouTube',
        icon: '/lt-page-editor/social/youtube.svg'
      }, {
        name: 'lieferando',
        title: 'Lieferando',
        icon: '/lt-page-editor/social/lieferando.svg'
      }];
      var websites = Ember.get(this, 'lokal.websites');

      if (!websites) {
        return;
      }

      websites = websites.filter(function (x) {
        return social.some(function (y) {
          return getUrl(x).toLowerCase().includes(y.name.toLowerCase());
        });
      });
      return websites.map(function (x) {
        return {
          icon: social.find(function (y) {
            return getUrl(x).toLowerCase().includes(y.name.toLowerCase());
          }).icon,
          href: x,
          title: social.find(function (y) {
            return getUrl(x).toLowerCase().includes(y.name.toLowerCase());
          }).title
        };
      });
    }),
    lieferando: Ember.computed('lokal.website', 'lokal.websites', function () {
      var lieferando = [Ember.get(this, 'lokal.website')].concat(Ember.get(this, 'lokal.websites') || []).filter(function (x) {
        return x;
      }).find(function (x) {
        return getUrl(x).toLowerCase().includes('lieferando');
      });

      if (!lieferando) {
        return null;
      }

      return lieferando;
    }),
    website: Ember.computed('lokal.website', function () {
      var website = Ember.get(this, 'lokal.website');

      if (!website) {
        return null;
      }

      return website;
    })
  });

  _exports.default = _default;
});