define("lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pBNNkwzg",
    "block": "{\"symbols\":[\"helper3\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"block \",[29,\"if\",[[25,[\"subselected\"]],[29,\"concat\",[\"selected-\",[25,[\"subselected\"]]],null]],null],\" layout-\",[23,\"layoutStyle\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"image\"],[11,\"data-component\",\"image\"],[9],[0,\"\\n\"],[4,\"component\",[[25,[\"image\",\"component\"]]],[[\"editor\",\"value\"],[[25,[\"editor\"]],[25,[\"_value\",\"image\"]]]],{\"statements\":[[0,\"      \"],[15,2,[[29,\"hash\",null,[[\"properties\",\"value\",\"helper\"],[[25,[\"image\",\"properties\"]],[25,[\"_value\",\"image\"]],[24,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n    \"],[1,[29,\"ui-textarea\",null,[[\"value\"],[[25,[\"_value\",\"text1\"]]]]],false],[0,\"\\n    \"],[1,[29,\"ui-textarea\",null,[[\"value\"],[[25,[\"_value\",\"text2\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,2,[[29,\"hash\",null,[[\"helper\"],[[25,[\"helper\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser/template.hbs"
    }
  });

  _exports.default = _default;
});