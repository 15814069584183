define("lt-page-editor/components/blocks/pk-open-small/lt-page-editor-pk-open-small-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-open-small/lt-page-editor-pk-open-small-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function pad(str) {
    return ("00" + str).substr(-2);
  }

  function timeToNumber(x, h24) {
    if (x) {
      var tmp = "".concat(x).split(':');
      var hours = parseInt(tmp[0], 10);
      var minutes = parseInt(tmp[1], 10);

      if (h24 && hours === 0 && minutes === 0) {
        hours += 24;
      }

      return hours * 100 + minutes;
    }

    return NaN;
  }

  function numberToTime(x) {
    if (x || x === 0) {
      return "".concat(pad(Math.floor(x / 100)), ":").concat(pad(x % 100));
    }

    return null;
  }

  function getOpenInfo(time, values) {
    var _iterator = _createForOfIteratorHelper(values),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var times = _step.value;

        if (time >= times.from && time <= times.to) {
          return {
            open: true,
            day: times.day
          };
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return {
      open: false,
      day: null
    };
  }

  function evaluateOpenHours(h) {
    var now = new Date();
    var dateDay = now.getDay();

    if (!h || !h.length) {
      return null;
    }

    var dayId = (dateDay + 6) % 7 + 1;
    var previousDayId = (dateDay + 5) % 7 + 1;
    var day = h.find(function (x) {
      return Ember.get(x, 'dayId') == dayId;
    });
    var previousDay = h.find(function (x) {
      return Ember.get(x, 'dayId') == previousDayId;
    }); // contains current days times and those from previous day if they overlap into the current day
    // current day gets cut off at 24:00

    var transformedValues = [];

    if (day) {
      for (var i = 0; i < day.values.length; i += 2) {
        var from = timeToNumber(day.values[i]);
        var to = timeToNumber(day.values[i + 1]);

        if (!isNaN(from) && !isNaN(to)) {
          if (to === 0) {
            to = 2400; // because sometimes it says 08:00 - 00:00 instead of 24:00
          }

          if (from > to) {
            transformedValues.push({
              from: from,
              to: 2400,
              day: day
            });
          } else {
            transformedValues.push({
              from: from,
              to: to,
              day: day
            });
          }
        }
      }
    }

    if (previousDay) {
      for (var _i = 0; _i < previousDay.values.length; _i += 2) {
        var _from = timeToNumber(previousDay.values[_i]);

        var _to = timeToNumber(previousDay.values[_i + 1]);

        if (!isNaN(_from) && !isNaN(_to)) {
          if (_to === 0) {
            _to = 2400;
          }

          if (_from > _to) {
            transformedValues.push({
              from: 0,
              to: _to,
              day: previousDay
            });
          }
        }
      }
    }

    var time = timeToNumber("".concat(pad(now.getHours()), ":").concat(pad(now.getMinutes())));
    var openInfo = getOpenInfo(time, transformedValues);
    var openDay = openInfo.day || day;
    var openDayId = openDay ? Ember.get(openDay, 'dayId') : dayId;
    return {
      tag: [null, "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"][openDayId],
      open: openDay && openDay.values[0] && openInfo.open,
      dual: openDay && openDay.values[0] && openDay.values[2],
      from1: openDay && numberToTime(timeToNumber(openDay.values[0])),
      to1: openDay && numberToTime(timeToNumber(openDay.values[1], true)),
      from2: openDay && numberToTime(timeToNumber(openDay.values[2])),
      to2: openDay && numberToTime(timeToNumber(openDay.values[3], true))
    };
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    lokal: Ember.computed('model.lokal.openHours.@each.values', function () {
      var h = Ember.get(this, 'model.lokal.openHours');
      return evaluateOpenHours.call(this, h);
    }),
    kitchen: Ember.computed('model.lokal.kitchenHours.@each.values', function () {
      var h = Ember.get(this, 'model.lokal.kitchenHours');
      return evaluateOpenHours.call(this, h);
    })
  });

  _exports.default = _default;
});