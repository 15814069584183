define("lt-page-editor/components/blocks/inception/lt-page-editor-inception-properties/component", ["exports", "lt-page-editor/components/blocks/inception/lt-page-editor-inception-properties/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    animateItems: Ember.computed(function () {
      return [{
        id: null,
        value: 'Keine'
      }, {
        id: 1,
        value: 'Horizontales Verschieben'
      }];
    }),
    alignSelf: Ember.computed(function () {
      return [{
        id: 3,
        value: "Gestreckt"
      },
      /*{ id: 1, value: "Abstand Rundum" },
      { id: 2, value: "Abstand Dazwischen" },*/
      {
        id: 0,
        value: "Anfang"
      }, {
        id: 4,
        value: "Mitte"
      }, {
        id: 5,
        value: "Ende"
      }];
    }),
    _align: Ember.computed('helper.helper.align', {
      get: function get(k) {
        k;
        return Ember.get(this, 'helper.helper.align') | 0;
      },
      set: function set(k, v) {
        k;
        Ember.get(this, 'helper.helper.setAlign')(v);
        return v;
      }
    }),
    _animate: Ember.computed('helper.helper.animate', {
      get: function get(k) {
        k;
        return Ember.get(this, 'helper.helper.animate') | 0;
      },
      set: function set(k, v) {
        k;
        Ember.get(this, 'helper.helper.setAnimate')(v);
        return v;
      }
    }),
    actions: {
      save: function save(value) {
        Ember.get(this, 'helper.helper.setValue')(JSON.parse(JSON.stringify(value)));
      }
    }
  });

  _exports.default = _default;
});