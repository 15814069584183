define("lt-page-editor/components/blocks/title/lt-page-editor-title-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EBxJXuhu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"text1\"],[9],[4,\"if\",[[25,[\"value\",\"data\",\"text1\"]]],null,{\"statements\":[[1,[25,[\"value\",\"data\",\"text1\"]],false]],\"parameters\":[]},{\"statements\":[[0,\"​\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"value\",\"layout\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h2\"],[11,\"class\",\"text2\"],[9],[4,\"if\",[[25,[\"value\",\"data\",\"text2\"]]],null,{\"statements\":[[1,[25,[\"value\",\"data\",\"text2\"]],false]],\"parameters\":[]},{\"statements\":[[0,\"​\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/title/lt-page-editor-title-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});