define("lt-page-editor/components/blocks/inception/lt-page-editor-inception-readonly/component", ["exports", "lt-page-editor/components/blocks/inception/lt-page-editor-inception-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['_animate'],
    inceptionClass: Ember.computed('value.align', function () {
      return "layout-".concat(Ember.get(this, 'value.align') | 0);
    }),
    _value: Ember.computed('value.data', function () {
      return Ember.get(this, 'value.data');
    }),
    padding: '0em',
    //<- muss ident mit style sein
    columnWidth: Ember.computed('value.width', 'editor.columnWidth', function () {
      var width = Ember.get(this, 'value.width');
      var cwidth = Ember.get(this, 'editor.columnWidth');
      var padding = Ember.get(this, 'padding');
      return "calc((".concat(cwidth, " * ").concat(width, " - 2 * ").concat(padding, ") / ").concat(width, ")");
    }),
    _animate: Ember.computed('value.animate', function () {
      if (!this.value.animate) {
        return;
      }

      return "animate-".concat(this.value.animate);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.updateAnimate = this.updateAnimate.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.value.animate) {
        this._aniOffset = 0;
        this._timer = setInterval(this.updateAnimate, 3737);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      clearInterval(this._timer);
    },
    updateAnimate: function updateAnimate() {
      if (!this.value.animate) {
        return;
      }

      var row = this.element.querySelector("div > lt-page-editor-readonly > .lt-ui-wysiwyg-row-wrapper > .lt-ui-wysiwyg-row");

      if (row.childElementCount < 1) {
        for (var _i = 0, _arr = _toConsumableArray(row.children); _i < _arr.length; _i++) {
          var x = _arr[_i];
          x.style.transform = null;
        }

        return;
      }

      if (this._aniOffset + 1 > row.childElementCount) {
        this._aniOffset = 0;
      }

      var offset = this._aniOffset * row.clientWidth;
      this._aniOffset += 1;

      for (var _i2 = 0, _arr2 = _toConsumableArray(row.children); _i2 < _arr2.length; _i2++) {
        var _x = _arr2[_i2];
        _x.style.transform = "translateX(-".concat(offset, "px)");
      }
    }
  });

  _exports.default = _default;
});