define("ui-page-editor-text/components/ui-page-editor-text-widget-link-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qL6Yd3Cy",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[29,\"if\",[[25,[\"active\"]],\"active\"],null]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"onclick\"]]],null]],[11,\"title\",\"Link einfügen\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"insert_link\"],[10],[0,\"\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor-text/components/ui-page-editor-text-widget-link-button/template.hbs"
    }
  });

  _exports.default = _default;
});