define("lt-page-editor/components/blocks/pk-block/lt-page-editor-pk-block/component", ["exports", "lt-page-editor/components/blocks/pk-block/lt-page-editor-pk-block/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    layout: _template.default,
    helper: Ember.computed('value.data.link', function () {
      var helper = {
        link: Ember.get(this, 'value.data.link'),
        setLink: this.setLink.bind(this)
      };
      return helper;
    }),
    setLink: function setLink(l) {
      Ember.set(this, 'value.data.link', l);
    },
    isVersion2: Ember.computed.equal('_value.version', 2),
    _value: Ember.computed('value.data', function () {
      var value = Ember.get(this, 'value');

      if (!value.data.title) {
        Ember.set(value, 'data', Object.assign(value.data, {
          title: 'Titel',
          image: {
            data: []
          },
          text: {
            data: ["", "Text"]
          },
          inception: {
            data: []
          }
        }));
      }

      return value.data;
    }),
    hasImageAndText: Ember.computed('_value.withImage', '_value.withText', function () {
      return Ember.get(this, '_value.withImage') && Ember.get(this, '_value.withText');
    }),
    imageStyle: Ember.computed('hasImageAndText', '_value.layout', '_value.width', 'possibleSizes.[]', function () {
      if (!Ember.get(this, 'hasImageAndText')) {
        return;
      }

      var width = Ember.get(this, '_value.width') | 0;
      var layout = Ember.get(this, '_value.layout') || 1;

      if (layout == 1) {
        // bild + text
        var possibleSizes = Ember.get(this, 'possibleSizes');
        var w = " ".concat(possibleSizes[Math.min(width, possibleSizes.length - 1)], "px");
        return Ember.String.htmlSafe("max-width: ".concat(w, "; min-width: ").concat(w));
      }

      if (layout == 2) {
        // text + bild
        return;
      }
    }),
    fixedColumnWidth: Ember.computed('textStyle', 'value.width', function () {
      var width = Ember.get(this, 'value.width');
      var stext = this.element.querySelector('.stext');

      if (!stext) {
        return "128px";
      }

      stext.style.overflow = 'hidden';
      var cccs = window.getComputedStyle(stext);
      var w = cccs.width;
      stext.style.overflow = '';
      return "calc(".concat(w, " / ").concat(width, ")");
    }),
    textStyle: Ember.computed('hasImageAndText', '_value.layout', '_value.width', 'value.width', 'possibleSizes.[]', function () {
      if (!Ember.get(this, 'hasImageAndText')) {
        return;
      }

      var width = Ember.get(this, '_value.width') | 0;
      var layout = Ember.get(this, '_value.layout') || 1;

      if (layout == 1) {
        // bild + text
        return;
      }

      if (layout == 2) {
        // text + bild
        var possibleSizes = Ember.get(this, 'possibleSizes');
        var w = " ".concat(possibleSizes[Math.min(width, possibleSizes.length - 1)], "px");
        return Ember.String.htmlSafe("max-width: ".concat(w, "; min-width: ").concat(w));
      }
    }),
    possibleSizes: Ember.computed('value.width', function () {
      var possibleSizes = [];
      var w = Ember.get(this, 'value.width');
      var r = 0.381966011; // golden ratio

      var cccs = window.getComputedStyle(this.element);
      var pl = parseFloat(cccs.paddingLeft);
      var pr = parseFloat(cccs.paddingRight);
      var pw = pl + parseFloat(cccs.width) + pr; // size of full element

      var pw1 = pw / w;

      for (var i = 0; i < w; ++i) {
        if (i != 0) {
          possibleSizes.push(pw1 * i - pr);
        }

        possibleSizes.push(pw1 * i + pw1 * r);
        possibleSizes.push(pw1 * i + pw1 - pw1 * r);
      }

      return possibleSizes;
    }),
    actions: {
      startResize: function startResize(e) {
        var _this = this;

        if (e.button != 0) {
          return;
        }

        e.preventDefault();
        var el = this.element.querySelector('.layout-1 .simage, .layout-2 .stext');
        var possibleSizes = Ember.get(this, 'possibleSizes');
        var elShadow = document.createElement('div'); //const oriWidth = el.style.maxWidth;

        var mouseUp = function mouseUp() {
          e.preventDefault(); //el.style.maxWidth = oriWidth;

          elShadow.remove();
          Ember.set(_this, '_value.width', _this._tmpSize | 0);
        };

        var mouseMove = function mouseMove(e) {
          e.preventDefault();

          if (e.button != 0) {
            return mouseUp(e);
          } // move


          var current = {
            d: Number.MAX_VALUE,
            s: 0
          };

          var _iterator = _createForOfIteratorHelper(possibleSizes),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var s = _step.value;
              el.style.maxWidth = "".concat(s, "px");
              el.style.minWidth = el.style.maxWidth;
              var bb = el.getBoundingClientRect();
              var r = bb.left + bb.width;
              var d = Math.abs(r - e.clientX);

              if (current.d > d) {
                current.d = d;
                current.s = s;
                current.i = possibleSizes.indexOf(s);
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          el.style.maxWidth = "".concat(current.s, "px");
          el.style.minWidth = el.style.maxWidth;
          _this._tmpSize = current.i;
        };

        elShadow.style.cursor = 'ew-resize';
        elShadow.style.position = 'fixed';
        elShadow.style.left = '0';
        elShadow.style.top = '0';
        elShadow.style.right = '0';
        elShadow.style.bottom = '0';
        elShadow.style.zIndex = '999999';
        document.body.appendChild(elShadow);
        elShadow.addEventListener('mouseup', mouseUp);
        elShadow.addEventListener('mousemove', mouseMove);
      }
    }
  });

  _exports.default = _default;
});