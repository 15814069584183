define("lt-page-editor/components/blocks/image/lt-page-editor-image-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aBTqCEEi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"helper\",\"openEdit\"]]],null]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"edit\"],[10],[0,\" Media Bearbeiten\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[4,\"unless\",[[25,[\"hideSizes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"Beschreibung\"],[10],[0,\"\\n    \"],[1,[29,\"ui-input-select\",null,[[\"items\",\"value\"],[[25,[\"descriptionItems\"]],[25,[\"_description\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"helper\",\"showEdit\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"lt-page-editor-image-properties-popup\",null,[[\"resolver\",\"uploader\",\"editor\",\"helper\",\"onSearchAddr\",\"text\",\"singleMode\",\"close\"],[[25,[\"resolver\"]],[25,[\"uploader\"]],[25,[\"editor\"]],[25,[\"helper\"]],[25,[\"onSearchAddr\"]],[25,[\"text\"]],[25,[\"singleMode\"]],[29,\"action\",[[24,0,[]],[25,[\"helper\",\"closeEdit\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/image/lt-page-editor-image-properties/template.hbs"
    }
  });

  _exports.default = _default;
});