define("lt-page-editor/components/blocks/teaser/lt-page-editor-teaser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4EQ0180o",
    "block": "{\"symbols\":[\"helper2\",\"helper3\",\"&default\"],\"statements\":[[7,\"canvas\"],[11,\"width\",\"1920\"],[11,\"height\",\"864\"],[11,\"_height\",\"1080\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"image\"],[11,\"data-component\",\"image\"],[9],[0,\"\\n\"],[4,\"component\",[[25,[\"image\",\"component\"]]],[[\"hideInlineEdit\",\"editor\",\"value\"],[true,[25,[\"editor\"]],[25,[\"_value\",\"image\"]]]],{\"statements\":[[0,\"    \"],[15,3,[[29,\"hash\",null,[[\"properties\",\"value\",\"helper\"],[[25,[\"image\",\"properties\"]],[25,[\"_value\",\"image\"]],[24,2,[]]]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[10],[0,\"\\n\"],[15,3,[[25,[\"helper\"]]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"scaler\"],[9],[0,\"\\n    \"],[1,[29,\"ui-textarea\",null,[[\"class\",\"value\"],[\"text-1\",[25,[\"_value\",\"text1\"]]]]],false],[0,\"\\n    \"],[1,[29,\"ui-textarea\",null,[[\"class\",\"value\"],[\"text-2\",[25,[\"_value\",\"text2\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"hasText3\"]]],null,{\"statements\":[[4,\"component\",[[25,[\"text\",\"component\"]]],[[\"class\",\"editor\",\"value\"],[\"text-3\",[25,[\"editor\"]],[25,[\"_value\",\"text3\"]]]],{\"statements\":[[0,\"        \"],[15,3,[[29,\"hash\",null,[[\"properties\",\"value\",\"helper\"],[[25,[\"text\",\"properties\"]],[25,[\"_value\",\"text3\"]],[24,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/teaser/lt-page-editor-teaser/template.hbs"
    }
  });

  _exports.default = _default;
});