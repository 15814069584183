define("lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link-properties/component", ["exports", "lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link-properties/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    linkData: Ember.computed('helper.helper.link', {
      get: function get() {
        return Ember.get(this, 'helper.helper.link');
      },
      set: function set(k, v) {
        Ember.get(this, 'helper.helper.setLink')(v);
      }
    }),
    actions: {
      saveLink: function saveLink(value) {
        Ember.set(this, 'linkData', value);
      },
      removeLink: function removeLink() {
        Ember.set(this, 'linkData', null);
      }
    }
  });

  _exports.default = _default;
});