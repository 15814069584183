define("ui-page-editor-text/components/ui-page-editor-text-widget/component", ["exports", "ui-page-editor-text/components/ui-page-editor-text-widget/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'span',
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this.element.setAttribute('contenteditable', 'false');
    },


    value: Ember.computed('tv', {
      get: function get(k) {
        k;
        return this.element.parentElement.dataset.value;
      },
      set: function set(k, v) {
        this.element.parentElement.dataset.value = v;
        return v;
      }
    })
  });
});