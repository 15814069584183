define("ui-page-editor-list/components/ui-page-editor-list-readonly/component", ["exports", "ui-page-editor-list/components/ui-page-editor-list-readonly/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.wrapperObject = undefined;


  var getSetFromArray = function getSetFromArray(arrayName, indexName, offset) {
    return Ember.computed(arrayName + '.@each', indexName, {
      get: function get(k) {
        k;
        return Ember.get(this, arrayName)[Ember.get(this, indexName) + offset];
      },
      set: function set(k, v) {
        k;
        Ember.get(this, arrayName).replace(Ember.get(this, indexName) + offset, 1, [v]);
        return v;
      }
    });
  };

  var wrapperObject = Ember.Object.extend({
    array: null,
    index: null,

    depth: getSetFromArray('array', 'index', 0),
    value: getSetFromArray('array', 'index', 1)
  });

  exports.wrapperObject = wrapperObject;
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['listType', 'classHelper'],
    listType: Ember.computed('value.type', function () {
      return this.get('value.type') ? 'list-ol' : 'list-ul';
    }),
    classHelper: Ember.computed('value.valign', function () {
      var tmp = '';
      switch (this.get('value.valign')) {
        case 1:
          tmp += "valign-middle ";
          break;
        case 2:
          tmp += "valign-bottom ";
          break;
        default:
          tmp += "valign-top ";
      }
      return tmp;
    }),
    _model: function _model(items) {
      var res = [];
      var olNrStack = [];

      // stores as [depth1, value1, depth2, value2]
      for (var i = 0; items && i < items.length; i += 2) {
        var depth = items[i];

        var original = wrapperObject.create({ array: items, index: i });

        var olNr = (olNrStack[depth] | 0) + 1;
        olNrStack[depth] = olNr;
        olNrStack[depth + 1] = 0;

        res.push({
          index: i / 2,
          olNr: olNr,
          original: original
        });
      }

      return res;
    },

    model: Ember.computed('value.data', function () {
      var value = this.get('value.data');
      return this._model(value);
    })
  });
});