define("lt-page-editor/components/lt-page-editor-add-block-override/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DoZ9cy3l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],\"onmousedown\"],null]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[7,\"button\"],[12,\"id\",[30,[[29,\"concat\",[[25,[\"elementId\"]],\"-btn\"],null]]]],[11,\"title\",\"Block hinzufügen\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"openPopover2\"],null]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"add\"],[10],[10],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"showPopover\"]]],null,{\"statements\":[[0,\"    \"],[15,1,[[29,\"action\",[[24,0,[]],\"addBlock\"],null]]],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/lt-page-editor-add-block-override/template.hbs"
    }
  });

  _exports.default = _default;
});