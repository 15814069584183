define("lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QH2oJ2oq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"lt-page-editor-sp-menu-link-readonly\",null,[[\"value\",\"resolver\",\"transition\"],[[25,[\"value\"]],[25,[\"resolver\"]],[25,[\"transition\"]]]]],false],[0,\"\\n\"],[15,1,[[29,\"hash\",null,[[\"helper\"],[[25,[\"helper\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link/template.hbs"
    }
  });

  _exports.default = _default;
});