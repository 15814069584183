define("lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser/component", ["exports", "lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    showNameBefore: true,
    showNameAfter: true,
    fromDB: Ember.computed.not('_value.type'),
    textWidth: Ember.computed('value.width', function () {
      var w = Ember.get(this, 'value.width');

      if (w <= 0) {
        w = 3;
      } //! TODO get value from "editor"


      return "".concat(w * 434, "px");
    }),
    _value: Ember.computed('value.data', function () {
      var value = Ember.get(this, 'value');

      if (!value.data || !Ember.get(value.data, 'inception')) {
        Ember.set(value, 'data', Object.assign(value.data || {}, {
          nameBefore: 'Titel 1',
          name: 'Titel 2',
          nameAfter: 'Title 3',
          inception: {
            data: []
          }
        }));
      }

      return value.data;
    }),
    helper: Ember.computed(function () {
      var helper = {};
      return helper;
    })
  });

  _exports.default = _default;
});