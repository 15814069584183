define("lt-page-editor/components/blocks/text/lt-page-editor-text-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cs5jKDCF",
    "block": "{\"symbols\":[\"item\",\"v\",\"item2\",\"v\"],\"statements\":[[7,\"div\"],[9],[4,\"each\",[[25,[\"_value\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[4,\"if\",[[24,1,[\"link\"]]],null,{\"statements\":[[4,\"lt-link\",null,[[\"class\",\"preview\",\"resolver\",\"transition\",\"link\"],[[24,1,[\"cls\"]],[25,[\"preview\"]],[25,[\"resolver\"]],[25,[\"transition\"]],[24,1,[\"link\"]]]],{\"statements\":[[4,\"each\",[[24,1,[\"value\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[7,\"span\"],[12,\"class\",[24,3,[\"cls\"]]],[9],[4,\"if\",[[24,3,[\"component\"]]],null,{\"statements\":[[4,\"with\",[[29,\"get\",[[25,[\"widgets\"]],[24,3,[\"component\"]]],null]],null,{\"statements\":[[1,[29,\"component\",[[24,4,[]]],[[\"value\"],[[24,3,[\"value\"]]]]],false]],\"parameters\":[4]},{\"statements\":[[7,\"span\"],[11,\"class\",\"error-editor-block\"],[9],[0,\"widget \"],[7,\"code\"],[9],[1,[24,3,[\"component\"]],false],[10],[0,\" not found\"],[10]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[1,[24,3,[\"value\"]],false]],\"parameters\":[]}],[10]],\"parameters\":[3]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[7,\"span\"],[12,\"class\",[24,1,[\"cls\"]]],[9],[4,\"if\",[[24,1,[\"component\"]]],null,{\"statements\":[[4,\"with\",[[29,\"get\",[[25,[\"widgets\"]],[24,1,[\"component\"]]],null]],null,{\"statements\":[[1,[29,\"component\",[[24,2,[]]],[[\"value\"],[[24,1,[\"value\"]]]]],false]],\"parameters\":[2]},{\"statements\":[[7,\"span\"],[11,\"class\",\"error-editor-block\"],[9],[0,\"widget \"],[7,\"code\"],[9],[1,[24,1,[\"component\"]],false],[10],[0,\" not found\"],[10]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[1,[24,1,[\"value\"]],false]],\"parameters\":[]}],[10]],\"parameters\":[]}]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/text/lt-page-editor-text-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});