define("ui-input/components/ui-input-autocomplete", ["exports", "ui-input/components/ui-input-select-autocomplete", "ui-input/utils/sendAction"], function (exports, _uiInputSelectAutocomplete, _sendAction) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiInputSelectAutocomplete.default.extend({
    tagName: 'ui-input-autocomplete',

    _value: Ember.computed('value', {
      get: function get(k) {
        k;
        return this.get('value');
      },
      set: function set(k, v) {
        k;
        this.set('value', v);
        (0, _sendAction.default)(this, 'textChanged', v);
        return v;
      }
    }),

    _updateFocus: function _updateFocus() {
      if (!this.element) {
        return;
      }
      this.element.classList.remove('focus');

      if (this.get('disabled')) {
        return;
      }

      if (this._focusRC > 0) {
        this.element.classList.add('focus');
      }
    }
  });
});