define("ui-contenteditable/components/ui-contenteditable", ["exports", "ui-contenteditable/templates/components/ui-contenteditable", "ui-contenteditable/utils/content-object", "ui-contenteditable/utils/emoji-regex", "ui-contenteditable/utils/extract-char-offsets", "ui-contenteditable/utils/extract-text-content", "ui-contenteditable/utils/send-action"], function (exports, _uiContenteditable, _contentObject, _emojiRegex, _extractCharOffsets, _extractTextContent, _sendAction) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ContentObject = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.ContentObject = _contentObject.default;


  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (var i = arr1.length; i--;) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }

  function textContent(node, preserveMultiline) {
    if (node.nodeType == Node.TEXT_NODE) {
      return node.nodeValue;
    }
    if (node.nodeType === Node.ELEMENT_NODE && node.tagName === "BR") {
      return '\n';
    }
    var res = '';
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = node.childNodes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var n = _step.value;

        res += textContent(n, preserveMultiline);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    if (node.nodeType === Node.ELEMENT_NODE && node.tagName === "DIV") {
      res += '\n';
    }
    if (preserveMultiline) {
      return res;
    } else {
      return res.replace(/[\r\n]/g, '');
    }
  }

  var events = ['focus', 'blur',
  // keyboard
  'keydown', 'keyup', 'keypress',
  // im
  'compositionstart', 'compositionupdate', 'compositionend',
  // dnd
  'drag', 'dragend', 'dragenter', 'dragexit', 'dragleave', 'dragover', 'dragstart', 'drop',
  // actions
  'cut', 'copy', 'past',
  // mouse
  'mousedown', 'mouseup'];

  exports.default = Ember.Component.extend({
    tagName: 'ui-contenteditable',
    layout: _uiContenteditable.default,
    attributeBindings: ['tabindex', 'contenteditable', 'role', 'readonly', 'disabled', 'readonly:aria-readonly', 'aria-label', 'spellcheck'],
    notContenteditable: Ember.computed.or('readonly', 'disabled'),
    contenteditable: Ember.computed.not('notContenteditable'),
    content: null,
    role: 'textbox',
    readonly: false,
    multiline: true,
    cursor: null,
    spellcheck: false,
    tabindex: 0,
    allowEmoji: false,

    bodyElement: Ember.computed(function () {
      return document.body;
    }),

    components: Ember.computed('_content', function () {
      var content = this.get('_content') || [];
      return content.filter(function (x) {
        return x.type === 1;
      });
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      // rebind
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = events[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var x = _step2.value;

          if (this["_" + x]) {
            this["_" + x] = this["_" + x].bind(this);
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      this._selectionChange = this._selectionChange.bind(this);
      this._focusByTab = this._focusByTab.bind(this);

      this._undoStack = [];
      this._redoStack = [];

      this._ignoreFocusBlur = 0;

      this._reqAnimationFrame = null;
      this._domObserver = new MutationObserver(function (mut) {
        var version = _this._domObserverVersion;
        if (mut.some(function (x) {
          return x.type === 'characterData' || x.type === 'attributes' || x.addedNodes.length || x.removedNodes.length;
        })) {
          // requestAnimationFrame because IE/Edge sucks
          // Edge doesn't update the window.getSelection ..  before mutation callback
          if (!_this._reqAnimationFrame) {
            _this._reqAnimationFrame = true;

            var r1 = void 0,
                r2 = void 0;
            var count = 0;
            var selectionC = function selectionC() {
              if ('setImmediate' in window && r1) {
                window.clearImmediate(r1);
              }
              if (r2) {
                cancelAnimationFrame(r2);
              }
              document.removeEventListener('selectionchange', selectionC);
              // call the stuff .. :/
              if (count++ === 0) {
                try {
                  //if (version === this._domObserverVersion) {
                  _this._mutation(mut);
                  //} else {
                  //  console.log("changes filtered");
                  //}
                } finally {
                  _this._reqAnimationFrame = null;
                }
              }
            };
            document.addEventListener('selectionchange', selectionC);
            if ('setImmediate' in window) {
              r1 = window.setImmediate(selectionC);
            }
            r2 = requestAnimationFrame(selectionC);
          }
        }
      });

      if (!this.get('_content')) {
        this.set('_content', []);
      }
    },
    willInsertElement: function willInsertElement() {
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = events[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var x = _step3.value;

          if (this["_" + x]) {
            this.element.addEventListener(x, this["_" + x]);
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      this._render();
      this._installObserver();

      document.addEventListener('selectionchange', this._selectionChange);
    },
    willDestroyElement: function willDestroyElement() {
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = events[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var x = _step4.value;

          if (this["_" + x]) {
            this.element.removeEventListener(x, this["_" + x]);
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      this._uninstallObserver();

      document.removeEventListener('selectionchange', this._selectionChange);
    },
    _installObserver: function _installObserver() {
      if (!this._domObserverConnected) {
        this._domObserverVersion = (this._domObserverVersion | 0) + 1;
        this._domObserverConnected = true;
        this._domObserver.observe(this.element, {
          childList: true,
          subtree: true,
          characterData: true,
          attributes: true,
          attributeFilter: ['class', 'data-value'] });
      } else {
        //debugger;
      }
    },
    _uninstallObserver: function _uninstallObserver() {
      this._domObserverVersion = (this._domObserverVersion | 0) + 1;
      if (this._domObserverConnected) {
        this._domObserverConnected = false;
        this._uninstallObserver();
      } else {
        //debugger;
      }
    },
    _selectionChange: function _selectionChange() {
      Ember.run.scheduleOnce('afterRender', this, this._selectionChangeReal);
    },
    _selectionChangeReal: function _selectionChangeReal() {
      var cur = this._cursor();
      if (!cur || !this.element.contains(cur.startContainer)) {
        this.set('cursor', null);
        this.set('cursorOffset', null);
        return;
      }
      this.set('cursor', cur);
      try {
        this._currentselectionOffset = this._cursorOffset();
      } catch (e) {
        /* IGNORE */
      }
      if (!this._currentselectionOffset) {
        this.set('cursorOffset', null);
      } else {
        this.set('cursorOffset', {
          start: this._currentselectionOffset[0],
          end: this._currentselectionOffset[1]
        });
      }
    },
    _undo: function _undo() {
      if (!this._undoStack.length) {
        return;
      }

      this._uninstallObserver();
      try {
        var _undoStack$pop = this._undoStack.pop(),
            cursor = _undoStack$pop.cursor,
            content = _undoStack$pop.content;

        this._redoStack.push({
          cursor: this._cursorOffset(),
          content: (this.get('content') || []).map(function (x) {
            return JSON.parse(x.serialize());
          })
        });

        this.set('content', content.map(function (x) {
          return _contentObject.default.create(x);
        }));

        if (cursor) {
          try {
            this._setCursorOffset(cursor);
          } catch (e) {
            e;
          }
        }
      } finally {
        this._installObserver();
      }
    },
    _redo: function _redo() {
      if (!this._redoStack.length) {
        return;
      }

      this._uninstallObserver();
      try {
        var _redoStack$pop = this._redoStack.pop(),
            cursor = _redoStack$pop.cursor,
            content = _redoStack$pop.content;

        this._undoStack.push({
          cursor: this._cursorOffset(),
          content: (this.get('content') || []).map(function (x) {
            return JSON.parse(x.serialize());
          })
        });

        this.set('content', content.map(function (x) {
          return _contentObject.default.create(x);
        }));
        if (cursor) {
          try {
            this._setCursorOffset(cursor);
          } catch (e) {
            e;
          }
        }
      } finally {
        this._installObserver();
      }
    },
    _mutation: function _mutation(mut) {
      var _this2 = this;

      if (!this.element) {
        // nothing todo
        return;
      }
      //debugger;
      var getParent = function getParent(el) {
        while (el && el.parentElement !== _this2.element) {
          el = el.parentElement;
        }
        return el;
      };
      var isComponent = function isComponent(el) {
        var p = getParent(el);
        return p && p.dataset && p.dataset.type == 1;
      };
      var mutTreeChanges = [].concat(_toConsumableArray(new Set(mut.reduce(function (p, c) {
        p.push.apply(p, _toConsumableArray(c.addedNodes));return p;
      }, []))));
      if (!mutTreeChanges.length) {
        mutTreeChanges = [].concat(_toConsumableArray(new Set(mut.reduce(function (p, c) {
          p.push.apply(p, _toConsumableArray(c.removedNodes));return p;
        }, []))));
      }
      var subTreeChangesInComponentsOnly = mutTreeChanges.length && mutTreeChanges.every(function (x) {
        var isFake = x.__fakeNewLine && x.nodeValue === '\n';
        return isFake && isComponent(x);
      });
      mutTreeChanges = mutTreeChanges.filter(function (x) {
        return !isComponent(x);
      }); // <- remove components
      var mutAttrChanges = [].concat(_toConsumableArray(new Set(mut.filter(function (x) {
        return x.type === 'attributes' && x.target != _this2.element;
      }).reduce(function (p, c) {
        p.push(c.target);return p;
      }, [])))).filter(function (x) {
        var isFake = x.__fakeNewLine && x.nodeValue === '\n';
        return !isFake && !isComponent(x) || x == getParent(x);
      });
      var mutCharChanges = [].concat(_toConsumableArray(new Set(mut.filter(function (x) {
        return x.type === 'characterData';
      }).reduce(function (p, c) {
        p.push(c.target);return p;
      }, [])))).filter(function (x) {
        var isFake = x.__fakeNewLine && x.nodeValue === '\n';
        return !isFake && !isComponent(x);
      });

      if (subTreeChangesInComponentsOnly && !mutAttrChanges.length && !mutCharChanges.length) {
        // ignore these changes
        return;
      }

      // removed nodes can't be checked :/ would need the old dom..

      if (!mutTreeChanges.length && !mutAttrChanges.length && !mutCharChanges.length) {
        // nothing changed ??
        return;
      }

      // store data for undo
      var sContent = {
        cursor: this._currentselectionOffset,
        content: (this.get('content') || []).filter(function (x) {
          return x.component || x.value;
        }).map(function (x) {
          return JSON.parse(x.serialize());
        })
      };

      this._uninstallObserver();
      this._ignoreFocusBlur += 1;

      [].concat(_toConsumableArray(this.element.childNodes)).filter(function (x) {
        return !x.dataset || !x.dataset.component;
      }).forEach(function (x) {
        return x.normalize();
      });

      var selectionOffset = this._cursorOffset();

      var nodes = [].concat(_toConsumableArray(this.element.childNodes));
      var content = [];
      var oldContent = this.get('_content') || [];

      var used = [];
      for (var i = 0; i < nodes.length; ++i) {
        var nn = nodes[i];
        var eNodes = (0, _extractTextContent.default)(nn, this.get('multiline'));
        for (var j = 0; j < eNodes.length; ++j) {
          var n = eNodes[j];
          var type = parseInt(n.dataset && n.dataset.type || '0');
          var component = n.dataset && n.dataset.component;
          var cls = (n.getAttribute && n.getAttribute('class') || '').split(' ').filter(function (x) {
            return x !== 'ember-view';
          }).sort().filter(function (x) {
            return x;
          });
          var value = n.firstChild && n.firstChild.nodeValue || '';
          var dataValue = n.dataset && n.dataset.value || null;
          var index = n.dataset && n.dataset.index || null;

          var obj = !used[index] && oldContent[index] || _contentObject.default.create();
          used[index] = obj;

          if (type === 0) {
            // text mode
            obj.setText(cls, value);
          } else if (type === 1) {
            obj.setComponent(component, cls, dataValue);
          }
          content.push(obj);
        }
      }

      // merge
      content = content.reduce(function (p, c, i) {
        if (i === 0) {
          p.push(c);
          return p;
        }
        var prev = p[p.length - 1];
        if (prev.equal(c)) {
          prev.append(c);
          return p;
        }
        p.push(c);
        return p;
      }, []);

      var allowEmoji = this.get('allowEmoji');
      if (!allowEmoji) {
        content = content.map(function (x) {
          x.set('value', x.value && x.value.replace(_emojiRegex.default, ''));
          return x;
        });
      }
      content = content.filter(function (x) {
        return x.component || x.value;
      });

      // remove last new line
      if (content.length) {
        var lastContent = content[content.length - 1];
        if (lastContent.value.endsWith('\n')) {
          lastContent.set('value', lastContent.value.substr(0, lastContent.value.length - 1));
        }
      }

      var currentC = (this.get('content') || []).filter(function (x) {
        return x.component || x.value;
      }).map(function (x) {
        return JSON.parse(x.serialize());
      });
      if (JSON.stringify(currentC) != JSON.stringify(sContent.content)) {
        this._undoStack.push(sContent);
        this._redoStack = [];
      }

      // this doesn't really work .. outside wants a "set" on _content
      // check for changes..
      //const newC = content.map(x => JSON.parse(x.serialize()));
      //if (JSON.stringify(currentC) != JSON.stringify(newC)) {
      // set new content
      this.set('_content', content);

      // restore cursor
      if (selectionOffset) {
        try {
          this._setCursorOffset(selectionOffset);
        } catch (e) {
          e;
        }
      }
      //}

      this._installObserver();
      this._ignoreFocusBlur -= 1;
    },
    willRender: function willRender() {
      this._uninstallObserver();
    },
    didRender: function didRender() {
      this._installObserver();
    },


    // wrap content for outside..
    __contentHelper: function __contentHelper() {
      var _this3 = this;

      var content = (this.get('content') || []).filter(function (x) {
        return x.value || x.component;
      });
      // reuse _span !
      return content.map(function (x, i) {
        if (x && !x._span && _this3.__content && _this3.__content[i] && _this3.__content[i]._span) {
          x._span = _this3.__content[i]._span;
        }
        return x;
      });
      return content;
    },

    _content: Ember.computed('content', {
      get: function get(k) {
        k;
        return this.__contentHelper();
      },
      set: function set(k, content) {
        this.__content = content;
        this.set('content', content);
        return this.__contentHelper();
      }
    }),

    _render: function _render() {
      this._uninstallObserver();

      this._ignoreFocusBlur += 1;
      //const nodes = [...this.element.childNodes];
      var content = this.get('_content').filter(function (x) {
        return x.value || x.component;
      }); // normally empty values get removed in _mutation

      var tmp = [];
      for (var i = 0; i < content.length; ++i) {
        var c = content[i];
        var span = c.createElement();
        span.dataset.index = i;
        tmp.push(span);
      }

      //check if all is same
      var nodes = [].concat(_toConsumableArray(this.element.childNodes));
      if (!arraysEqual(nodes.filter(function (x) {
        return !x.__fakeNewLine;
      }), tmp)) {
        var _loop = function _loop(_i) {
          var oldN = nodes[_i];
          if (!tmp.find(function (x) {
            return oldN === x;
          })) {
            oldN.remove();
          }
        };

        // remove all wrong nodes
        for (var _i = 0; _i < nodes.length; ++_i) {
          _loop(_i);
        }
        // add new nodes
        nodes = [].concat(_toConsumableArray(this.element.childNodes));
        for (var _i2 = 0; _i2 < tmp.length; ++_i2) {
          var newN = tmp[_i2];

          if (nodes[_i2] == newN) {
            // do nothing
            continue;
          }

          // move to right position
          this.element.insertBefore(newN, nodes[_i2] && nodes[_i2].nextSibling);
          nodes = [].concat(_toConsumableArray(this.element.childNodes));
        }
      } else {
        // remove unwanted stuff
        nodes.filter(function (x) {
          return !x.__fakeNewLine && !content.some(function (y) {
            return y._span === x;
          });
        }).forEach(function (x) {
          return x.remove();
        });
      }
      var nl = nodes.find(function (x) {
        return x.__fakeNewLine;
      }) || document.createTextNode('\n');
      if (nl.nodeValue != '\n') {
        nl.nodeValue = '\n';
      }
      nl.__fakeNewLine = true;
      if (this.element.lastChild != nl) {
        this.element.appendChild(nl); //add newline
      }

      this._ignoreFocusBlur -= 1;

      this._installObserver();
    },

    _updateRender: Ember.observer('_content', function () {
      this._render();
    }),

    _expandSelection: function _expandSelection(selection) {
      if (selection.collapsed) {
        var text = textContent(this.element, this.get('multiline')).replace(/\s/g, ' ');

        this._cursor(selection);
        var of = this._cursorOffset();

        if (of) {
          var start = text.lastIndexOf(' ', of[0] - 1);
          var end = text.indexOf(' ', of[0]);
          if (start < 0) {
            start = 0;
          }
          if (end < 0) {
            end = text.length;
          }

          start = Math.min(start + 1, end);

          this._setCursorOffset([start, end], true);
          selection = this._cursor();
        }
      }

      return selection;
    },
    _component: function _component(selection, componentName, data) {
      var _this4 = this;

      this._ignoreFocusBlur += 1;
      try {
        var selectionOffset = this._cursorOffset();

        // make sure we have the current document
        //this._mutation();

        selection = this._cursor();
        selection = this._expandSelection(selection);
        this._cursor(selection);

        var content = Ember.A([].concat(_toConsumableArray(this.get('_content'))));
        var s1 = parseInt(selection.startContainer.dataset.index);
        var s2 = parseInt(selection.endContainer.dataset.index);

        // extract and combine everything and then replace with
        var comps = [];
        var cpyContent = [].concat(_toConsumableArray(content));
        for (var i = s2; i >= s1; --i) {
          var c = cpyContent[i];
          if (!c) {
            continue;
          }
          if (c.type === 1) {
            throw "Can't set component over component !";
          }
        }
        for (var _i3 = s2; _i3 >= s1; --_i3) {
          var _c = cpyContent[_i3];
          if (!_c) {
            continue;
          }

          if (_i3 === s1 && _i3 === s2) {
            var _c$split = _c.split(selection.startOffset, selection.endOffset),
                _c$split2 = _slicedToArray(_c$split, 2),
                prev = _c$split2[0],
                after = _c$split2[1];

            content.replace(content.indexOf(_c), 1, [prev, _c, after]);
          } else if (_i3 === s1) {
            var _c$split3 = _c.split(selection.startOffset),
                _c$split4 = _slicedToArray(_c$split3, 2),
                _prev = _c$split4[0],
                _after = _c$split4[1];

            content.replace(content.indexOf(_c), 1, [_prev, _c, _after]);
          } else if (_i3 === s2) {
            var _c$split5 = _c.split(0, selection.endOffset),
                _c$split6 = _slicedToArray(_c$split5, 2),
                _prev2 = _c$split6[0],
                _after2 = _c$split6[1];

            content.replace(content.indexOf(_c), 1, [_prev2, _c, _after2]);
          }
          comps.push(_c);
        }

        if (!comps.length) {
          // no component found
          return;
        }

        // remove to be merged
        var comp = comps[0];
        content = content.filter(function (x) {
          return x === comp || !comps.includes(function (y) {
            return y === x;
          });
        });

        var value = comps.reduce(function (p, c) {
          return p + (c.value || '');
        }, '');
        comp.setComponent(componentName, comp.class, JSON.stringify(Object.assign({}, data, { value: value })));

        // merge equals
        content = content.filter(function (x) {
          return x && x.value;
        }).reduce(function (p, c, i) {
          if (i === 0) {
            p.push(c);
            return p;
          }
          var prev = p[p.length - 1];
          if (prev.equal(c)) {
            prev.append(c);
            return p;
          }
          p.push(c);
          return p;
        }, []);

        //TODO selection offset .... changes ! alot

        var index = content.indexOf(comp);
        // render the new "content"
        this.set('_content', content);

        // get new content:
        var span = (this.get('_content')[index] || {})._span;

        Ember.run.schedule('afterRender', function () {
          if (span) {
            var _span = span.querySelector('[contenteditable]:not([contenteditable="false"])') || span;
            _this4._setCursor(_span, 0, _span, 1);
          }
        });
        //this._setCursorOffset(selectionOffset, true);
      } finally {
        this._ignoreFocusBlur -= 1;
      }
    },
    _class: function _class(selection, cls) {
      this._ignoreFocusBlur += 1;
      try {
        if (cls) {
          if (typeof cls === 'string' || cls instanceof String) {
            cls = cls.split(' ');
          } else {
            cls = [].concat(_toConsumableArray(cls)).sort();
          }
        }

        var selectionOffset = this._cursorOffset();

        // make sure we have the current document
        //this._mutation();

        selection = this._cursor();
        selection = this._expandSelection(selection);
        this._cursor(selection);

        var content = Ember.A([].concat(_toConsumableArray(this.get('_content'))));
        var s1 = parseInt(selection.startContainer.dataset.index);
        var s2 = parseInt(selection.endContainer.dataset.index);

        var cpyContent = [].concat(_toConsumableArray(content));
        for (var i = s2; i >= s1; --i) {
          var c = cpyContent[i];
          if (!c) {
            continue;
          }

          if (c.type === 1) {
            c.set('class', cls);
            continue;
          }

          if (i === s1 && i === s2) {
            var _c$split7 = c.split(selection.startOffset, selection.endOffset),
                _c$split8 = _slicedToArray(_c$split7, 2),
                prev = _c$split8[0],
                after = _c$split8[1];

            content.replace(content.indexOf(c), 1, [prev, c, after]);
          } else if (i === s1) {
            var _c$split9 = c.split(selection.startOffset),
                _c$split10 = _slicedToArray(_c$split9, 2),
                _prev3 = _c$split10[0],
                _after3 = _c$split10[1];

            content.replace(content.indexOf(c), 1, [_prev3, c, _after3]);
          } else if (i === s2) {
            var _c$split11 = c.split(0, selection.endOffset),
                _c$split12 = _slicedToArray(_c$split11, 2),
                _prev4 = _c$split12[0],
                _after4 = _c$split12[1];

            content.replace(content.indexOf(c), 1, [_prev4, c, _after4]);
          }
          c.set('class', cls);
        }

        content = content.filter(function (x) {
          return x && x.value;
        }).reduce(function (p, c, i) {
          if (i === 0) {
            p.push(c);
            return p;
          }
          var prev = p[p.length - 1];
          if (prev.equal(c)) {
            prev.append(c);
            return p;
          }
          p.push(c);
          return p;
        }, []);

        // render the new "content"
        this.set('_content', content);
        this._setCursorOffset(selectionOffset, true);
      } finally {
        this._ignoreFocusBlur -= 1;
      }
    },
    _cursorOffset: function _cursorOffset() {
      var selection = this._cursor(true);
      if (selection) {
        var m = this.get('multiline');
        var offsets = (0, _extractCharOffsets.default)(this.element, m);
        var c1 = offsets.find(function (x) {
          return x.node === selection.startContainer;
        });
        var c2 = offsets.find(function (x) {
          return x.node === selection.endContainer;
        });
        var offset1 = c1 && c1.textStart + selection.startOffset;
        var offset2 = c2 && c2.textStart + selection.endOffset;
        return [offset1, offset2];
      }
      return null;
    },
    _setCursorOffset: function _setCursorOffset(selectionOffset, force) {
      if (!selectionOffset || selectionOffset[0] === undefined || selectionOffset[1] === undefined) {
        return;
      }
      if (selectionOffset[0] === 0 && selectionOffset[1] >= Number.MAX_SAFE_INTEGER) {
        // just select all
        this._setCursor(this.element, 0, this.element, this.element.childNodes.length);
        return;
      }

      if (selectionOffset[0] >= Number.MAX_SAFE_INTEGER) {
        this._setCursor(this.element, this.element.childNodes.length, this.element, this.element.childNodes.length);
        return;
      }

      var m = this.get('multiline');
      var offsets = (0, _extractCharOffsets.default)(this.element, m);

      var c1 = offsets.find(function (x) {
        return x.node.nodeType === Node.TEXT_NODE && x.textEnd >= selectionOffset[0];
      });
      var c2 = offsets.find(function (x) {
        return x.node.nodeType === Node.TEXT_NODE && x.textEnd >= selectionOffset[1];
      });

      if (c1 && c2) {
        this._setCursor(c1.node, selectionOffset[0] - c1.textStart, c2.node, selectionOffset[1] - c2.textStart, force);
      }
    },
    _cursor: function _cursor(raw) {
      if (!this.element) {
        return null;
      }

      var selection = window.getSelection() || document.getSelection();

      if ((!this.element.contains(selection.anchorNode) || !this.element.contains(selection.focusNode)) && selection.anchorNode !== this.element && selection.focusNode !== this.element) {
        // not a child of this element.. ?
        return null;
      }

      var backwards = false;
      if (!selection.isCollapsed) {
        var range = document.createRange();
        range.setStart(selection.anchorNode, selection.anchorOffset);
        range.setEnd(selection.focusNode, selection.focusOffset);
        backwards = range.collapsed;
        range.detach();
      }

      if (selection.rangeCount < 1) {
        return null;
      }

      var tmp = {
        startContainer: !backwards ? selection.anchorNode : selection.focusNode,
        startOffset: !backwards ? selection.anchorOffset : selection.focusOffset,
        endContainer: !backwards ? selection.focusNode : selection.anchorNode,
        endOffset: !backwards ? selection.focusOffset : selection.anchorOffset,
        collapsed: selection.isCollapsed
      };

      tmp.boundingBox = function () {
        var range = document.createRange();
        range.setStart(tmp.startContainer, tmp.startOffset);
        range.setEnd(tmp.startContainer, tmp.startOffset);
        var t = range.getBoundingClientRect();
        range.detach();
        return t;
      }();

      // move selection down to the lowest possible element !
      while (tmp.startContainer.childNodes.length) {
        tmp.startContainer = tmp.startContainer.childNodes[Math.max(0, tmp.startOffset - 1)];
        tmp.startOffset = tmp.startContainer.nodeValue ? tmp.startOffset === 0 ? 0 : tmp.startContainer.nodeValue.length : tmp.startOffset === 0 ? 0 : 1;
      }
      while (tmp.endContainer.childNodes.length) {
        tmp.endContainer = tmp.endContainer.childNodes[Math.max(0, tmp.endOffset - 1)];
        tmp.endOffset = tmp.endContainer.nodeValue ? tmp.endOffset === 0 ? 0 : tmp.endContainer.nodeValue.length : tmp.endOffset === 0 ? 0 : 1;
      }

      if (!raw) {
        tmp.setClass = this._class.bind(this, tmp);
        tmp.setComponent = this._component.bind(this, tmp);

        while (tmp.startContainer.nodeType === 3) {
          tmp.startContainer = tmp.startContainer.parentNode;
        }
        while (tmp.endContainer.nodeType === 3) {
          tmp.endContainer = tmp.endContainer.parentNode;
        }
      }

      Object.freeze(tmp);
      return tmp;
    },
    _setCursor: function _setCursor(startElement, startIndex, endElement, endIndex, force) {
      if (!force && document.activeElement) {
        if (!startElement || this.get('notContenteditable') || !document.activeElement || document.activeElement !== this.element || !document.activeElement.contains(this.element)) {
          // do nothing, ignore this change
          return;
        }
      }
      var selection = window.getSelection() || document.getSelection();
      var new_range = document.createRange();
      try {
        new_range.setStart(startElement, startIndex);
        new_range.setEnd(endElement ? endElement : startElement, endElement ? endIndex : startIndex);
        selection.removeAllRanges();
        selection.addRange(new_range);
      } catch (e) {
        /* IGNORE */
      }
    },
    _keypress: function _keypress(e) {
      if (e.keyCode === 13) {
        try {
          if (document.execCommand('insertHTML', false, '<br/>')) {
            e.preventDefault();
          }
        } catch (e) {
          /* IGNORE DO DEFAULT */
        }
        return;
      }
    },
    _keydown: function _keydown(e) {
      if (e.ctrlKey && e.keyCode === 90) {
        e.preventDefault();
        if (e.shiftKey) {
          this._redo();
        } else {
          this._undo();
        }
        return;
      } else if (e.ctrlKey && e.keyCode == 89) {
        e.preventDefault();
        this._redo();
        return;
      }
      if (e.keyCode === 8) {
        var tmp = this._cursor();
        if (tmp && tmp.startOffset === 0 && tmp.startContainer === tmp.endContainer && tmp.startOffset === tmp.endOffset) {
          // select before
          var node = tmp.startContainer.previousSibling;
          if (node && node.dataset && node.dataset.static) {
            node = node.previousSibling;
            this._setCursor(node, 1, node, 1);
            e.preventDefault();
            return;
          }
        }
      }
      if (!this.get('multiline') && e.keyCode === 13 /* new line */) {
          /* we don't allow new lines */
          e.preventDefault();
          return;
        }
    },
    _focusByTab: function _focusByTab(e) {
      e;
      if (!this.get('multiline')) {
        // if single line -> select all
        this._setCursorOffset([0, Number.MAX_SAFE_INTEGER]);
      } else if (this._lastCursorOffset) {
        // if not single line and we have a old offset -> select old
        this._setCursorOffset(this._lastCursorOffset);
      } else {
        // if not single line -> select last possible offset
        this._setCursorOffset([Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]);
      }
    },
    _focus: function _focus() {
      if (this._cursor()) {
        // cursor is already set...
        return;
      }
      if (this._ignoreFocusBlur) {
        return;
      }
      if (!this._focusByMouse) {
        this._focusByTab();
      }
      var content = (this.get('content') || []).map(function (x) {
        return JSON.parse(x.serialize());
      });
      this._contentBeforeBlur = JSON.stringify(content);
      _sendAction.default.apply(undefined, [this, 'onfocus'].concat(Array.prototype.slice.call(arguments)));
    },
    _blur: function _blur() {
      if (this._ignoreFocusBlur) {
        return;
      }
      try {
        var content = (this.get('content') || []).map(function (x) {
          return JSON.parse(x.serialize());
        });
        var different = JSON.stringify(content) !== this._contentBeforeBlur;
        this._contentBeforeBlur = null;
        if (different) {
          this._change.apply(this, arguments);
        }
        this.element.scrollTop = 0;
        this.element.scrollLeft = 0;
        _sendAction.default.apply(undefined, [this, 'onblur'].concat(Array.prototype.slice.call(arguments)));
      } finally {
        this._lastCursorOffset = this._cursorOffset();
      }
    },
    _change: function _change() {
      _sendAction.default.apply(undefined, [this, 'onchange'].concat(Array.prototype.slice.call(arguments)));
    },
    _mousedown: function _mousedown() {
      var _this5 = this;

      this._focusByMouse = true;
      requestAnimationFrame(function () {
        return _this5._focusByMouse = false;
      });
    }
  });
});