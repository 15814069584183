define("lt-page-editor/components/blocks/image/lt-page-editor-image/component", ["exports", "lt-page-editor/components/blocks/image/lt-page-editor-image/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    helper: Ember.computed('value.layout', 'value.{size,showDesc}', 'value.data.[]', 'showEdit', function () {
      var l = Ember.get(this, 'value.layout') || 1;
      var helper = {
        layout: l,
        images: l == 5 ? null : Ember.get(this, 'value.data'),
        map: l == 5 ? Ember.get(this, 'value.data') : null,
        size: Ember.get(this, 'value.size') | 0,
        setSize: Ember.set.bind(null, this, 'value.size'),
        showDesc: Ember.get(this, 'value.showDesc') | 0,
        setShowDesc: Ember.set.bind(null, this, 'value.showDesc'),
        setData: this._setData.bind(this),
        showEdit: Ember.get(this, 'showEdit'),
        openEdit: Ember.set.bind(null, this, 'showEdit', true),
        closeEdit: Ember.set.bind(null, this, 'showEdit', false)
      };
      return helper;
    }),
    _setData: function _setData(layout, data, map) {
      Ember.set(this, 'value.layout', layout);

      if (layout == 5) {
        Ember.set(this, 'value.data', map);
      } else {
        Ember.set(this, 'value.data', data);
      }
    },
    _value: Ember.computed('value.data', function () {
      var value = Ember.get(this, 'value');
      return value.data;
    })
  });

  _exports.default = _default;
});