define("ui-contenteditable/templates/components/ui-contenteditable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4VlpCibq",
    "block": "{\"symbols\":[\"c\",\"&default\"],\"statements\":[[4,\"in-element\",[[25,[\"bodyElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[4,\"each\",[[25,[\"components\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[4,\"in-element\",[[24,1,[\"_span\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:1%\",null]],{\"statements\":[[15,2,[[24,1,[\"component\"]],[24,1,[\"value\"]]]]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-contenteditable/templates/components/ui-contenteditable.hbs"
    }
  });

  _exports.default = _default;
});