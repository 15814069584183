define("ui-page-editor-text/components/ui-page-editor-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aRcxwXZU",
    "block": "{\"symbols\":[\"componentName\",\"tv\",\"v\",\"value\",\"&default\"],\"statements\":[[4,\"ui-textarea-richtext\",null,[[\"cursor\",\"value\"],[[25,[\"_cursor\"]],[25,[\"_value\"]]]],{\"statements\":[[4,\"with\",[[29,\"get\",[[25,[\"widgets\"]],[24,1,[]]],null]],null,{\"statements\":[[4,\"ui-page-editor-text-widget\",null,[[\"tv\"],[[24,2,[]]]],{\"statements\":[[1,[29,\"component\",[[24,3,[\"component\"]]],[[\"value\"],[[24,4,[]]]]],false]],\"parameters\":[4]},null]],\"parameters\":[3]},{\"statements\":[[7,\"span\"],[11,\"class\",\"error-editor-block\"],[9],[0,\"widget \"],[7,\"code\"],[9],[1,[24,1,[]],false],[10],[0,\" not found\"],[10]],\"parameters\":[]}]],\"parameters\":[1,2]},null],[15,5,[[25,[\"helper\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor-text/components/ui-page-editor-text/template.hbs"
    }
  });

  _exports.default = _default;
});