define("ui-input/templates/components/ui-textarea-richtext", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yy/9zqai",
    "block": "{\"symbols\":[\"componentName\",\"value\",\"&default\"],\"statements\":[[4,\"ui-contenteditable\",null,[[\"multiline\",\"content\",\"cursor\"],[[25,[\"multiline\"]],[25,[\"content\"]],[25,[\"cursor\"]]]],{\"statements\":[[15,3,[[24,1,[]],[24,2,[]]]]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-input/templates/components/ui-textarea-richtext.hbs"
    }
  });

  _exports.default = _default;
});