define("lt-page-editor/components/blocks/hr/lt-page-editor-hr-readonly/component", ["exports", "lt-page-editor/components/blocks/hr/lt-page-editor-hr-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['type'],
    type: Ember.computed('value.layout', function () {
      return "layout-".concat(Ember.get(this, 'value.layout') | 0);
    })
  });

  _exports.default = _default;
});