define("ui-page-editor-text/utils/track-changes-object", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TrackChangesObject = Ember.Object.extend({
    _notifyChanges: null,
    _content: null,

    _createProxyArray: function _createProxyArray(res) {
      var _this = this;

      return Ember.ArrayProxy.create({
        content: res.map(function (x) {
          if (Array.isArray(x)) {
            return _this._createProxyArray(x);
          }
          if (x instanceof Object) {
            return TrackChangesObject.create({
              _notifyChanges: _this._notifyChanges,
              _content: x
            });
          }
          return x;
        }),
        arrayContentDidChange: function arrayContentDidChange() {
          this._super.apply(this, arguments);
          this._notifyChanges();
        }
      });
    },


    unknownProperty: function unknownProperty(key) {
      var res = Ember.get(this, "_content." + key);

      if (Array.isArray(res)) {
        return this._createProxyArray(res);
      }
      if (res instanceof Object) {
        return TrackChangesObject.create({
          _notifyChanges: this._notifyChanges,
          _content: res
        });
      }

      return res;
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      Ember.set(this, "_content." + key, value);
      this._notifyChanges();
      return Ember.get(this, "_content." + key);
    }
  });

  exports.default = TrackChangesObject;
  var computedTrackChanges = exports.computedTrackChanges = function computedTrackChanges(key, callback, getter) {
    var tracker = function tracker() {
      var _this2 = this;

      var _content = getter ? getter.call(this, key) : Ember.get(this, key);
      return TrackChangesObject.create({
        _content: _content,
        _notifyChanges: function _notifyChanges() {
          callback.call(_this2, _content);
        }
      });
    };
    return Ember.computed(key, {
      get: function get(k) {
        k;
        return tracker.call(this);
      },
      set: function set(k, v) {
        k;
        // property did change..
        callback.call(this, v);
        return tracker.call(this);
      }
    });
  };
});