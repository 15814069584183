define("ui-page-editor/components/ui-page-editor-add-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E+G7UXRu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],\"onmousedown\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"withoutButtonRow\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[12,\"id\",[30,[[29,\"concat\",[[25,[\"elementId\"]],\"-btn\"],null]]]],[11,\"title\",\"Neuer Block\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"openPopover2\"],null]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"add\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[7,\"button\"],[12,\"id\",[30,[[29,\"concat\",[[25,[\"elementId\"]],\"-btn\"],null]]]],[11,\"title\",\"Neuer Block\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"openPopover2\"],null]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"add\"],[10],[10],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[25,[\"showPopover\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\" \"],[0,\"\\n\"],[4,\"ember-tether\",null,[[\"target\",\"targetAttachment\",\"attachment\",\"optimizations\"],[[29,\"concat\",[\"#\",[25,[\"elementId\"]],\"-btn\"],null],\"top right\",\"top left\",[29,\"hash\",null,[[\"gpu\"],[false]]]]],{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"ui-page-editor-popover\",null,null,{\"statements\":[[0,\"            \"],[7,\"div\"],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],\"onmousedown\"],null]],[9],[0,\"\\n              \"],[15,1,[[29,\"action\",[[24,0,[]],\"addBlock\"],null]]],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor/components/ui-page-editor-add-block/template.hbs"
    }
  });

  _exports.default = _default;
});