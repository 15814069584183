define("ui-page-editor/components/ui-page-editor-wysiwyg/component", ["exports", "ui-page-editor/components/ui-page-editor-wysiwyg/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    _value: Ember.computed('value', {
      get: function get(k) {
        var v = Ember.get(this, 'value');
        if (!v) {
          return Ember.A([]);
        }
        if (Ember.Array.detect(v)) {
          return v;
        }
        return Ember.A(v);
      },
      set: function set(k, v) {
        return Ember.set(this, 'value', v);
      }
    }),
    obs_value: Ember.observer('_value.[]', function () {
      var v1 = Ember.get(this, 'value');
      var v2 = Ember.get(this, '_value');
      if (v1 !== v2) {
        Ember.set(this, 'value', v2);
      }
    }),
    tagName: 'ui-wysiwyg',
    layout: _template.default,
    columns: 8,
    columnWidth: '128px',
    columnMaxWidth: null,
    columnMinWidth: null,
    _style: Ember.computed('elementId', 'columns', 'columnWidth', 'columnMaxWidth', 'columnMinWidth', function () {
      var columns = this.get('columns');
      var columnWidth = this.get('columnWidth');
      var hasMinMax = this.get('columnMinWidth') || this.get('columnMaxWidth');
      var columnMinWidth = hasMinMax ? this.get('columnMinWidth') : columnWidth;
      var columnMaxWidth = hasMinMax ? this.get('columnMaxWidth') : columnWidth;
      var elementId = this.get('elementId');
      return "." + elementId + "-ui-wysiwyg-col {\n  width: calc(" + columns + " * " + columnWidth + ");\n  min-width: calc(" + columns + " * " + columnMinWidth + ");\n  max-width: calc(" + columns + " * " + columnMaxWidth + ");\n}\n" + Array.from(new Array(columns + 3), function (_, i) {
        var j = i - 2;
        if (j < 0) {
          j = columns;
        }
        return "." + elementId + "-ui-wysiwyg-col[data-w=\"" + (i - 2) + "\"] {\n  width: calc(" + columns + " * " + columnWidth + ");\n  min-width: calc(" + j + " * " + columnMinWidth + ");\n  max-width: calc(" + j + " * " + columnMaxWidth + ");\n}";
      }).join('\n') + ("\n." + elementId + "-ui-wysiwyg-row > .content {\n  width: calc(" + columns + " * " + columnWidth + ");\n  min-width: calc(" + columns + " * " + columnMinWidth + ");\n  max-width: calc(" + columns + " * " + columnMaxWidth + ");\n}");
    }),
    modelGuid: Ember.computed('model', function () {
      return Ember.guidFor(this.get('model'));
    }),
    modelBlocks: Ember.computed('model', function () {
      var _this = this;

      var singleList = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get('model')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var s = _step.value;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = s.data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var b = _step2.value;

              singleList.push(b);
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return singleList.map(function (x) {
        return Object.assign({ guidFor: x.isPlaceholder ? x.index : Ember.guidFor(x.data), hideBar: Ember.get(_this, "parent.blocks." + x.data.component + ".hideBar") }, x);
      });
    }),
    model: Ember.computed('value.[]', 'value.@each.width', 'selectedIndex', 'columns', 'withoutSections', function () {
      var _this2 = this;

      var value = this.get('_value');
      var sIndex = this.get('selectedIndex');
      var columns = this.get('columns');
      var withSections = !this.get('withoutSections');

      var pos = 0;
      var currentSection = {
        isComplete: true,
        data: []
      };
      var prevBlock = null;
      var result = [currentSection];
      var forceNewSection = false;
      for (var i = 0; i < value.length; ++i) {
        var block = value[i];
        var width = block.width < 0 ? columns : block.width > columns ? columns : block.width;

        if (i === 0) {
          var _index = Ember.guidFor(this) + "col-" + i;
          currentSection.data.push({
            i: i,
            index: _index,
            selected: sIndex === _index,
            data: block,
            rowIndex: Math.floor(pos / columns),
            sectionIndex: result.length - 1,
            prev: prevBlock
          });
          var _nextBlock = currentSection.data[currentSection.data.length - 1];
          if (prevBlock) {
            prevBlock.next = _nextBlock;
          }
          prevBlock = _nextBlock;
          pos += width;
          continue;
        }

        var hasEnoughSpace = columns - (pos - Math.floor(pos / columns) * columns) >= width;
        currentSection.isComplete = hasEnoughSpace;
        if (!hasEnoughSpace) {
          // add a place holder !
          var nWidth = columns - (pos - Math.floor(pos / columns) * columns);
          var _index2 = Ember.guidFor(this) + "placeholder-" + (i - 1);
          /*currentSection.data.push({
            i: i - 1,
            index: index,
            selected: sIndex === index,
            isPlaceholder: true,
            rowIndex: Math.floor(pos / columns),
            sectionIndex: result.length - 1,
            data: {
              width: nWidth
            },
            prev: prevBlock
          });
          if (prevBlock) {
            prevBlock.nextPlaceholder = currentSection.data[currentSection.data.length - 1];
          }*/
          pos += nWidth;
        }

        if (withSections) {
          if (block.width === -1 || forceNewSection) {
            if (!forceNewSection) {
              currentSection.isComplete = Math.ceil(pos / columns) * columns === pos;
              if (!currentSection.isComplete) {
                var _index3 = Ember.guidFor(this) + "placeholder-" + (i - 1);
                /*currentSection.data.push({
                  i: i - 1,
                  index: index,
                  selected: sIndex === index,
                  isPlaceholder: true,
                  rowIndex: Math.floor(pos / columns),
                  sectionIndex: result.length - 1,
                  prev: prevBlock,
                  data: {
                    width: Math.ceil(pos / columns) * columns - pos
                  }
                });
                if (prevBlock) {
                  prevBlock.nextPlaceholder = currentSection.data[currentSection.data.length - 1];
                }*/
              } else {
                var _index4 = Ember.guidFor(this) + "placeholder-" + (i - 1);
                /*currentSection.data.push({
                  i: i - 1,
                  index: index,
                  selected: sIndex === index,
                  isPlaceholder: true,
                  rowIndex: Math.floor(pos / columns),
                  sectionIndex: result.length - 1,
                  prev: prevBlock,
                  data: {
                    width: columns
                  }
                });
                if (prevBlock) {
                  prevBlock.nextPlaceholder = currentSection.data[currentSection.data.length - 1];
                }*/
              }
            }
            forceNewSection = false;
            pos = 0;
            currentSection = {
              isComplete: true,
              data: []
            };
            result.push(currentSection);
          }
          if (block.width === -2) {
            forceNewSection = true;
            currentSection.isComplete = true;
            if (hasEnoughSpace) {
              var _index5 = Ember.guidFor(this) + "placeholder-" + (i - 1);
              /*currentSection.data.push({
                i: i - 1,
                index: index,
                selected: sIndex === index,
                isPlaceholder: true,
                rowIndex: Math.floor(pos / columns),
                sectionIndex: result.length - 1,
                prev: prevBlock,
                data: {
                  width: columns
                }
              });
              if (prevBlock) {
                prevBlock.nextPlaceholder = currentSection.data[currentSection.data.length - 1];
              }*/
              pos += columns;
            }
          }
        }

        // add to section
        var index = Ember.guidFor(this) + "col-" + i;
        currentSection.data.push({
          i: i,
          index: index,
          selected: sIndex === index,
          data: block,
          rowIndex: Math.floor(pos / columns),
          sectionIndex: result.length - 1,
          prev: prevBlock
        });
        var nextBlock = currentSection.data[currentSection.data.length - 1];
        if (prevBlock) {
          prevBlock.next = nextBlock;
        }
        prevBlock = nextBlock;
        pos += width;
      }

      currentSection.isComplete = Math.ceil(pos / columns) * columns === pos;
      if (!currentSection.isComplete) {
        var _i = value.length - 1;
        var _index6 = Ember.guidFor(this) + "placeholder-" + _i;
        /*
        currentSection.data.push({
          i: i,
          index: index,
          selected: sIndex === index,
          isPlaceholder: true,
          prev: prevBlock,
          data: {
            width: Math.ceil(pos / columns) * columns - pos
          }
        });
        if (prevBlock) {
          prevBlock.nextPlaceholder = currentSection.data[currentSection.data.length - 1];
        }
        */
      } else {
        var _i2 = value.length - 1;
        var _index7 = Ember.guidFor(this) + "placeholder-" + _i2;
        /*
        currentSection.data.push({
          i: i,
          index: index,
          selected: sIndex === index,
          isPlaceholder: true,
          prev: prevBlock,
          data: {
            width: columns
          }
        });
        if (prevBlock) {
          prevBlock.nextPlaceholder = currentSection.data[currentSection.data.length - 1];
        }
        */
      }
      if (value.length === 0) {
        var _index8 = Ember.guidFor(this) + "placeholder-0";
        currentSection.data.push({
          i: 0,
          index: _index8,
          selected: sIndex === _index8,
          isPlaceholder: true,
          data: {
            width: columns
          }
        });
      }

      var result2 = result.filter(function (x) {
        return x.data;
      }).map(function (x) {
        var min = x.data.reduce(function (v, c) {
          return Math.min(c.i, v);
        }, Number.POSITIVE_INFINITY);
        var max = x.data.reduce(function (v, c) {
          return Math.max(c.i, v);
        }, Number.NEGATIVE_INFINITY);
        return {
          isComplete: x.isComplete,
          index: Ember.guidFor(_this2) + "row-" + min + "-" + max,
          selected: sIndex === Ember.guidFor(_this2) + "row-" + min + "-" + max,
          data: x.data
        };
      });

      // add some properties for styling

      var _loop = function _loop(_i3) {
        var section = result2[_i3].data;
        if (result2[_i3].selected) {
          result2.filter(function (x, j) {
            return j < _i3;
          }).forEach(function (x) {
            x.beforeSelected = true;
          });
          return "break";
        }

        var _loop2 = function _loop2(s) {
          var block = section[s];
          if (block.selected) {
            result2[_i3].hasSelected = true;
            result2.filter(function (x, j) {
              return j < _i3;
            }).forEach(function (x) {
              x.beforeSelected = true;
            });
            section.filter(function (x) {
              return block.rowIndex <= x.rowIndex;
            }).forEach(function (x) {
              x.selectedInRow = true;
            });
            section.filter(function (x) {
              return block.rowIndex >= x.rowIndex;
            }).forEach(function (x) {
              x.selectedInRowBefore = true;
            });
            for (var j = 0; j < _i3; ++j) {
              result2[j].beforeSelected = true;
            }
            for (var _j = _i3 + 1; _j < result2.length; ++_j) {
              result2[_j].afterSelected = true;
            }
            for (var s2 = s + 1; s2 < section.length; ++s2) {
              var block2 = section[s2];
              if (block.rowIndex !== block2.rowIndex) {
                return {
                  v: "break|crazy_loop"
                };
              }
              block2.afterSelectedInRow = true;
            }
            return {
              v: "break|crazy_loop"
            };
          }
        };

        for (var s = 0; s < section.length; ++s) {
          var _ret2 = _loop2(s);

          if ((typeof _ret2 === "undefined" ? "undefined" : _typeof(_ret2)) === "object") return _ret2.v;
        }
      };

      crazy_loop: for (var _i3 = 0; _i3 < result2.length; ++_i3) {
        var _ret = _loop(_i3);

        switch (_ret) {
          case "break":
            break crazy_loop;

          case "break|crazy_loop":
            break crazy_loop;}
      }

      // add some stuff for drop-zones
      result2.forEach(function (section) {
        var lastRow = -1;
        section.data.forEach(function (block, bindex) {
          if (lastRow !== block.rowIndex) {
            if (bindex > 0) {
              var blockBefore = section.data[bindex - 1];

              blockBefore.addDropAfter = false;
              if (blockBefore.nextPlaceholder) {
                blockBefore.nextPlaceholder.addDropAfterRow = true;
              } else {
                blockBefore.addDropAfterRow = true;
              }
            }
            if (bindex == 0) {
              block.addDropBeforeRow = true;
            }
            if (!block.isPlaceholder && block.data.width > 0 && block.data.width !== columns) {
              block.addDropBefore = true;
            }
            lastRow = block.rowIndex;
          }
          if (block.data.width > 0) {
            block.addDropAfter = true;
          } else {
            if (block.nextPlaceholder) {
              block.nextPlaceholder.addDropAfterRow = true;
            } else {
              block.addDropAfterRow = true;
            }
          }
          if (bindex + 1 >= section.data.length) {
            block.addDropAfter = false;
            if (block.nextPlaceholder) {
              block.nextPlaceholder.addDropAfterRow = true;
            } else {
              block.addDropAfterRow = true;
            }
          }
        });
      });

      return result2;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._activateItem = this._activateItem.bind(this);
      this.addBlock = this.addBlock.bind(this);
      this.removeBlock = this.removeBlock.bind(this);
      this._focusIn = this._focusIn.bind(this);
      this._focusOut = this._focusOut.bind(this);
      this._afterFocus = this._afterFocus.bind(this);

      this._dndMime = "custom/ui-wysiwyg-col-" + Ember.guidFor(this);
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      document.addEventListener('focusin', this._focusIn);
      document.addEventListener('focusout', this._focusOut);
    },
    didInsertElement: function didInsertElement() {
      // never lose focus on toolbar ! (warum geht das nicht in willInsertElement ?)
      document.body.querySelector('#' + Ember.get(this, 'toolbarId')).addEventListener('mousedown', function (e) {
        var checkInput = function checkInput(el) {
          return el.tagName === 'UI-INPUT' || el.tagName === 'INPUT';
        };
        var el = e.target;
        while (el && !checkInput(el)) {
          el = el.parentElement;
        }
        if (el && checkInput(el)) {
          return;
        }
        e.preventDefault();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('focusin', this._focusIn);
      document.removeEventListener('focusout', this._focusOut);
    },
    didRender: function didRender() {
      var area = document.body.querySelector('#' + Ember.get(this, 'toolbarId'));
      if (area) {
        var elements = [].concat(_toConsumableArray(area.children)).map(function (x) {
          return {
            tagName: x.tagName, order: x.dataset && x.dataset.order, el: x
          };
        });
        elements.sort(function (a, b) {
          return (a.order | 0) - (b.order | 0);
        });
        var first = true;
        elements.forEach(function (x, i) {
          x.el.style.order = i;
          x.el.dataset.firstChild = false;
          x.el.dataset.lastChild = false;
          x.el.dataset.visible = x.el.dataset.parent == elements[elements.length - 1].el.dataset.parent;
          if (x.el.dataset.visible == "true" && first) {
            x.el.dataset.firstChild = true;
            first = false;
          }
        });

        if (elements.length) {
          elements[elements.length - 1].el.dataset.lastChild = true;
        }
      }
      var activeElement = document.activeElement;
      var selectedIndex = this.get('selectedIndex');
      var el = this.element.querySelector("ui-wysiwyg-col[data-index=\"" + selectedIndex + "\"]");
      if (!el) {
        return;
      }
      if (el === activeElement) {
        return;
      }
      if (el.contains(activeElement)) {
        return;
      }

      // doesn't have focus ... select the next focusable element in this object
      /*
      try {
        var treeWalker = document.createTreeWalker(
          document.body,
          NodeFilter.SHOW_ELEMENT,
          { acceptNode: function(node) { return (node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP); } },
          false
        );
        treeWalker.currentNode = el;
        var nextFocusableNode = treeWalker.nextNode();
        if (el.contains(nextFocusableNode)) {
          nextFocusableNode.focus();
        }
      } catch (e) {
        // ignore errors
      }
      */
    },
    addBlock: function addBlock(index, data) {
      var _this3 = this;

      data = Object.assign({}, data);
      var value = this.get('_value');
      var columns = this.get('columns');

      if (data.width === null || data.width === undefined) {
        data.width = columns;
      }

      var idx = parseInt(index.substring(index.indexOf('-') + 1));
      value.replace(idx + 1, 0, [data]);

      var sourceBlockUpdate = (this.get('model').filter(function (x) {
        return x.data.some(function (y) {
          return y.data === data;
        });
      }).map(function (x) {
        return x.data;
      })[0] || []).find(function (y) {
        return y.data === data;
      });
      requestAnimationFrame(function () {
        if (_this3.element) {
          var el = _this3.element.querySelector("[data-index=\"" + sourceBlockUpdate.index + "\"]");
          if (el) {
            el.focus();
          }
        }
      });
    },
    removeBlock: function removeBlock(index) {
      var value = this.get('_value');

      var s = index.split('-');
      var idx = parseInt(s[1]);

      if (s.length == 2) {
        value.removeAt(idx);
      } else {
        var lidx = parseInt(s[2]) + 1;
        value.replace(idx, lidx - idx, null);
      }

      // reset selection
      this.set('selectedIndex', null);
    },
    _inception: function _inception(n) {
      while (n) {
        if (n === this.element) {
          return false;
        }
        if (n.tagName === this.element.tagName) {
          return true;
        }
        n = n.parentElement;
      }
      return false;
    },
    _afterFocus: function _afterFocus() {
      this._activateItem(this._lastFocus);
    },
    _focusIn: function _focusIn(e) {
      this._lastFocus = e;
      this._lastFocusReset = false;
      Ember.run.once(this._afterFocus);
    },
    _focusOut: function _focusOut(e) {
      this._lastFocus = e;
      this._lastFocusReset = true;
      Ember.run.once(this._afterFocus);
    },
    _activateItem: function _activateItem(e, reset) {
      if (this.get('lockSelection')) {
        return;
      }

      var node = e && e.target;
      var ignoreElements = [].concat(_toConsumableArray(document.body.querySelectorAll('[data-ignore-selection]')));
      if (ignoreElements.some(function (x) {
        return x.contains(node);
      })) {
        // ignore this
        return;
      }

      if (this._lastFocusReset) {
        node = null;
      }

      // find next col or row
      while (node && node.tagName != 'UI-WYSIWYG-COL' && node.tagName != 'UI-WYSIWYG-ROW') {
        node = node.parentElement;
      }

      if (reset) {
        // RESET SELECTION !
        node = null;
      }

      var sI = node && node.dataset && node.dataset.index;
      if (sI && sI != this.get('selectedIndex')) {
        this.set('selectedIndex', sI);
      }
    },


    actions: {
      dragStart: function dragStart(data, e) {
        if (!e.target || !e.target.draggable) {
          return;
        }
        e.dataTransfer.setData(this._dndMime, JSON.stringify({
          i: data.i,
          rowIndex: data.rowIndex,
          sectionIndex: data.sectionIndex,
          data: data.data
        }));
        e.dataTransfer.effectAllowed = 'move';
        var blockEl = e.currentTarget;
        blockEl.classList.add('will-drag');
        var bb = blockEl.getBoundingClientRect();

        try {
          e.dataTransfer.setDragImage(blockEl, e.clientX - bb.left, e.clientY - bb.top);
        } catch (e) {
          /* ignore */
        }
        requestAnimationFrame(function () {
          blockEl.classList.add('dragging');
          blockEl.classList.remove('will-drag');
          document.body.classList.add('activate-dropzones-level-2');
        });
        e.stopPropagation();
      },
      dragStop: function dragStop(e) {
        var blockEl = e.currentTarget.parentElement;
        blockEl.classList.remove('dragging');
        document.body.classList.remove('activate-dropzones-level-2');
      },
      dragEnter: function dragEnter(e) {
        var _this4 = this;

        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this4._dndMime;
        })) {
          return;
        }
        e.preventDefault();

        /* FIREFOX WHY ? */
        if (e.target.nodeType === 3) {
          if (e.target.parentElement.dataset.hasDragEnter3) {
            throw "Has Drag Enter 3 ... can't be";
          } else if (e.target.parentElement.dataset.hasDragEnter2) {
            e.target.parentElement.dataset.hasDragEnter3 = true;
          } else {
            e.target.parentElement.dataset.hasDragEnter2 = true;
          }
        } else {
          e.target.dataset.hasDragEnter = true;
        }

        if (e.currentTarget.querySelector('[data-has-drag-enter], [data-has-drag-enter2], [data-has-drag-enter3]')) {
          e.currentTarget.classList.add('highlight');
        } else {
          e.currentTarget.classList.remove('highlight');
        }
      },
      dragOver: function dragOver(e) {
        var _this5 = this;

        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this5._dndMime;
        })) {
          return;
        }
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      },
      dragLeave: function dragLeave(e) {
        var _this6 = this;

        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this6._dndMime;
        })) {
          return;
        }
        e.preventDefault();

        /* FIREFOX WHY ? */
        if (e.target.nodeType === 3) {
          delete e.target.parentElement.dataset.hasDragEnter2;
        } else {
          delete e.target.dataset.hasDragEnter;
        }
        if (e.target.nodeType === 3) {
          if (e.target.parentElement.dataset.hasDragEnter3) {
            delete e.target.parentElement.dataset.hasDragEnter3;
          } else if (e.target.parentElement.dataset.hasDragEnter2) {
            delete e.target.parentElement.dataset.hasDragEnter2;
          } else {
            delete e.target.parentElement.dataset.hasDragEnter;
          }
        } else {
          delete e.target.dataset.hasDragEnter;
        }

        if (e.currentTarget.querySelector('[data-has-drag-enter], [data-has-drag-enter2], [data-has-drag-enter3]')) {
          e.currentTarget.classList.add('highlight');
        } else {
          e.currentTarget.classList.remove('highlight');
        }
      },
      drop: function drop(mode, _targetBlock, e) {
        var _this7 = this;

        e.preventDefault();

        this.element.querySelectorAll('.drop-before-row, .drop-before, .drop-replace, .drop-after-row, .drop-after').forEach(function (x) {
          x.classList.remove('highlight');
        });

        if (![].concat(_toConsumableArray(e.dataTransfer.types)).some(function (x) {
          return x === _this7._dndMime;
        })) {
          return;
        }

        e.stopPropagation();

        var columns = this.get('columns');
        var blocks = Ember.A([].concat(_toConsumableArray(this.get('_value'))));
        var targetBlock = Object.assign({}, _targetBlock.isPlaceholder ? _targetBlock.prev : _targetBlock);
        var _sourceBlock = JSON.parse(e.dataTransfer.getData(this._dndMime));
        _sourceBlock.data = blocks[_sourceBlock.i]; // for now :/
        var sourceBlock = (this.get('model').filter(function (x) {
          return x.data.some(function (y) {
            return y.data === _sourceBlock.data;
          });
        }).map(function (x) {
          return x.data;
        })[0] || []).find(function (y) {
          return y.data === _sourceBlock.data;
        });

        var tIndex = null;
        var sIndex = null;
        var isRow = false;
        var isReplace = false;
        var isPlaceholder = false;
        switch (mode) {
          case 'after':
            tIndex = targetBlock.i;
            sIndex = targetBlock.i + 1;
            break;
          case 'after-row':
            isRow = true;
            tIndex = targetBlock.i;
            sIndex = targetBlock.i + 1;
            break;
          case 'before-row':
            tIndex = targetBlock.i + 1;
            sIndex = targetBlock.i;
            break;
          case 'before':
            tIndex = targetBlock.i + 1;
            sIndex = targetBlock.i;
            break;
          case 'replace':
            if (_targetBlock.isPlaceholder) {
              // after
              isPlaceholder = true;
              tIndex = targetBlock.i;
              sIndex = targetBlock.i + 1;
            } else {
              isReplace = true;
              tIndex = sourceBlock.i;
              sIndex = targetBlock.i;
            }
            break;
          default:
            throw "Drop mode not supported !";
        }

        if (tIndex === sIndex) {
          return;
        }

        blocks.replace(targetBlock.i, 1, [null]);
        blocks.replace(sourceBlock.i, 1, [null]);

        if (isRow) {
          if (sourceBlock.data.width > 0) {
            if (sourceBlock.prev && sourceBlock.prev.rowIndex === sourceBlock.rowIndex && sourceBlock.prev.sectionIndex === sourceBlock.sectionIndex && sourceBlock.prev.data.component === 'text') {
              Ember.set(sourceBlock.prev, 'data.width', sourceBlock.prev.data.width + sourceBlock.data.width);
            } else if (sourceBlock.next && sourceBlock.next.rowIndex === sourceBlock.rowIndex && sourceBlock.next.sectionIndex === sourceBlock.sectionIndex && sourceBlock.next.data.component === 'text') {
              Ember.set(sourceBlock.next, 'data.width', sourceBlock.next.data.width + sourceBlock.data.width);
            } else if (sourceBlock.prev && sourceBlock.prev.rowIndex === sourceBlock.rowIndex && sourceBlock.prev.sectionIndex === sourceBlock.sectionIndex) {
              Ember.set(sourceBlock.prev, 'data.width', sourceBlock.prev.data.width + sourceBlock.data.width);
            } else if (sourceBlock.next && sourceBlock.next.rowIndex === sourceBlock.rowIndex && sourceBlock.next.sectionIndex === sourceBlock.sectionIndex) {
              Ember.set(sourceBlock.next, 'data.width', sourceBlock.next.data.width + sourceBlock.data.width);
            }
            Ember.set(sourceBlock, 'data.width', columns);
          }
        } else if (!isReplace) {
          if (!isPlaceholder && sourceBlock.data.width > 0 && (sourceBlock.rowIndex !== targetBlock.rowIndex || sourceBlock.sectionIndex !== targetBlock.sectionIndex)) {
            // try to take freespace
            var usedspace = 0;
            var lastspace = 0;
            var lastBlock = null;
            var b = targetBlock.next || targetBlock;
            while (b.prev != null && b.prev.data.width > 0 && b.prev.rowIndex === targetBlock.rowIndex && b.prev.sectionIndex === targetBlock.sectionIndex) {
              b = b.prev;
            }
            while (b != null && b.data.width > 0 && b.rowIndex === targetBlock.rowIndex && b.sectionIndex === targetBlock.sectionIndex) {
              lastBlock = b;
              lastspace = b.data.width;
              usedspace += b.data.width;
              b = b.next;
            }
            var freespace = columns - usedspace;

            if (lastspace > 0 || freespace > 0) {
              if (sourceBlock.prev && sourceBlock.prev.rowIndex === sourceBlock.rowIndex && sourceBlock.prev.sectionIndex === sourceBlock.sectionIndex && sourceBlock.prev.data.component === 'text') {
                Ember.set(sourceBlock.prev, 'data.width', sourceBlock.prev.data.width + sourceBlock.data.width);
              } else if (sourceBlock.next && sourceBlock.next.rowIndex === sourceBlock.rowIndex && sourceBlock.next.sectionIndex === sourceBlock.sectionIndex && sourceBlock.next.data.component === 'text') {
                Ember.set(sourceBlock.next, 'data.width', sourceBlock.next.data.width + sourceBlock.data.width);
              } else if (sourceBlock.prev && sourceBlock.prev.rowIndex === sourceBlock.rowIndex && sourceBlock.prev.sectionIndex === sourceBlock.sectionIndex) {
                Ember.set(sourceBlock.prev, 'data.width', sourceBlock.prev.data.width + sourceBlock.data.width);
              } else if (sourceBlock.next && sourceBlock.next.rowIndex === sourceBlock.rowIndex && sourceBlock.next.sectionIndex === sourceBlock.sectionIndex) {
                Ember.set(sourceBlock.next, 'data.width', sourceBlock.next.data.width + sourceBlock.data.width);
              }
            }

            if (freespace === 0 && lastspace > 0) {
              //debugger;
              if (lastBlock.data.width - sourceBlock.data.width > 0) {
                Ember.set(lastBlock, 'data.width', lastBlock.data.width - sourceBlock.data.width);
              } else {
                Ember.set(sourceBlock, 'data.width', lastspace);
                Ember.set(lastBlock, 'data.width', columns);
              }
            }
            if (freespace > 0) {
              Ember.set(sourceBlock, 'data.width', freespace);
            }
          }
          if (isPlaceholder) {
            if (sourceBlock.data.width > 0) {
              Ember.set(sourceBlock, 'data.width', _targetBlock.data.width);
            }
          }
        } else {
          if (sourceBlock.sectionIndex !== targetBlock.sectionIndex || sourceBlock.rowIndex !== targetBlock.rowIndex) {
            // replace !
            var w = sourceBlock.data.width;
            if (targetBlock.data.width > 0) {
              if (sourceBlock.data.width > 0) {
                Ember.set(sourceBlock, 'data.width', targetBlock.data.width);
              }
            } else {
              if (sourceBlock.data.width > 0) {
                Ember.set(sourceBlock, 'data.width', columns);
              }
            }
            if (w > 0) {
              if (targetBlock.data.width > 0) {
                Ember.set(targetBlock, 'data.width', w);
              }
            } else {
              if (targetBlock.data.width > 0) {
                Ember.set(targetBlock, 'data.width', columns);
              }
            }
          }
        }

        if (tIndex > sIndex) {
          blocks.replace(tIndex, 0, [targetBlock.data]);
          if (targetBlock.i !== sourceBlock.i) {
            blocks.replace(sIndex, 0, [sourceBlock.data]);
          }
        } else {
          blocks.replace(sIndex, 0, [sourceBlock.data]);
          if (targetBlock.i !== sourceBlock.i) {
            blocks.replace(tIndex, 0, [targetBlock.data]);
          }
        }

        Ember.set(this, '_value', Ember.A(blocks.compact()));
        var sourceBlockUpdate = (this.get('model').filter(function (x) {
          return x.data.some(function (y) {
            return y.data === sourceBlock.data;
          });
        }).map(function (x) {
          return x.data;
        })[0] || []).find(function (y) {
          return y.data === sourceBlock.data;
        });
        Ember.set(this, 'selectedIndex', sourceBlockUpdate.index);
      },
      moveRow: function moveRow(mode, from, to) {
        if (from === to) {
          // nothing todo
          return;
        }

        var fS = from.split('-');
        var tS = to.split('-');

        var from1 = parseInt(fS[1]);
        var from2 = parseInt(fS[2]);
        var to1 = parseInt(tS[1]);
        var to2 = parseInt(tS[2]);

        var ranges = [from1, from2, to1, to2].sort(function (a, b) {
          return a - b;
        });

        var value = this.get('value');
        if (mode === 0) {
          // before
          value.setObjects([].concat(value.slice(0, ranges[0]), from1 > to1 ? value.slice(from1, from2 + 1) : [], from1 > to1 ? value.slice(to1, to2 + 1) : [], value.slice(ranges[1] + 1, ranges[2]), from1 < to1 ? value.slice(from1, from2 + 1) : [], from1 < to1 ? value.slice(to1, to2 + 1) : [], value.slice(ranges[3] + 1)));
        } else if (mode === 1) {
          // swap
          value.setObjects([].concat(value.slice(0, ranges[0]), to1 > from1 ? value.slice(to1, to2 + 1) : [], to1 < from1 ? value.slice(from1, from2 + 1) : [], value.slice(ranges[1] + 1, ranges[2]), to1 > from1 ? value.slice(from1, from2 + 1) : [], to1 < from1 ? value.slice(to1, to2 + 1) : [], value.slice(ranges[3] + 1)));
        } else if (mode === 2) {
          // after
          value.setObjects([].concat(value.slice(0, ranges[0]), from1 > to1 ? value.slice(to1, to2 + 1) : [], from1 > to1 ? value.slice(from1, from2 + 1) : [], value.slice(ranges[1] + 1, ranges[2]), from1 < to1 ? value.slice(to1, to2 + 1) : [], from1 < to1 ? value.slice(from1, from2 + 1) : [], value.slice(ranges[3] + 1)));
        }
      }
    }
  });
});