define("lt-page-editor/components/lt-page-editor-sp-menu-add-block/component", ["exports", "lt-page-editor/components/lt-page-editor-add-block/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    blocks: Ember.computed(function () {
      return [{
        title: 'Menü - Link',
        icon: '/lt-page-editor/block_text.svg',
        default: {
          width: -1,
          component: 'spMenuLink'
        }
      }];
    }),
    didInsertElement: function didInsertElement() {
      document.body.querySelector("#".concat(this.elementId, "-popup .content button")).click();
    }
  });

  _exports.default = _default;
});