define("lt-page-editor/components/blocks/text/lt-page-editor-text-properties/component", ["exports", "ui-page-editor-text/components/ui-page-editor-text-properties/component", "lt-page-editor/components/blocks/text/lt-page-editor-text-properties/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _component.default.extend({
    layout: _template.default,
    hasLink: (0, _component.computedContainsClass)("link"),
    hasTitleH1: (0, _component.computedContainsClass)("title_h1"),
    hasTitleH2: (0, _component.computedContainsClass)("title_h2"),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'sizeItems', [{
        id: 'big',
        value: 'große Schriftgröße'
      }, {
        id: null,
        value: 'normale Schriftgröße'
      }, {
        id: 'small',
        value: 'kleine Schriftgröße'
      }, {
        id: 'title_h1',
        value: 'Überschrift 1'
      }, {
        id: 'title_h2',
        value: 'Überschrift 2'
      }]);
    },
    currentSize: Ember.computed('hasBig', 'hasSmall', 'hasNormal', 'hasTitleH1', 'hasTitleH2', {
      get: function get(k) {
        k;
        return Ember.get(this, 'hasBig') && 'big' || Ember.get(this, 'hasSmall') && 'small' || Ember.get(this, 'hasTitleH1') && 'title_h1' || Ember.get(this, 'hasTitleH2') && 'title_h2' || null;
      },
      set: function set(k, v) {
        k;
        var classes = ['big', 'small', 'title_h1', 'title_h2'];

        if (v) {
          this.send.apply(this, ['setClass', v].concat(_toConsumableArray(classes.filter(function (x) {
            return x !== v;
          }))));
        } else {
          this.send.apply(this, ['removeClass'].concat(classes));
        }

        return v;
      }
    }),
    linkIds: Ember.computed('value.data', function () {
      var data = Ember.get(this, 'value.data') || [];

      if (!data.reduce) {
        return [];
      }

      return data.reduce(function (p, c, i) {
        if (i % 2 != 0) {
          return p;
        }

        var classes = (c || '').split(' ');

        if (!classes.includes('link')) {
          return p;
        }

        var link = classes.find(function (x) {
          return x.indexOf('link-') === 0;
        });

        if (!link) {
          return p;
        }

        var linkIdx = parseInt(link.split('-')[1]);
        p.push(linkIdx);
        return p;
      }, []);
    }),
    nextLinkId: Ember.computed('linkIds', function () {
      var data = Ember.get(this, 'linkIds');
      var idx = data.reduce(function (p, c) {
        return Math.max(c + 1, p);
      }, 1);
      return idx;
    }),
    updateReferences: Ember.observer('value.data', 'value.links', function () {
      // update references:
      var linkIds = Ember.get(this, 'linkIds');
      var links = Ember.get(this, 'value.links') || [];

      if (links.some(function (x) {
        return !linkIds.includes(x.id);
      })) {
        // missing some links..
        links = links.filter(function (x) {
          return linkIds.includes(x.id);
        }); // fix the links..

        Ember.set(this, 'value.links', links);
      }

      var references = [];
      var references_external = [];

      var _iterator = _createForOfIteratorHelper(links),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var l = _step.value;

          if (l.internal) {
            references.push(l.data);
          } else {
            references_external.push(l.data);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (JSON.stringify(Ember.get(this, 'value.references') || []) != JSON.stringify(references)) {
        Ember.set(this, 'value.references', references);
      }

      if (JSON.stringify(Ember.get(this, 'value.references_external') || []) != JSON.stringify(references_external)) {
        Ember.set(this, 'value.references_external', references_external);
      }
    }),
    linkData: Ember.computed('cursorNode', function () {
      var node = this.get('cursorNode');
      var linkIdx = Ember.get(this, 'linkIds').map(function (x) {
        return node && node.classList && node.classList.contains("link") && node.classList.contains("link-".concat(x)) && x;
      }).find(function (x) {
        return x;
      });
      var link = (Ember.get(this, 'value.links') || []).find(function (x) {
        return x.id === linkIdx;
      });
      return link;
    }),
    actions: {
      focusHack: function focusHack(e) {
        e.stopPropagation(); // disable focus lose in editor

        var editor = Ember.get(this, 'editor');
        Ember.set(editor, 'lockSelection', true);
        Ember.run.next(function () {
          Ember.set(editor, 'lockSelection', false);
        });
      },
      removeLink: function removeLink() {
        this.send.apply(this, ['removeClass', 'link'].concat(_toConsumableArray(Ember.get(this, 'linkIds').map(function (x) {
          return "link-".concat(x);
        }))));
      },
      saveLink: function saveLink(data) {
        var cursor = Ember.get(this, 'setupLink');
        var idx = cursor.linkIdx || Ember.get(this, 'nextLinkId');
        Ember.set(this, 'setupLink', null); // foce selection

        Ember.set(this, 'helper.cursor', cursor.cursor);
        var selection = window.getSelection() || document.getSelection();
        selection.removeAllRanges();
        selection.addRange(cursor.range); // set classes etc.

        var ids = Ember.get(this, 'linkIds');
        this.send.apply(this, ['setClass', "link link-".concat(idx), "link-".concat(idx)].concat(_toConsumableArray(ids.map(function (x) {
          return "link-".concat(x);
        }))));
        var links = Ember.get(this, 'value.links') || [];
        links = links.filter(function (x) {
          return ids.includes(x.id) && x.id !== idx;
        });
        links.push({
          id: idx,
          internal: data.internal,
          type: data.type,
          title: data.title,
          data: data.data,
          rel: data.rel
        });
        Ember.set(this, 'value.links', links);
      }
    }
  });

  _exports.default = _default;
});