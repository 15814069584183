define("lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser/component", ["exports", "lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    helper: Ember.computed('value.data.link', 'value.layout', function () {
      var helper = {
        link: Ember.get(this, 'value.data.link'),
        setLink: this.setLink.bind(this),
        layout: Ember.get(this, 'value.layout'),
        setLayout: this.setLayoutStyle.bind(this)
      };
      return helper;
    }),
    _value: Ember.computed('value.data', function () {
      var value = Ember.get(this, 'value');

      if (!value.data || !value.data.image) {
        Ember.set(value, 'data', Object.assign(value.data || {}, {
          text1: 'Text 1',
          text2: 'Text 2',
          image: {
            data: []
          }
        }));
      }

      return value.data;
    }),
    layoutStyle: Ember.computed('value.layout', function () {
      return Ember.get(this, 'value.layout') || 0;
    }),
    setLink: function setLink(l) {
      Ember.set(this, 'value.data.link', l);
    },
    setLayoutStyle: function setLayoutStyle(l) {
      Ember.set(this, 'value.layout', l);
    }
  });

  _exports.default = _default;
});