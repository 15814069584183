define("lt-page-editor/components/blocks/sp-calendar-top/lt-page-editor-sp-calendar-top-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Cr40Hjr",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-calendar-top/lt-page-editor-sp-calendar-top-properties/template.hbs"
    }
  });

  _exports.default = _default;
});