define("lt-page-editor/components/blocks/sp-advent-calendar/lt-page-editor-sp-advent-calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vizzd9Wn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"lt-page-editor-sp-advent-calendar-readonly\",null,[[\"adventInfo\",\"adventDayInfo\",\"value\",\"resolver\",\"edit\"],[[25,[\"adventInfo\"]],[25,[\"adventDayInfo\"]],[25,[\"value\"]],[25,[\"resolver\"]],true]]],false],[0,\"\\n\"],[15,1,[[25,[\"helper\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-advent-calendar/lt-page-editor-sp-advent-calendar/template.hbs"
    }
  });

  _exports.default = _default;
});