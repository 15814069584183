define("lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser-properties/component", ["exports", "lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser-properties/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    typeItems: Ember.computed(function () {
      return [{
        id: 0,
        value: 'Lokal von Datenbank'
      }, {
        id: 1,
        value: 'Manuell gesetzt'
      }];
    })
  });

  _exports.default = _default;
});