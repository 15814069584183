define("lt-page-editor/components/blocks/db-content/lt-page-editor-db-content/component", ["exports", "lt-page-editor/components/blocks/db-content/lt-page-editor-db-content/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    helper: Ember.computed('value.db', function () {
      var _this = this;

      var helper = {
        db: Ember.get(this, 'value.db'),
        setDb: function setDb(x) {
          debugger;

          _this.set('value.db', x);
        }
      };
      return helper;
    })
  });

  _exports.default = _default;
});