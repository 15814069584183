define("lt-page-editor/components/lt-page-editor-add-block-override/component", ["exports", "ui-page-editor/components/ui-page-editor-add-block/component", "lt-page-editor/components/lt-page-editor-add-block-override/template", "lt-page-editor/utils/send-action"], function (_exports, _component, _template, _sendAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    _mouseDownOutside: function _mouseDownOutside() {
      /* DO NOTHING */
    },
    actions: {
      addBlock: function addBlock() {
        if (arguments[0]) {
          _sendAction.default.apply(void 0, [this, 'addBlock'].concat(Array.prototype.slice.call(arguments)));
        }

        this.send('closePopover');
      }
    }
  });

  _exports.default = _default;
});