define("lt-page-editor/components/blocks/image/lt-page-editor-image-readonly/component", ["exports", "lt-page-editor/components/blocks/image/lt-page-editor-image-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function groupBy(items, key) {
    var res = [];

    var _iterator = _createForOfIteratorHelper(items),
        _step;

    try {
      var _loop = function _loop() {
        item = _step.value;
        var k = Ember.get(item, key);
        var it = res.find(function (x) {
          return Ember.get(x[0], key) == k;
        });

        if (!it) {
          res.push([item]);
          return "continue";
        }

        it.push(item);
      };

      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var item;

        var _ret = _loop();

        if (_ret === "continue") continue;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return res;
  }

  function mod(x, y) {
    x = x | 0;
    y = y | 0;

    if (x < 0) {
      // make positve
      x -= Math.floor(x / y) * y;
    }

    return x % y;
  }

  var GuidWrapper = Ember.ObjectProxy.extend({
    guid: Ember.computed('content', function () {
      return Ember.get(this, 'fixFor') || Ember.guidFor(Ember.get(this, 'content'));
    })
  });

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['hasSize', 'preview'],
    layout1: Ember.computed.equal('value.layout', 1),
    layout2: Ember.computed.equal('value.layout', 2),
    layout3: Ember.computed.equal('value.layout', 3),
    layout4: Ember.computed.equal('value.layout', 4),
    layout5: Ember.computed.equal('value.layout', 5),
    tagit: Ember.computed.not('disableTagit'),
    index: 0,
    hasSize: Ember.computed.bool('value.size'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._interval = setInterval(function () {
        if (!_this.isDestroyed) {
          if (Ember.get(_this, 'value.data.length') > 1) {
            Ember.set(_this, 'index', (Ember.get(_this, 'index') | 0) + 1);
          }
        }
      }, 10000);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      clearInterval(this._interval);
    },
    cwidth: Ember.computed('firstImage.w', 'firstImage.h', 'value.size', function () {
      var w = Ember.get(this, 'firstImage.w'); //const h = get(this, 'firstImage.h');

      var size = Ember.get(this, 'value.size');

      switch (size) {
        case 1:
          //4:3
          return 4;

        case 2:
          //4:6
          return 128;

        case 3:
          //1:1
          return 1;

        case 4:
          //2:1
          return 2;

        case 5:
          //1:2
          return 1;

        default:
          return w;
      }
    }),
    cheight: Ember.computed('firstImage.w', 'firstImage.h', 'value.size', function () {
      //const w = get(this, 'firstImage.w');
      var h = Ember.get(this, 'firstImage.h');
      var size = Ember.get(this, 'value.size');

      switch (size) {
        case 1:
          //4:3
          return 3;

        case 2:
          //4:6
          return 221;

        case 3:
          //1:1
          return 1;

        case 4:
          //2:1
          return 1;

        case 5:
          //1:2
          return 2;

        default:
          return h;
      }
    }),
    firstImage: Ember.computed('value.data', function () {
      var data = _toConsumableArray(Ember.get(this, 'value.data') || []);

      data.sort(function (a, b) {
        var r = Ember.get(a, 'y') - Ember.get(b, 'y');

        if (r != 0) {
          return r;
        }

        return Ember.get(a, 'x') - Ember.get(b, 'x');
      });
      return data[0];
    }),
    data1: Ember.computed('value.data', 'index', function () {
      var data = _toConsumableArray(Ember.get(this, 'value.data') || []);

      if (data.length > 1 && data.length < 3) {
        var j = data.length;

        for (var i = 0; i < j; ++i) {
          data.push(Object.assign({
            fixFor: Ember.guidFor(data[i]) + '_' + i
          }, data[i]));
        }
      }

      var index = mod(Ember.get(this, 'index'), data.length) || 0;
      var prevIndex = mod(index - 1, data.length) || 0;
      var nextIndex = mod(index + 1, data.length) || 0;
      return {
        slideshow: data.length > 1,
        images: [GuidWrapper.create({
          content: data[prevIndex]
        }), GuidWrapper.create({
          content: data[index]
        }), GuidWrapper.create({
          content: data[nextIndex]
        })],
        imagesAll: data.map(function (x) {
          return GuidWrapper.create({
            content: x
          });
        })
      };
    }),
    data2: Ember.computed('value.data', function () {
      return Ember.get(this, 'value.data') || [];
    }),
    data3: Ember.computed('value.data', function () {
      var data = _toConsumableArray(Ember.get(this, 'value.data') || []);

      data.sort(function (a, b) {
        return Ember.get(a, 'y') - Ember.get(b, 'y');
      });
      var first = null;
      return groupBy(data, 'y').map(function (i) {
        i.sort(function (a, b) {
          return Ember.get(a, 'x') - Ember.get(b, 'x');
        });

        if (!first) {
          first = i[0];
        }

        return i;
      });
    }),
    data5: Ember.computed('value.data', function () {
      return Ember.get(this, 'value.data') || {};
    }),
    bodyElement: Ember.computed(function () {
      return document.body;
    }),
    click: function click() {
      if (!Ember.get(this, 'disableFullscreen')) {//set(this, 'fullscreen', true);
      }
    },
    _value: Ember.computed('value.data', function () {
      var value = Ember.get(this, 'value');
      return value && value.data && value.data[0];
    }),
    _missingCopyright: Ember.computed('value.data.@each.copyright', function () {
      var v = Ember.get(this, 'value.data') || [];

      if ('some' in v) {
        return v.some(function (x) {
          return !Ember.get(x, 'copyright');
        });
      }
    }),
    _visibleDesciption: Ember.computed('index', 'value.data', function () {
      var data = _toConsumableArray(Ember.get(this, 'value.data') || []);

      var index = mod(Ember.get(this, 'index'), data.length) || 0;
      return Ember.get(data[index] || {}, 'description');
    }),
    _descriptions: Ember.computed('value.data', function () {
      var data = _toConsumableArray(Ember.get(this, 'value.data') || []);

      return data.map(function (x) {
        return Ember.get(x, 'description');
      }).filter(function (x) {
        return x && contentToText(x).trim();
      });
    })
  });

  _exports.default = _default;

  function contentToText(x) {
    if (!x || !x.data || !('map' in x.data)) {
      return '';
    }

    return x.data.map(function (a, i) {
      return i % 2 == 0 ? null : a;
    }).filter(function (a) {
      return a;
    }).join('');
  }
});