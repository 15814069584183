define("lt-page-editor/components/blocks/sp-author/lt-page-editor-sp-author-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bD5f4YYs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"author-date\"],[9],[1,[23,\"publishDate\"],false],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"author-name\"],[9],[1,[25,[\"model\",\"author\"]],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-author/lt-page-editor-sp-author-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});