define("lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6JIU6WaE",
    "block": "{\"symbols\":[\"title\"],\"statements\":[[4,\"lt-link\",null,[[\"link\",\"resolver\",\"transition\"],[[25,[\"linkValue\"]],[25,[\"resolver\"]],[25,[\"transition\"]]]],{\"statements\":[[4,\"if\",[[25,[\"linkValue\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"      \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"nolink\"],[9],[0,\"Kein Titel gesetzt\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"nolink\"],[9],[0,\"Kein Link gesetzt\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});