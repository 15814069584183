define("lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-teaser/lt-page-editor-pk-teaser-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function filter(name, v) {
    return Ember.computed(name, function () {
      return (Ember.get(this, name) || []).filter(function (x) {
        return x && (Ember.get(x, 'id') / 1000 | 0) == v;
      });
    });
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    _value: Ember.computed.alias('value.data'),
    lokal: Ember.computed.readOnly('model.lokal'),
    bezahlmoeglichkeiten: filter('model.lokal.attributes', 4),
    ausstattung: filter('model.lokal.attributes', 5),
    angebot: filter('model.lokal.attributes', 6),
    extra: filter('model.lokal.attributes', 7),
    address: Ember.computed('model.lokal.address', function () {
      var address = Ember.get(this, 'model.lokal.address') || {};
      var ad = [[address.street, address.num], [address.plz, address.city], [address.additional]];
      return ad.map(function (x) {
        return x.filter(function (x) {
          return x;
        }).join(' ');
      }).filter(function (x) {
        return x;
      }).join(', ');
    }),
    hasImageDescription: Ember.computed('lokal.teaserImage.description', function () {
      var description = Ember.get(this, 'lokal.teaserImage.description');

      if (!description || !description.data || !description.data.length) {
        return false;
      }

      var str = description.data.reduce(function (acc, val, i) {
        if (i % 2 == 1 && val) {
          return acc + val;
        }

        return acc;
      }, '');
      return str.trim() !== '';
    }),
    fromDB: Ember.computed.not('_value.type'),
    textWidth: Ember.computed('value.width', function () {
      var w = Ember.get(this, 'value.width');

      if (w <= 0) {
        w = 3;
      } //! TODO get value from "editor"


      return "".concat(w * 434, "px");
    })
  });

  _exports.default = _default;
});