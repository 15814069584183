define("lt-page-editor/components/blocks/pk-book/lt-page-editor-pk-book-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1nEDy8HT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"canReservieren\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"onclick\",[29,\"custom-event\",[\"lt-tisch-reservieren\"],[[\"lokal\"],[[25,[\"lokal\"]]]]]],[9],[0,\"Tisch reservieren\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-book/lt-page-editor-pk-book-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});