define("ui-input/templates/components/ui-input-richtext", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fICKvtIY",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"ui-contenteditable\",null,[[\"content\",\"cursor\"],[[25,[\"content\"]],[25,[\"cursor\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"helper\"],[11,\"style\",\"display: none\"],[9],[0,\"\\n  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"markup\",\"b\"],null]],[9],[7,\"b\"],[9],[0,\"B\"],[10],[10],[0,\"\\n  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"markup\",\"i\"],null]],[9],[7,\"i\"],[9],[0,\"I\"],[10],[10],[0,\"\\n  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"markup\",\"u\"],null]],[9],[7,\"u\"],[9],[0,\"U\"],[10],[10],[0,\"\\n  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"markup\",\"s\"],null]],[9],[7,\"s\"],[9],[0,\"S\"],[10],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-input/templates/components/ui-input-richtext.hbs"
    }
  });

  _exports.default = _default;
});