define('ui-page-editor-text/mixins/contenteditable-plaintext-only', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    attributeBindings: ['contenteditable'],
    contenteditable: 'plaintext-only',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._domObserver = new MutationObserver(function (mut) {
        if (!_this.element) {
          return;
        }
        _this._domObserver.disconnect();
        try {
          if (mut.any(function (x) {
            return x.addedNodes.length || x.removedNodes.length;
          })) {
            // force plain-text !!!
            _this.element.innerText = _this.element.textContent;
          }
          _this.send('updateContent');
        } finally {
          _this._installObserver();
        }
      });
    },
    _installObserver: function _installObserver() {
      this._domObserver.observe(this.element, {
        childList: true,
        subtree: true,
        characterData: true
      });
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this._installObserver();
    },
    willRemoveElement: function willRemoveElement() {
      this._super.apply(this, arguments);
      this._domObserver.disconnect();
    }
  });
});