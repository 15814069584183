define("ui-input/components/ui-input-checkbox", ["exports", "ui-input/templates/components/ui-input-checkbox", "ui-input/utils/sendAction"], function (exports, _uiInputCheckbox, _sendAction) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _uiInputCheckbox.default,
    init: function init() {
      this._super.apply(this, arguments);

      this._change = this._change.bind(this);
    },
    willInsertElement: function willInsertElement() {
      this.element.addEventListener('change', this._change);
    },
    willRemoveElement: function willRemoveElement() {
      this.element.removeEventListener('change', this._change);
    },

    _items: Ember.computed('value', 'items', function () {
      var value = this.get('value') || [];
      var items = this.get('items') || [];
      return items.map(function (x) {
        return {
          checked: value.includes(x.id),
          original: x
        };
      });
    }),
    _change: function _change(e) {
      var value = this.get('value') || [];
      var selected = (this.get('items')[parseInt(e.target.value)] || {}).id;
      value.removeObject(selected);
      if (e.target.checked) {
        value.addObject(selected);
      }
      this.set('value', value);
      _sendAction.default.apply(undefined, [this, 'onchange'].concat(Array.prototype.slice.call(arguments)));
    }
  });
});