define("lt-page-editor/components/lt-page-editor-toolbar/component", ["exports", "lt-page-editor/components/lt-page-editor-toolbar/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    domToolbar: Ember.computed('dom', function () {
      var dom = Ember.get(this, 'dom');
      return dom && dom.querySelector('#' + dom.id + '-toolbar');
    })
  });

  _exports.default = _default;
});