define('ui-input/components/ui-input-tel', ['exports', 'ui-input/components/ui-textarea', 'ui-input/utils/maskedFormat', 'ui-input/utils/maskedFormatContent', 'ui-input/utils/immediate', 'ui-input/mixins/masked-tabbing'], function (exports, _uiTextarea, _maskedFormat, _maskedFormatContent3, _immediate, _maskedTabbing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function trimContent(segment) {
    if (!segment || !segment.value) {
      return segment;
    }
    var contentSpezial = [];
    var match = segment.value.match(/(^[\s]*)(.*?)([\s]*$)/);

    if (match[1].length != 0 && match[1].trim().length === 0) {
      contentSpezial.push({
        value: match[1],
        class: ['hidden', 'static', 'to-be-removed']
      });
    }
    contentSpezial.push({
      value: match[2],
      class: segment.class
    });
    /*if (match[3].length != 0 && match[3].trim().length === 0) {
      contentSpezial.push({
        value: match[3],
        class: [ 'hidden', 'static' ]
      });
    }*/

    return contentSpezial;
  }

  exports.default = _uiTextarea.default.extend(_maskedTabbing.default, {
    tagName: 'ui-input',
    multiline: false,
    init: function init() {
      throw "DON'T USE UI-INPUT :/ by Luca this component needs to be updates";
      this._super.apply(this, arguments);
      this._dontUpdateCursor = false;
    },
    _format: function _format(input) {
      var _this = this;

      input = input || '';
      var startsCorrect = input.startsWith('(');
      input = '(+' + input.replace('(+', '');

      var shift = input.replace(/\s/g, ' ').replace(/-/g, '_').replace(/[\u2012]/g, '-').replace(/[^0-9:(+)-_ ]/g, '').length - input.length;

      var _maskedFormatContent = (0, _maskedFormatContent3.default)({
        input: input,
        mask: '#+### 0#####)##########_######',
        removePlaceholder: /[-]/g,
        cursorOffset: function () {
          var c = _this._cursorOffset();
          if (c && !startsCorrect) {
            if (c[0] < 2) {
              c[0] += 2;
            }
            if (c[1] < 2) {
              c[1] += 2;
            }
          }
          return c;
        }(),
        preprocess: function preprocess(value) {
          value = value.replace(/\s/g, ' '); // whitespace to normal space
          value = value.replace(/-/, '_');
          value = value.replace(/[\u2012]/g, '-'); // figure dash to normal dash
          return value;
        },
        postprocess: function postprocess(value, size) {
          value = (0, _maskedFormat.rightPad)(value.trimLeft(), size);
          value = value.replace(/[^0-9]/g, ' '); // remove non numbers
          value = value.replace(/\s/g, '\u2007'); // replace whitespace with figure whitespace
          value = (0, _maskedFormat.placeholder)(value);
          value = value.replace(/-/g, '\u2012'); // replace dash with figure dash
          return value;
        },

        isPlaceholder: function isPlaceholder(value, size) {
          value = (0, _maskedFormat.leftPad)(value.trimLeft(), size);
          value = value.replace(/[^0-9]/g, ' '); // remove non numbers
          return (0, _maskedFormat.isPlaceholder)(value);
        }
      }),
          _maskedFormatContent2 = _slicedToArray(_maskedFormatContent, 2),
          cursor = _maskedFormatContent2[0],
          content = _maskedFormatContent2[1];

      if (cursor && !this._dontUpdateCursor) {
        // needs to be done after ... this "_format" !
        (0, _immediate.default)(function () {
          if (!_this.element) {
            return;
          }
          try {
            var changed = !startsCorrect || input.replace(/\u2012/g, '') !== _this.element.textContent.replace(/\u2012/g, '');
            if (changed && document.activeElement === _this.element) {
              cursor[0] += shift;
              cursor[1] += shift;
              _this._setCursorOffset(cursor);
            }
          } catch (e) {
            // ignore
          }
        });
      }

      var contentSpezial = [];
      content.shift();
      content.shift(); // remove (+
      //(+
      contentSpezial.push({ value: '(+', static: true, class: ['static', 'static-1'] });
      // vorwahl
      contentSpezial.push.apply(contentSpezial, trimContent(content[0]));
      // 0er
      contentSpezial.push({ value: ' 0', static: true, class: ['static', 'static-2'] });
      // ortswahl
      contentSpezial.push.apply(contentSpezial, trimContent(content[2]));
      // )
      contentSpezial.push({ value: ')', static: true, class: ['static', 'static-3'] });
      // nr
      contentSpezial.push.apply(contentSpezial, trimContent(content[4]));
      // -
      contentSpezial.push({ value: '-', static: true, class: ['static', 'static-4'] });
      // dw
      contentSpezial.push.apply(contentSpezial, trimContent(content[6]));

      (0, _immediate.default)(function () {
        if (_this.element) {
          _this.element.classList.remove('without-dw');
          if (content[6].class && content[6].class.includes('placeholder')) {
            _this.element.classList.add('without-dw');
          }
          _this.element.classList.remove('empty');
          if (content[0].class && content[0].class.includes('placeholder') && content[2].class && content[2].class.includes('placeholder') && content[4].class && content[4].class.includes('placeholder') && content[6].class && content[6].class.includes('placeholder')) {
            _this.element.classList.add('empty');
          }
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = _this.element.querySelectorAll('.to-be-removed')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var el = _step.value;

              el.remove();
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }
      });

      return contentSpezial;
    },

    content: Ember.computed('value', {
      set: function set(k, v) {
        try {
          this._ignoreValueChange = true;
          var formated = this._format(v.reduce(function (x, _ref) {
            var value = _ref.value;
            return x + value;
          }, ''));
          var parsed = formated.reduce(function (x, _ref2) {
            var value = _ref2.value;
            return x + value;
          }, '');
          if (this.get('value') !== parsed) {
            this.set('value', parsed);
          }
          return formated;
        } finally {
          this._ignoreValueChange = false;
        }
      },
      get: function get(k) {
        k;
        var value = this.get('value');
        return this._format(value);
      }
    })
  });
});