define("lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kgxVVnWy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"lt-link\",null,[[\"resolver\",\"transition\",\"link\",\"class\"],[[25,[\"resolver\"]],[25,[\"transition\"]],[25,[\"linkValue\"]],[29,\"concat\",[\"block layout-\",[25,[\"layoutStyle\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"image\"],[9],[0,\"\\n    \"],[1,[29,\"component\",[[25,[\"image\"]]],[[\"value\"],[[25,[\"_value\",\"image\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[25,[\"_value\",\"text1\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[25,[\"_value\",\"text2\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/small-teaser/lt-page-editor-small-teaser-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});