define("ui-page-editor/components/ui-page-editor-properties/component", ["exports", "ui-page-editor/components/ui-page-editor-properties/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    _parent: Ember.computed('editor.element', function () {
      var id = parseInt((Ember.get(this, 'editor.elementId') || '').split('ember')[1]) * 1000;
      return id;
    }),

    _order: Ember.computed('editor.element', 'order', function () {
      var id = parseInt((Ember.get(this, 'editor.elementId') || '').split('ember')[1]) * 1000;
      var order = Ember.get(this, 'order') | 0;
      var _order = id + order;
      return _order;
    }),

    toolbarDom: Ember.computed('editor.element', 'toolbarId', function () {
      return document.body.querySelector('#' + Ember.get(this, 'toolbarId'));
    }),

    actions: {
      removeBlock: function removeBlock() {
        this.sendAction.apply(this, ['removeBlock'].concat(Array.prototype.slice.call(arguments)));
      },
      addBlock: function addBlock(data) {
        this.sendAction('addBlock', data, true);
      }
    }
  });
});