define("ui-page-editor-text/components/ui-page-editor-text-widget-link/component", ["exports", "ui-page-editor-text/utils/computed-json", "ui-page-editor-text/mixins/contenteditable-plaintext-only", "ui-page-editor-text/components/ui-page-editor-text-widget-link/template"], function (exports, _computedJson, _contenteditablePlaintextOnly, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_contenteditablePlaintextOnly.default, {
    layout: _template.default,
    tagName: 'span',
    model: (0, _computedJson.default)('value'),

    obsValue: Ember.observer('model.value', function () {
      var v = Ember.get(this, 'model.value');
      if (this.element.textContent !== v) {
        this.element.innerText = v;
      }
    }),

    willInsertElement: function willInsertElement() {
      this.element.innerText = this.get('model.value');
      this._super.apply(this, arguments);
    },


    actions: {
      updateContent: function updateContent() {
        var v = Ember.get(this, 'model.value');
        if (this.element.textContent !== v) {
          // save changed value !!
          Ember.set(this, 'model.value', this.element.textContent);
        }
      }
    }
  });
});