define('ember-root-url/services/root-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    build: function build(relativeURL) {
      var ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      return '' + ENV.rootURL + relativeURL.replace(/^\//, '');
    }
  });
});