define("lt-page-editor/components/blocks/pk-ausstattung/lt-page-editor-pk-ausstattung-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QBU8j3Dq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"helper\",\"properties\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"helper\",\"properties\"]]],[[\"editor\",\"helper\",\"value\"],[[25,[\"editor\"]],[25,[\"helper\",\"helper\"]],[25,[\"helper\",\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/pk-ausstattung/lt-page-editor-pk-ausstattung-properties/template.hbs"
    }
  });

  _exports.default = _default;
});