define('ui-input/utils/maskedFormatContent', ['exports', 'ui-input/utils/maskedFormat', 'ui-contenteditable/components/ui-contenteditable'], function (exports, _maskedFormat3, _uiContenteditable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function maskedFormatContent(_ref) {
    var input = _ref.input,
        mask = _ref.mask,
        removePlaceholder = _ref.removePlaceholder,
        cursorOffset = _ref.cursorOffset,
        preprocess = _ref.preprocess,
        postprocess = _ref.postprocess,
        isPlaceholder = _ref.isPlaceholder;

    input = input || '';
    if (preprocess) {
      input = preprocess(input);
    }

    var _maskedFormat = (0, _maskedFormat3.default)(mask, removePlaceholder, input, cursorOffset),
        _maskedFormat2 = _slicedToArray(_maskedFormat, 3),
        offset = _maskedFormat2[0],
        formated = _maskedFormat2[1],
        _maskedFormat2$ = _slicedToArray(_maskedFormat2[2], 2),
        formatSep = _maskedFormat2$[0],
        formatSize = _maskedFormat2$[1];

    var res = [];
    for (var i = 0; i < formatSize.length; ++i) {
      var r = formated[i];
      var v = r;
      if (postprocess) {
        v = postprocess(v, formatSize[i]);
      }
      var n = _uiContenteditable.ContentObject.create();
      res.push(n);
      n.setText(isPlaceholder && isPlaceholder(r, formatSize[i]) ? ['placeholder', 'placeholder-' + i] : ['text-' + i], v);
      if (formatSep[i]) {
        var p = _uiContenteditable.ContentObject.create();
        res.push(p);
        p.setText(['static', 'static-' + i], formatSep[i]);
        p.set('static', true);
      }
    }

    return [offset, res];
  }

  exports.default = maskedFormatContent;
});