define("lt-page-editor/components/blocks/vr-teaser/lt-page-editor-vr-teaser/component", ["exports", "lt-page-editor/components/blocks/vr-teaser/lt-page-editor-vr-teaser/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    helper: Ember.computed(function () {
      var helper = {};
      return helper;
    })
  });

  _exports.default = _default;
});