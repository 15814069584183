define("lt-page-editor/components/blocks/sp-calendar-top/lt-page-editor-sp-calendar-top/component", ["exports", "lt-page-editor/components/blocks/sp-calendar-top/lt-page-editor-sp-calendar-top/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    init: function init() {
      this._super.apply(this, arguments);
    },
    helper: Ember.computed(function () {
      var helper = {};
      return helper;
    }),
    _value: Ember.computed('value.data', function () {
      var value = Ember.get(this, 'value');

      if (!value.data) {
        Ember.set(value, 'data', Object.assign(value.data || {}, {}));
      }

      return value.data;
    })
  });

  _exports.default = _default;
});