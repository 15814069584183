define("ui-page-editor/components/ui-page-editor-wysiwyg-col/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8S5aaG88",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"selected\"]]],null,{\"statements\":[[15,1,[[29,\"hash\",null,[[\"editor\",\"isSelected\",\"index\",\"model\"],[[25,[\"editor\"]],true,[25,[\"index\"]],[25,[\"model\",\"data\"]]]]]]]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"handler\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"handle\"],[11,\"draggable\",\"true\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"drag_handle\"],[10],[10]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"content\"],[12,\"style\",[25,[\"model\",\"style\"]]],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"model\",\"isPlaceholder\"]]],null,{\"statements\":[[0,\"    \"],[15,1,[[29,\"hash\",null,[[\"editor\",\"selected\",\"isContent\",\"index\",\"model\"],[[25,[\"editor\"]],[25,[\"selected\"]],true,[25,[\"index\"]],[25,[\"model\",\"data\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[15,1,[[29,\"hash\",null,[[\"editor\",\"selected\",\"isPlaceholder\",\"index\",\"model\"],[[25,[\"editor\"]],[25,[\"selected\"]],true,[25,[\"index\"]],[25,[\"model\",\"data\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"resizer\"],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],\"startResize\"],null]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"m-add-block\"],[9],[0,\"\\n  \"],[15,1,[[29,\"hash\",null,[[\"editor\",\"selected\",\"isPlaceholder\",\"index\",\"model\"],[[25,[\"editor\"]],[25,[\"selected\"]],true,[25,[\"index\"]],[25,[\"model\",\"data\"]]]]]]],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor/components/ui-page-editor-wysiwyg-col/template.hbs"
    }
  });

  _exports.default = _default;
});