define("lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link-readonly/component", ["exports", "lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    linkValue: Ember.computed('value.data', function () {
      return Ember.get(this, 'value.data');
    })
  });

  _exports.default = _default;
});