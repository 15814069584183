define("lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link/component", ["exports", "lt-page-editor/components/blocks/sp-menu-link/lt-page-editor-sp-menu-link/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    helper: Ember.computed('value.data', function () {
      var helper = {
        link: Ember.get(this, 'value.data'),
        setLink: this.setLink.bind(this)
      };
      return helper;
    }),
    setLink: function setLink(l) {
      Ember.set(this, 'value.data', l);
    }
  });

  _exports.default = _default;
});