define("lt-page-editor/components/blocks/inception/lt-page-editor-inception/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rksPcLxM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"lt-page-editor-inception-readonly\",null,[[\"model\",\"columns\",\"columnWidth\",\"editor\",\"value\",\"resolver\",\"transition\"],[[25,[\"model\"]],[25,[\"columns\"]],[25,[\"columnWidth\"]],[25,[\"editor\"]],[25,[\"value\"]],[25,[\"resolver\"]],[25,[\"transition\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"edit\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"button-row\"],[9],[0,\"\\n    \"],[7,\"button\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"edit\"],[10],[0,\" Inhalt Bearbeiten\"],[3,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"showEdit\"]]],null],true]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[15,1,[[29,\"hash\",null,[[\"helper\",\"showEdit\",\"openEdit\",\"closeEdit\",\"pageEditor\"],[[25,[\"helper\"]],[25,[\"showEdit\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"showEdit\"]]],null],true],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"showEdit\"]]],null],false],null],[29,\"component\",[\"lt-page-editor\"],[[\"columns\",\"columnWidth\",\"withoutSections\",\"resolver\",\"transition\",\"uploader\",\"onInternalPage\",\"addBlock\",\"blocks\"],[[29,\"if\",[[25,[\"columns\"]],[25,[\"columns\"]],[25,[\"value\",\"width\"]]],null],[25,[\"columnWidth\"]],true,[25,[\"resolver\"]],[25,[\"transition\"]],[25,[\"uploader\"]],[25,[\"onInternalPage\"]],[29,\"component\",[[25,[\"editor\",\"parent\",\"addBlock\"]]],[[\"withoutPk\"],[[25,[\"withoutPk\"]]]]],[29,\"lt-page-editor-object-merge\",[[25,[\"editor\",\"parent\",\"blocks\"]],[29,\"hash\",null,[[\"teaser\"],[[28]]]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/inception/lt-page-editor-inception/template.hbs"
    }
  });

  _exports.default = _default;
});