define('ui-input/components/ui-input', ['exports', 'ui-input/templates/components/ui-input'], function (exports, _uiInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    multiline: false,
    type: 'text',
    layout: _uiInput.default
  });
});