define("lt-page-editor/components/lt-page-editor-readonly/component", ["exports", "lt-page-editor/components/lt-page-editor-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function calcHackIE11(expression) {
    return expression && expression.replace(/(?!^calc\()(calc)\(/i, '(');
  }

  var _default = Ember.Component.extend({
    tagName: 'lt-page-editor-readonly',
    layout: _template.default,
    columns: 3,
    columnWidth: '434px',
    _style: Ember.computed('elementId', 'columns', 'columnWidth', function () {
      var columns = this.get('columns');
      var columnWidth = this.get('columnWidth');
      var elementId = this.get('elementId');
      return ".".concat(elementId, "-ui-wysiwyg-col { width: ") + calcHackIE11("calc(".concat(columns, " * ").concat(columnWidth, ")")) + "; }\n" + Array.from(new Array(columns + 3), function (_, i) {
        var j = i - 2;

        if (j < 0) {
          j = columns;
        }

        return ".".concat(elementId, "-ui-wysiwyg-col[data-w=\"").concat(i - 2, "\"] { width: ") + calcHackIE11("calc(".concat(j, " * ").concat(columnWidth, ")")) + "; }";
      }).join('\n') + "\n.".concat(elementId, "-ui-wysiwyg-row { min-width: ") + calcHackIE11("calc(".concat(columns, " * ").concat(columnWidth, ")")) + "; max-width: " + calcHackIE11("calc(".concat(columns, " * ").concat(columnWidth, ")")) + "; }";
    }),
    data: Ember.computed('value', function () {
      var res = [];
      var l = [];

      var _iterator = _createForOfIteratorHelper(Ember.get(this, 'value') || []),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          var c = Ember.get(item, 'component');

          if (c == 'title' || c == 'hr' || c == 'teaser') {
            if (l.length) {
              res.push(l);
              l = [];
            }

            res.push(item);
            continue;
          }

          l.push(item);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (l.length) {
        res.push(l);
      }

      return res;
    })
  });

  _exports.default = _default;
});