define("ui-page-editor-list/components/ui-page-editor-list/component", ["exports", "ui-page-editor-list/components/ui-page-editor-list/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var getSetFromArray = function getSetFromArray(arrayName, indexName, offset, def, initFunc) {
    return Ember.computed(arrayName + '.@each', indexName, {
      get: function get(k) {
        k;
        var arr = Ember.get(this, arrayName);
        if (!arr) {
          return def;
        }
        return arr[Ember.get(this, indexName) + offset] || def;
      },
      set: function set(k, v) {
        k;
        var arr = Ember.get(this, arrayName);
        if (!arr) {
          arr = [];
          Ember.set(this, arrayName, arr);
        }
        var index = Ember.get(this, indexName) + offset;
        while (arr.length < index) {
          arr.pushObject(null);
        }
        if (initFunc) {
          Ember.get(this, initFunc).call(this);
        }
        arr.replace(index, 1, [v]);
        return v;
      }
    });
  };

  var wrapperObject = Ember.Object.extend({
    array: null,
    index: null,

    depth: getSetFromArray('array', 'index', 0, 0),
    value: getSetFromArray('array', 'index', 1, '')
  });

  var wrapperDepthObject = Ember.Object.extend({
    depth: Ember.computed.alias('original.depth')
  });

  var initialObject = Ember.Object.extend({
    model: null,
    index: null,
    default: null,
    array: Ember.computed.alias('model.data'),
    depth: getSetFromArray('array', 'index', 0, 0, '_initialize'),
    value: getSetFromArray('array', 'index', 1, '', '_initialize'),

    _initialize: function _initialize() {
      var array = Ember.get(this, 'array');
      var index = Ember.get(this, 'index');
      array.replace(index, 1, [Ember.get(this, 'depth')]);
      array.replace(index + 1, 1, [Ember.get(this, 'value')]);
    }
  });

  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['listType', 'classHelper'],
    listType: Ember.computed('value.type', function () {
      return this.get('value.type') ? 'list-ol' : 'list-ul';
    }),
    classHelper: Ember.computed('value.valign', function () {
      var tmp = '';
      switch (this.get('value.valign')) {
        case 1:
          tmp += "valign-middle ";
          break;
        case 2:
          tmp += "valign-bottom ";
          break;
        default:
          tmp += "valign-top ";
      }
      return tmp;
    }),
    helper: Ember.computed('selectedId', 'value.valign', 'model.@each.depth', 'value.type', 'value.type', function () {
      var helper = {};
      var selectedId = this.get('selectedId');

      Ember.set(helper, 'moveSelectedLine', this._moveLine.bind(this, selectedId));

      var depth = Ember.get(this.get('model')[selectedId] || {}, 'depth');
      var depthBefore = Ember.get(this.get('model')[selectedId - 1] || {}, 'depth');
      if (selectedId === 0) {
        Ember.set(helper, 'canMoveLeft', false);
        Ember.set(helper, 'canMoveRight', false);
      } else {
        Ember.set(helper, 'canMoveLeft', depth > 0);
        Ember.set(helper, 'canMoveRight', depth != 3 && depthBefore >= depth);
      }
      Ember.set(helper, 'type', this.get('value.type'));

      Ember.set(helper, 'setType', this._setType.bind(this));

      Ember.set(helper, 'valign', this.get('value.valign'));
      Ember.set(helper, 'setVAlign', this._setVAlign.bind(this));

      return helper;
    }),
    _setVAlign: function _setVAlign() {
      this.set.apply(this, ['value.valign'].concat(Array.prototype.slice.call(arguments)));
    },
    _model: function _model(items) {
      var res = [];
      var olNrStack = [];
      var selectedId = Ember.get(this, 'selectedId');

      // stores as [depth1, value1, depth2, value2]
      for (var i = 0; items && i < items.length; i += 2) {
        var depth = items[i] | 0;

        var original = wrapperObject.create({ array: items, index: i });

        var olNr = (olNrStack[depth] | 0) + 1;
        olNrStack[depth] = olNr;
        olNrStack[depth + 1] = 0;

        res.push(wrapperDepthObject.create({
          selected: i / 2 === selectedId,
          index: i / 2,
          olNr: olNr,
          original: original
        }));
      }

      if (!res.length) {
        res.push(wrapperDepthObject.create({
          selected: 0 === selectedId,
          index: 0,
          oldNr: 1,
          original: initialObject.create({
            model: this.get('value'),
            index: 0
          })
        }));
      }

      return res;
    },
    _value: function _value(model) {
      var _ref;

      var res = model.map(function (x) {
        return [Ember.get(x, 'original.depth') | 0, Ember.get(x, 'original.value') || ''];
      });

      return (_ref = []).concat.apply(_ref, _toConsumableArray(res));
    },
    _update: function _update() {
      var nModel = this.get('model');
      var nValue = this._value(nModel);
      var value = this.get('value.data');

      if (JSON.stringify(nValue) !== JSON.stringify(value)) {
        this.set('value.data', nValue);
      }
    },

    model: Ember.computed('value.data.[]', 'selectedId', {
      set: function set(k, v) {
        k;
        var value = this.get('value.data');
        var nModel = this._model(v);
        var nValue = this._value(nModel);
        if (JSON.stringify(nValue) !== JSON.stringify(value)) {
          this.set('value.data', nValue);
        }
        return nModel;
      },
      get: function get(k) {
        k;
        var value = this.get('value.data');
        return this._model(value);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('value')) {
        this.set('value', {
          type: null,
          data: []
        });
      }
      this._keydown = this._keydown.bind(this);
      this._focus = this._focus.bind(this);
    },
    willInsertElement: function willInsertElement() {
      this.element.addEventListener('keydown', this._keydown, true);
      this.element.addEventListener('focus', this._focus, true);
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('keydown', this._keydown, true);
      this.element.removeEventListener('focus', this._focus, true);
    },
    _focus: function _focus(e) {
      var node = e.target;
      while (node && (!node.dataset || !node.dataset.index)) {
        node = node.parentNode;
      }
      this.set('selectedId', node && node.dataset && parseInt(node.dataset.index));
    },
    _addNewLine: function _addNewLine(index) {
      var _this = this;

      var model = this.get('model');

      model.insertAt(index + 1, {
        original: {
          depth: Ember.get(model[index], 'depth'),
          value: ''
        }
      });
      this._update();

      Ember.run.schedule('afterRender', function () {
        if (!_this.element) {
          return;
        }
        var el = _this.element.children[index + 1];
        if (!el) {
          return;
        }
        var input = el.querySelector('ui-textarea');
        if (!input) {
          return;
        }
        input.focus();
      });
    },
    _moveLine: function _moveLine(index, shift, e) {
      var value = this.get('model');
      if (!value[index - 1]) {
        return;
      }
      var parentDepth = value[index - 1] && Ember.get(value[index - 1], 'depth') || 0;
      var item = value[index];
      if (!item) {
        return;
      }

      var dir = shift ? -1 : 1;

      var depth = (Ember.get(item, 'depth') || 0) + dir;
      depth = Math.min(depth, Math.min(parentDepth + 1, 3));
      depth = Math.max(depth, 0);

      if (depth !== Ember.get(item, 'depth')) {
        // children
        var oldDepth = Ember.get(item, 'depth');
        Ember.set(item, 'depth', depth);
        for (var i = index + 1; i < value.length; ++i) {
          if (Ember.get(value[i], 'depth') <= oldDepth) {
            break;
          }
          if (Ember.get(value[i], 'depth') !== oldDepth + 1) {
            continue;
          }
          this._moveLine(i, shift);
        }
        if (e) {
          e.preventDefault();
        }
      }
    },
    _removeLine: function _removeLine(index) {
      var value = this.get('model');
      if (index > 0) {
        value.removeAt(index);
        this._update();
      }
      if (!this.element) {
        return;
      }
      var el = this.element.children[index - 1];
      if (!el) {
        return;
      }
      var input = el.querySelector('ui-textarea');
      if (!input) {
        return;
      }
      input.focus();
    },
    _keydown: function _keydown(e) {
      var node = e.target;
      while (node && (!node.dataset || !node.dataset.index)) {
        node = node.parentNode;
      }
      if (!node) {
        return;
      }
      var key = e.which || e.keyCode;
      var index = parseInt(node.dataset.index);
      if (!e.shiftKey && key === 13 /*ENTER*/) {
          e.preventDefault();
          this._addNewLine(index);
          return;
        }
      if (key === 9 /*TAB*/) {
          var selection = window.getSelection() || document.getSelection();
          if (selection.anchorOffset == 0) {
            this._moveLine(index, e.shiftKey, e);
          }
          return;
        }
      if (key === 8 /*BACKSPACE*/) {
          var t = node.querySelector('ui-textarea');
          if (t && !t.innerText.replace(/[\r\n]/g, '').length) {
            e.preventDefault();
            this._removeLine(index);
          }
        }
    },
    _setType: function _setType(type) {
      this.set('value.type', type);
    }
  });
});