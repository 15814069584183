define("lt-page-editor/components/blocks/sp-advent-calendar/lt-page-editor-sp-advent-calendar-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yYjBxMCf",
    "block": "{\"symbols\":[\"item\",\"i\"],\"statements\":[[4,\"if\",[[25,[\"items\"]]],null,{\"statements\":[[4,\"each\",[[25,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[\"item item-\",[24,1,[\"day\"]],\" \",[29,\"if\",[[24,1,[\"gruppe\"]],\"open\"],null]]]],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"block \",[24,1,[\"class\"]],\" paper-f-1\"]]],[9],[0,\"\\n\"],[0,\"\\n\"],[4,\"if\",[[24,1,[\"gruppe\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"lt-page-editor-image-tag\",null,[[\"class\",\"src\",\"tagIt\",\"width\",\"height\",\"colors\",\"copyright\",\"resolver\"],[\"image\",[24,1,[\"gruppe\",\"teaserImage\",\"url\"]],false,[24,1,[\"gruppe\",\"teaserImage\",\"w\"]],[24,1,[\"gruppe\",\"teaserImage\",\"h\"]],[24,1,[\"gruppe\",\"teaserImage\",\"colors\"]],[24,1,[\"gruppe\",\"teaserImage\",\"copyright\"]],[25,[\"resolver\"]]]]],false],[0,\"\\n            \"],[1,[24,1,[\"canvas\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[24,1,[\"canvas\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[7,\"span\"],[11,\"class\",\"nr\"],[9],[1,[24,1,[\"day\"]],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"area\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"open\",[24,1,[]]],null]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"edit\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"error\"],[9],[0,\"\\n      Kein Gewinnspiel ausgewählt oder Gewinnspiel ist leer.\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/sp-advent-calendar/lt-page-editor-sp-advent-calendar-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});