define("lt-page-editor/components/blocks/sp-advent-calendar/lt-page-editor-sp-advent-calendar-readonly/component", ["exports", "lt-page-editor/components/blocks/sp-advent-calendar/lt-page-editor-sp-advent-calendar-readonly/template", "lt-page-editor/components/blocks/sp-advent-calendar/lt-page-editor-sp-advent-calendar-readonly/renderer"], function (_exports, _template, _renderer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function shuffle(a) {
    var j, x, i;

    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }

    return a;
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    rootUrl: Ember.inject.service(),
    didRender: function didRender() {
      this.element.querySelectorAll('image, feImage').forEach(function (x) {
        x.setAttributeNS('http://www.w3.org/1999/xlink', 'href', x.getAttribute('xlink:href'));
      });
    },
    adventInfo: Ember.computed('value.data.link.data', function () {
      var data = Ember.get(this, 'value.data.link.data');

      if (!data) {
        return;
      }

      var resolver = Ember.get(this, 'resolver');

      if (!resolver) {
        console.warn("RESOLVER NOT SET");
        return;
      }

      var res = resolver('advent', data, {
        supportAsync: false
      });

      var _iterator = _createForOfIteratorHelper(res || []),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (!item.gruppe_id) {
            continue;
          }

          item.gruppe = resolver('gewinnspiel', 'g' + item.gruppe_id, {
            supportAsync: false
          });
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return res;
    }),
    items: Ember.computed('adventInfo', function () {
      var rootUrl = Ember.get(this, 'rootUrl');
      var adventInfo = Ember.get(this, 'adventInfo');

      if (!adventInfo) {
        return null;
      }

      var res = adventInfo.map(function (x) {
        var tmp = Object.assign({
          class: "paper-".concat(Math.floor(Math.random() * 100) % 3 + 1, " pattern-").concat(Math.floor(Math.random() * 100) % 3 + 1, " paper-style1-").concat(Math.random() * 100 | 0, " paper-style2-").concat(Math.random() * 100 | 0, " pattern-style1-").concat(Math.random() * 100 | 0, " pattern-style2-").concat(Math.random() * 100 | 0),
          paper_id: Math.floor(Math.random() * 100) % 3 + 1,
          pattern_id: 1
        }, x);
        var size = 197;

        if (x.day == 24 || x.day == 1) {
          size = 414;
        }

        var dayBig = rootUrl.build('/lt-page-editor/advent/day-big.jpg');
        var daySmall = rootUrl.build('/lt-page-editor/advent/day-small.jpg');
        (0, _renderer.default)(size, size, size == 414 ? dayBig : daySmall, tmp.gruppe).then(function (x) {
          Ember.set(tmp, 'canvas', x);
          Ember.run.schedule('afterRender', function () {
            return x.classList.add('done');
          });
        });
        return tmp;
      });
      return shuffle(res);
    }),
    actions: {
      open: function open(item, e) {
        e.preventDefault();

        if (!item.gruppe) {
          return;
        }

        var event = new CustomEvent("lt-page-editor-sp-advent-calendar", {
          detail: {
            day: item
          },
          bubbles: true,
          cancelable: false
        });
        e.currentTarget.dispatchEvent(event);
      }
    }
  });

  _exports.default = _default;
});