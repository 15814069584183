define("lt-page-editor/components/lt-page-editor-add-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0nYZ41Gn",
    "block": "{\"symbols\":[\"block\"],\"statements\":[[4,\"in-element\",[[25,[\"bodyElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[30,[[23,\"elementId\"],\"-popup\"]]],[12,\"class\",[30,[[23,\"componentCssClassName\"],\"-popup-area\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"popup\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"\\n        \"],[7,\"span\"],[9],[0,\"Block hinzufügen\"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"close\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"addBlock\"]],null],null]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"quick-search\"],[9],[0,\"\\n          \"],[1,[29,\"input\",null,[[\"type\",\"placeholder\",\"value\"],[\"search\",\"Suchen\",[25,[\"search\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"blocks\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[4,\"if\",[[24,1,[\"seperator\"]]],null,{\"statements\":[[0,\"            \"],[7,\"hr\"],[12,\"class\",[24,1,[\"class\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"button\"],[12,\"disabled\",[24,1,[\"disabled\"]]],[12,\"title\",[24,1,[\"title\"]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"addBlock\"]],[24,1,[\"default\"]],false],null]],[9],[0,\"\\n              \"],[7,\"img\"],[12,\"src\",[29,\"root-url\",[[24,1,[\"icon\"]]],null]],[9],[10],[0,\"\\n              \"],[7,\"span\"],[9],[1,[24,1,[\"title\"]],false],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/lt-page-editor-add-block/template.hbs"
    }
  });

  _exports.default = _default;
});