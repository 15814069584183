define("ui-page-editor-text/components/ui-page-editor-text-widget-link-readonly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uCSUBCDM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"class\",[23,\"componentCssClassName\"]],[12,\"href\",[29,\"unbound\",[[25,[\"model\",\"href\"]]],null]],[9],[1,[29,\"unbound\",[[25,[\"model\",\"value\"]]],null],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor-text/components/ui-page-editor-text-widget-link-readonly/template.hbs"
    }
  });

  _exports.default = _default;
});