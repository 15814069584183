define("ui-page-editor/components/ui-page-editor-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bIQ5/3gs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\" \"],[1,[29,\"log\",[\"render in\",[25,[\"toolbarId\"]],[25,[\"toolbarDom\"]]],null],false],[0,\"\\n\"],[4,\"in-element\",[[25,[\"toolbarDom\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\" \"],[7,\"ui-page-editor-properties\"],[11,\"data-ignore-selection\",\"\"],[12,\"data-parent\",[23,\"_parent\"]],[12,\"data-order\",[23,\"_order\"]],[12,\"class\",[30,[[23,\"componentCssClassName\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"toolbar\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"spacer\"],[9],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"rm button-row\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"danger\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"removeBlock\"],null]],[11,\"title\",\"Block entfernen\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"delete_forever\"],[10],[0,\"Entfernen\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-page-editor/components/ui-page-editor-properties/template.hbs"
    }
  });

  _exports.default = _default;
});