define('ui-input/components/ui-textarea', ['exports', 'ui-contenteditable/components/ui-contenteditable'], function (exports, _uiContenteditable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiContenteditable.default.extend({
    tagName: 'ui-textarea',
    attributeBindings: ['type', 'accept', 'alt', 'checked', 'disabled', 'max', 'maxlength', 'min', 'minlength', 'name', 'placeholder', 'readonly', 'required', 'size', 'step', 'type', 'multiline:aria-multiline'],
    multiline: true,
    classNameBindings: ['empty'],

    empty: Ember.computed('value', function () {
      var value = this.get('value');
      return !value || value === '';
    }),

    _keypress: function _keypress(e) {
      if (!this.get('multiline') && e.keyCode === 13 /* new line */) {
          /* we don't allow new lines */
          e.preventDefault();
          return;
        }
      this._super.apply(this, arguments);
    },


    obsValue: Ember.observer('value', function () {
      if (!this._ignoreValueChange) {
        this._change();
      }
    }),

    content: Ember.computed('value', {
      set: function set(k, v) {
        var value = '';
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = v[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var c = _step.value;

            value += c.value;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        if (this.get('value') !== value) {
          this._ignoreValueChange = true;
          try {
            this.set('value', value);
          } finally {
            this._ignoreValueChange = false;
          }
        }
        return [{ value: value }].map(function (x) {
          var tmp = _uiContenteditable.ContentObject.create();
          tmp.setText(null, x.value || '');
          return tmp;
        });
      },
      get: function get(k) {
        k;
        var value = this.get('value');
        return [{ value: value }].map(function (x) {
          var tmp = _uiContenteditable.ContentObject.create();
          tmp.setText(null, x.value || '');
          return tmp;
        });
      }
    })
  });
});