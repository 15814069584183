define("lt-page-editor/components/blocks/title/lt-page-editor-title/component", ["exports", "lt-page-editor/components/blocks/title/lt-page-editor-title/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    helper: Ember.computed('value.data.layout', function () {
      var helper = {
        layout: Ember.get(this, 'value.layout') | 0,
        setLayout: this._setValue.bind(this, 'value.layout')
      };
      return helper;
    }),
    _setValue: function _setValue(key, value) {
      if (!Ember.get(this, 'value.data')) {
        Ember.set(this, 'value.data', {});
      }

      Ember.set(this, key, value);

      if (!Ember.get(this, 'value.layout')) {
        Ember.set(this, 'value.data.text2', null);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._setValue = this._setValue.bind(this);
    },
    _text1: Ember.computed('value.data.text1', {
      get: function get() {
        return Ember.get(this, 'value.data.text1') || '';
      },
      set: function set(k, v) {
        this._setValue('value.data.text1', v);

        return v;
      }
    }),
    _text2: Ember.computed('value.data.text2', {
      get: function get() {
        return Ember.get(this, 'value.data.text2') || '';
      },
      set: function set(k, v) {
        this._setValue('value.data.text2', v);

        return v;
      }
    })
  });

  _exports.default = _default;
});