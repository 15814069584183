define("lt-page-editor/components/blocks/hr/lt-page-editor-hr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yoQaTMy3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"lt-page-editor-hr-readonly\",null,[[\"value\"],[[25,[\"value\"]]]]],false],[0,\"\\n\"],[15,1,[[25,[\"helper\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lt-page-editor/components/blocks/hr/lt-page-editor-hr/template.hbs"
    }
  });

  _exports.default = _default;
});