define("lt-page-editor/components/blocks/pk-lieferando/lt-page-editor-pk-lieferando-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-lieferando/lt-page-editor-pk-lieferando-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getUrl(x) {
    try {
      var url = new URL(x);

      if (url.host.includes('awin1.com')) {
        return url.searchParams.get('ued');
      }

      return url.href;
    } catch (ex) {
      ex;
      return x;
    }
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    lokal: Ember.computed.readOnly('model.lokal'),
    lieferando: Ember.computed('lokal.website', 'lokal.websites', function () {
      var lieferando = [Ember.get(this, 'lokal.website')].concat(Ember.get(this, 'lokal.websites') || []).filter(function (x) {
        return x;
      }).find(function (x) {
        return getUrl(x).toLowerCase().includes('lieferando');
      });

      if (!lieferando) {
        return null;
      }

      return lieferando;
    })
  });

  _exports.default = _default;
});