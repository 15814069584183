define("ui-page-editor-text/components/ui-page-editor-text/component", ["exports", "ui-page-editor-text/components/ui-page-editor-text/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ComponentSelectedObject = Ember.Object.extend({
    parent: null,
    widgetNode: Ember.computed('node', function () {
      var el = Ember.get(this, 'node');
      return el;
    }),
    node: null,

    componentName: Ember.computed('widgetNode', function () {
      var w = Ember.get(this, 'widgetNode');
      return w && w.dataset.component;
    }),

    index: Ember.computed('widgetNode', function () {
      var w = Ember.get(this, 'widgetNode');
      return w && w.dataset.index | 0;
    }),

    value: Ember.computed('parent._value.@each', {
      get: function get(k) {
        k;
        var _value = this.get('parent._value');
        var index = this.get('index');
        return _value[index * 2 + 1];
      },
      set: function set(k, v) {
        var w = Ember.get(this, 'widgetNode');
        if (w) {
          // correctly updates by ui-contenteditable
          w.dataset.value = v;
        }
        return v;
      }
    })
  });

  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['classHelper'],
    classHelper: Ember.computed('value.valign', 'value.halign', function () {
      var tmp = '';
      switch (this.get('value.halign')) {
        case 1:
          tmp += "halign-center ";
          break;
        case 2:
          tmp += "halign-right ";
          break;
        case 3:
          tmp += "halign-justify ";
          break;
        default:
          tmp += "halign-left ";
      }
      switch (this.get('value.valign')) {
        case 1:
          tmp += "valign-middle ";
          break;
        case 2:
          tmp += "valign-bottom ";
          break;
        default:
          tmp += "valign-top ";
      }
      return tmp;
    }),
    _value: Ember.computed('value.data', {
      get: function get(k) {
        k;
        return this.get('value.data');
      },
      set: function set(k, v) {
        k;
        this.set('value.data', v);
        return v;
      }
    }),
    helper: Ember.computed('_cursor', 'value.valign', 'value.halign', 'widgets', 'selectedComponent', function () {
      var cursor = this.get('_cursor');

      // setup helper
      var helper = {};
      Ember.set(helper, 'cursor', cursor);
      Ember.set(helper, 'setCursor', Ember.set.bind(null, this, '_cursor'));
      Ember.set(helper, 'valign', this.get('value.valign'));
      Ember.set(helper, 'halign', this.get('value.halign'));
      Ember.set(helper, 'setVAlign', this._setVAlign.bind(this));
      Ember.set(helper, 'setHAlign', this._setHAlign.bind(this));
      var comp = this.get('selectedComponent');
      var widgets = this.get('widgets');
      if (comp && widgets) {
        var componentName = Ember.get(widgets, Ember.get(comp, 'componentName') + ".properties");
        if (componentName) {
          var tmp = {
            componentName: componentName,
            component: comp
          };
          tmp[Ember.get(comp, 'componentName')] = true; // dirty hack
          Ember.set(helper, 'widget', tmp);
        }
      }
      Ember.set(helper, 'selectedWidget', this.get('selectedComponent'));

      return helper;
    }),

    obsSelected: Ember.observer('selected', function () {
      if (!Ember.get(this, 'selected')) {
        Ember.set(this, '_debounceSelected', false);
        this.notifyPropertyChange('selectedComponent');
      }
    }),

    selectedComponent: Ember.computed('_cursor', function () {
      var startNode = this.get('_cursor.startContainer');
      var endNode = this.get('_cursor.endContainer');

      if (!startNode) {
        return this.get('_debounceSelected');
      }

      var startOffset = this.get('_cursor.startOffset');
      var endOffset = this.get('_cursor.endOffset');
      if (startOffset !== endOffset) {
        if (startOffset === startNode.innerText.length) {
          if (startNode.nextElementSibling) {
            startNode = startNode.nextElementSibling;
          }
        }
        if (endOffset === 0) {
          if (endNode.previousSibling) {
            endNode = endNode.previousSibling;
          }
        }
      }

      while (startNode.parentElement && startNode.parentElement.tagName !== 'UI-CONTENTEDITABLE') {
        startNode = startNode.parentElement;
      }

      var node2 = this.get('_cursor.endContainer');
      while (node2 && node2.parentElement && node2.parentElement.tagName !== 'UI-CONTENTEDITABLE') {
        node2 = node2.parentElement;
      }

      if (this.get('_debounceSelected.node') != startNode) {
        this.set('_debounceSelected', ComponentSelectedObject.create({
          parent: this,
          node: startNode
        }));
      }

      /*const isComponent = startNode && startNode.dataset && ('component' in startNode.dataset) && startNode === node2;
      if (!isComponent) {
        return;
      }*/

      return this.get('_debounceSelected');
    }),

    _setVAlign: function _setVAlign() {
      this.set.apply(this, ['value.valign'].concat(Array.prototype.slice.call(arguments)));
    },
    _setHAlign: function _setHAlign() {
      this.set.apply(this, ['value.halign'].concat(Array.prototype.slice.call(arguments)));
    },
    init: function init() {
      this._super.apply(this, arguments);
      this._renderedWidgets = [];
      this._renderWidgetsUpdate = this._renderWidgetsUpdate.bind(this);
    },
    _renderWidgetsUpdate: function _renderWidgetsUpdate() {
      this.notifyPropertyChange('selectedComponent');
    },


    actions: {
      editComponent: function editComponent(status) {
        this.set('currentSelectedComponent', status ? this.get('selectedComponent') : null);
      },
      saveComponent: function saveComponent(value) {
        var cSC = this.get('currentSelectedComponent');
        if (cSC) {
          cSC.set('data', value);
        }
      }
    }
  });
});