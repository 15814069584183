define("ui-page-editor/components/ui-page-editor/component", ["exports", "ui-page-editor/components/ui-page-editor/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    columns: 8,
    columnWidth: '128px',
    columnMaxWidth: null,
    columnMinWidth: null,
    layout: _template.default
  });
});