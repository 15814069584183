define("lt-page-editor/components/blocks/pk-ausstattung/lt-page-editor-pk-ausstattung-readonly/component", ["exports", "lt-page-editor/components/blocks/pk-ausstattung/lt-page-editor-pk-ausstattung-readonly/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function filter(name, v) {
    return Ember.computed(name, function () {
      var items = (Ember.get(this, name) || []).filter(function (x) {
        return x && (Ember.get(x, 'id') / 1000 | 0) == v;
      }).map(function (x) {
        return x.value;
      });
      return items;
    });
  }

  function stringify(name) {
    return Ember.computed(name, function () {
      var items = (Ember.get(this, name) || []).map(function (x) {
        return x.value;
      });
      return items;
    });
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    _value: Ember.computed.alias('value.data'),
    lokal: Ember.computed.readOnly('model.lokal'),
    kategorien: stringify('lokal.kategorien'),
    kuechen: stringify('lokal.kuechen'),
    bezahlmoeglichkeiten: filter('model.lokal.attributes', 4),
    ausstattung: filter('model.lokal.attributes', 5),
    angebot: filter('model.lokal.attributes', 6),
    extra: filter('model.lokal.attributes', 7),
    address: Ember.computed('model.lokal.address', function () {
      var address = Ember.get(this, 'model.lokal.address');
      var ad = [[address.street, address.num], [address.plz, address.city], [address.additional]];
      return ad.map(function (x) {
        return x.filter(function (x) {
          return x;
        }).join(' ');
      }).filter(function (x) {
        return x;
      });
    }),
    isNotPremium: Ember.computed.equal('model.lokal.premiumStatusId', 0),
    isPremium: Ember.computed.not('isNotPremium')
  });

  _exports.default = _default;
});